import { Typography } from "@mui/material";
import clsx from "clsx";
import pluralize from "pluralize";
import { useEffect, useState } from "react";
import Tooltip from "../components/Tooltip";
import useGetHealthCheck from "../utils/useGetHealthCheck";

enum HealthStatus {
  AdmissionController = "Admission controller",
  AgentController = "Agent controller",
  Prometheus = "Prometheus",
  Recommender = "Recommender",
  Updater = "Updater",
  KubeStateMetrics = "Kube state metrics",
  ImageMatchesChartVersion = "Image matches chart version",
}

const FailedHealthCheckList = ({ unHealthArrayValues }: { unHealthArrayValues: HealthStatus[] }) => {
  return (
    <div className="flex flex-col gap-1">
      <Typography variant="caption">
        The following health <b>{pluralize("check", unHealthArrayValues.length)}</b> failed:
      </Typography>
      <ul className="list-disc list-inside ml-2">
        {unHealthArrayValues.map((healthStatus) => (
          <li key={healthStatus}>{healthStatus}</li>
        ))}
      </ul>
    </div>
  );
};

const IMAGE_MATCH_CHART_ENABLED = true;

const HealthCheckStatus = () => {
  const [unHealthArray, setUnHealthArray] = useState<HealthStatus[]>([]);
  const [didHealthCheckPassed, setDidHealthCheckPassed] = useState<boolean | undefined>(undefined);

  const { data, isError, isLoading } = useGetHealthCheck();

  useEffect(() => {
    if (data) {
      const unHealthArrayValues: HealthStatus[] = [];
      if (data.admissionController?.healthy === false) {
        unHealthArrayValues.push(HealthStatus.AdmissionController);
      }
      if (data.agentController?.healthy === false) {
        unHealthArrayValues.push(HealthStatus.AgentController);
      }
      if (data.prometheus?.healthy === false) {
        unHealthArrayValues.push(HealthStatus.Prometheus);
      }
      if (data.recommender?.healthy === false) {
        unHealthArrayValues.push(HealthStatus.Recommender);
      }
      if (data.updater?.healthy === false) {
        unHealthArrayValues.push(HealthStatus.Updater);
      }
      if (data.kubeStateMetrics?.healthy === false) {
        unHealthArrayValues.push(HealthStatus.KubeStateMetrics);
      }
      if (IMAGE_MATCH_CHART_ENABLED && !data.imageMatchesChart) {
        unHealthArrayValues.push(HealthStatus.ImageMatchesChartVersion);
      }

      setUnHealthArray(unHealthArrayValues);
      setDidHealthCheckPassed(unHealthArrayValues.length === 0 || data.isFreshInstall);
    }
  }, [data]);

  if (isError) {
    console.log("Error fetching health status");
  }

  if (isLoading || isError || !data) {
    return null;
  }

  let tooltipContent;

  switch (true) {
    case didHealthCheckPassed:
      tooltipContent = (
        <>
          All health checks <b>passed successfully</b>.
        </>
      );
      break;
    case data.isFreshInstall:
      tooltipContent = (
        <p>
          <div className="flex items-center gap-4">
            <b className="text-guideline-darkGreen">Installing ScaleOps</b>
            <div>
              <div className="dot-elastic" />
            </div>
          </div>
          <span>Health checks will be available soon</span>.
        </p>
      );
      break;
    default:
      tooltipContent = <FailedHealthCheckList unHealthArrayValues={unHealthArray} />;
  }

  return (
    <Tooltip title={tooltipContent} maxWidth={500} placement="right" className="w-fit">
      <div className="h-8 flex items-center gap-1.5">
        <div
          className={clsx("w-2 h-2 rounded-full", {
            "bg-main-green": didHealthCheckPassed,
            "bg-main-red animate-pulse": !didHealthCheckPassed,
          })}
        />
        <Typography variant="caption" className="opacity-50 cursor-default">
          {didHealthCheckPassed ? "Health check" : "Health check failed"}
        </Typography>
      </div>
    </Tooltip>
  );
};

export default HealthCheckStatus;
