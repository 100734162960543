import { useQuery } from "@tanstack/react-query";
import { GetIsReadOnly, GetIsReadOnlyResponse } from "../api/fetcher";

const { queryFn, queryKey } = GetIsReadOnly();

const useIsReadyOnlyFrontEnd = () => {
  const { data, error } = useQuery<GetIsReadOnlyResponse, Error>({
    queryKey: [queryKey],
    queryFn: queryFn,
  });

  if (error) {
    console.log("Error fetching isReadOnly", error);
  }

  if (!data || error) {
    return false;
  }

  return Object.values(data).some((value) => value === true);
};

export default useIsReadyOnlyFrontEnd;
