import { IconProps } from "./utils/utils";

const RolloutIcon = (props: IconProps) => {
  const { height = 20, width = 20, className } = props;
  return (
    <svg height={height} width={width} className={className} fill="currentColor" viewBox="0 0 20 20" {...props}>
      <g clip-path="url(#clip0_921_104)">
        <path d="M9.91609 1.62183C11.3894 1.62183 12.8651 2.04004 14.1836 2.83135C15.0199 3.33452 15.7714 3.96689 16.4097 4.70532L14.2071 4.63267C13.9484 4.62402 13.7317 4.8269 13.7232 5.08574C13.7147 5.34443 13.9175 5.56123 14.1763 5.56958L17.2239 5.67022C17.399 5.74229 17.6002 5.702 17.7341 5.56797C17.8678 5.43394 17.9078 5.23267 17.8355 5.05762L17.7351 2.01074C17.7293 1.7519 17.5147 1.54683 17.2558 1.55254C16.9971 1.5584 16.7918 1.773 16.7976 2.03169C16.7976 2.03521 16.7976 2.03843 16.7979 2.0418L16.8559 3.79961C16.2111 3.11055 15.4741 2.51392 14.666 2.02715C13.2019 1.14868 11.5594 0.684326 9.91609 0.684326C7.29109 0.684326 4.87087 1.7667 3.10222 3.73193C1.53542 5.4729 0.599976 7.81621 0.599976 10.0004C0.599976 10.2593 0.809741 10.4692 1.06873 10.4692C1.32771 10.4692 1.53748 10.2593 1.53748 10.0004C1.53748 8.0415 2.38284 5.93257 3.79905 4.35918C5.38782 2.59419 7.56003 1.62183 9.91609 1.62183Z" />
        <path d="M18.8813 9.53169C18.6223 9.53169 18.4125 9.74146 18.4125 10.0004C18.4125 11.9592 17.5672 14.0683 16.1509 15.6418C14.5622 17.4068 12.39 18.3789 10.0339 18.3789C8.56057 18.3789 7.08488 17.961 5.76638 17.1697C4.9301 16.6664 4.17863 16.034 3.54025 15.2957L5.74294 15.3684C6.00163 15.3767 6.21828 15.1738 6.22678 14.9151C6.23527 14.6564 6.03254 14.4398 5.7737 14.4312L2.72609 14.3307C2.55104 14.2586 2.34978 14.299 2.21589 14.4329C2.08215 14.5669 2.04216 14.7681 2.11452 14.9431L2.21486 17.99C2.22072 18.249 2.43532 18.4542 2.69416 18.4483C2.95285 18.4426 3.15822 18.228 3.15236 17.9692C3.15236 17.9658 3.15236 17.9626 3.15207 17.9591L3.09406 16.2013C3.73889 16.8905 4.47585 17.4868 5.284 17.9739C6.74811 18.8525 8.39064 19.3167 10.0339 19.3167C12.6589 19.3167 15.0791 18.2345 16.8478 16.2692C18.4146 14.5281 19.35 12.1848 19.35 10.0004C19.35 9.74146 19.1403 9.53169 18.8813 9.53169Z" />
      </g>
      <defs>
        <clipPath id="clip0_921_104">
          <rect width="20" height="20" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RolloutIcon;
