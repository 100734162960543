import { ListItemIcon, MenuItem, Typography } from "@mui/material";
import pluralize from "pluralize";
import { ArrayParam, BooleanParam, useQueryParam } from "use-query-params";
import { components } from "../../api/schema";
import AutomationIcon from "../../Icons/AutomationIcon";
import BulkIcon from "../../Icons/BulkIcon";
import CircleIconWrapper from "../../Icons/CircleIconWrapper";
import PolicyIcon from "../../Icons/PolicyIcon";
import UnAutomateIcon from "../../Icons/UnAutomateIcon";
import useIsReadyOnlyFrontEnd from "../../utils/useIsReadyOnlyFrontEnd";
import CustomListItem from "../CustomListItem";
import YouHaveReadOnlyAccess from "../YouHaveReadOnlyAccess";
import AutomateMenuItemDiv from "./AutomateMenuItemDiv";
import SelectActionItem from "./SelectActionItem";
import { CantEditScaleopsWorkloadsTooltip } from "./utils";

interface Props {
  automateByFilter: (state: boolean) => void;
  policies: components["schemas"]["V1alpha1Policy"][];
  attachPolicyToNamespace: (policyName: string) => void;
  disable: boolean;
}

const NamespacesActionsMenu = ({ automateByFilter, policies, attachPolicyToNamespace, disable }: Props) => {
  const isReadyOnlyFrontEnd = useIsReadyOnlyFrontEnd();
  const [namespacesParams] = useQueryParam("namespaces", ArrayParam);
  const [isNamespacesExclude] = useQueryParam("isNamespacesExclude", BooleanParam);
  const [namespaceAnnotationParams] = useQueryParam("namespaceAnnotation", ArrayParam);
  const isDisabled =
    (!namespacesParams || namespacesParams.length === 0) &&
    (!namespaceAnnotationParams || namespaceAnnotationParams.length === 0);

  if (isDisabled || disable) {
    return null;
  }

  const isOptionDisabled = isReadyOnlyFrontEnd || !!isNamespacesExclude;

  const options = [
    {
      name: "Automate",
      callback: () => {
        automateByFilter(true);
      },
      handleOnClick: true,
      disabled: isOptionDisabled,
      icon: <AutomationIcon />,
      renderRow: () => <AutomateMenuItemDiv />,
    },
    {
      name: "Un-Automate",
      callback: () => {
        automateByFilter(false);
      },
      disabled: isOptionDisabled,
      handleOnClick: true,
      icon: <UnAutomateIcon />,
    },
    {
      name: (
        <SelectActionItem
          options={policies.map((policy) => policy.metadata?.name).filter((policy) => policy !== undefined) as string[]}
          onChange={(policyName) => attachPolicyToNamespace(policyName)}
          preSelectText="Attach policy "
          postSelectText=""
        />
      ),
      disabled: isOptionDisabled,
      // handleOnClick: policyNameToNamespace !== "",
      icon: <PolicyIcon />,
    },
  ];

  const numberOfSelectedNamespaces = namespacesParams?.length ?? 0;
  const numberOfSelectedNamespaceAnnotations = namespaceAnnotationParams?.length ?? 0;
  const scaleopsSystemSelected = namespacesParams?.includes("scaleops-system");
  const onlyScaleopsSystem = numberOfSelectedNamespaces === 1 && scaleopsSystemSelected;

  return (
    <>
      {isReadyOnlyFrontEnd && <YouHaveReadOnlyAccess className="mb-2 ml-4" chipDesign />}
      <div className="mb-4 mx-4">
        <Typography variant="body2" className="p-4 max-w-[410px] border border-border rounded-lg">
          <div className="flex gap-3 justify-start items-center">
            <CircleIconWrapper
              className="text-white bg-primary-purpleBlue"
              icon={<BulkIcon width={14} height={14} />}
            />
            <div className="grow text-primary-purpleBlue ">
              {!!numberOfSelectedNamespaces && (
                <span>
                  {onlyScaleopsSystem ? "scaleops-system" : numberOfSelectedNamespaces}{" "}
                  <b className="scaleopsTextShadow">{pluralize("namespace", numberOfSelectedNamespaces)}</b>{" "}
                  {!!numberOfSelectedNamespaces && !!numberOfSelectedNamespaceAnnotations ? "and," : "selected."}
                </span>
              )}
              {!!numberOfSelectedNamespaces && !!numberOfSelectedNamespaceAnnotations && <br />}
              {!!numberOfSelectedNamespaceAnnotations && (
                <span>
                  {numberOfSelectedNamespaceAnnotations}{" "}
                  <b className="scaleopsTextShadow">
                    namespace {pluralize("annotation", numberOfSelectedNamespaceAnnotations)}
                  </b>{" "}
                  selected.
                </span>
              )}
            </div>
          </div>
          <br />
          <span className="text-[14px] text-primary-purpleBlue scaleopsTextShadow">
            {onlyScaleopsSystem ? (
              CantEditScaleopsWorkloadsTooltip
            ) : (
              <div>
                This action will be applied to all <b>selected and future workloads</b>.
              </div>
            )}
          </span>

          {isNamespacesExclude && (
            <div className="text-[10px] mt-[12px] gap-2 flex flex-col">
              <CustomListItem
                content={
                  <>
                    <b>Note:</b> you have selected <b>Exclude Namespaces</b> filter.
                    <br />
                    Select <b>Include Namespaces</b> filter to take action.
                  </>
                }
              />
            </div>
          )}
        </Typography>
      </div>
      {options.map((option) => (
        <MenuItem
          key={option.name.toString()}
          onClick={option.callback}
          disabled={isDisabled || isReadyOnlyFrontEnd || option.disabled || onlyScaleopsSystem}
        >
          {option.renderRow ? (
            option.renderRow()
          ) : (
            <div className="pl-[22px] flex gap-2 justify-center items-center h-[30px]">
              {option.icon && <ListItemIcon>{option.icon}</ListItemIcon>}
              <Typography variant="body2">{option.name}</Typography>
            </div>
          )}
        </MenuItem>
      ))}
    </>
  );
};

export default NamespacesActionsMenu;
