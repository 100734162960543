import { Typography } from "@mui/material";
import { useState } from "react";
import { NumberParam, QueryParamConfig, StringParam, useQueryParam, withDefault } from "use-query-params";
import RangePicker, { RangeOptions } from "../../components/RangePicker/RangePicker";
import ScaleopsIcon from "../../Icons/ScaleopsIcon";
import { DateType, getFromDate } from "../Analytics/AnalyticsV2/utils";
import ChartsContainer from "./Charts/ChartsContainer";
import NetworkAlerts from "./NetworkAlerts";
import TablesContainer from "./Tables/TablesContainer";
import { TableType } from "./Tables/utils";
import TopNetworkMetrics from "./TopNetworkMetrics";
import { CostReportType, HAS_NETWORK_REPORT } from "./utils";

const tableTypeConfig: QueryParamConfig<TableType, TableType> = {
  encode: (value) => value,
  decode: (value) => value as TableType,
};

interface Props {
  reportType: CostReportType;
}

const CostContainer = ({ reportType }: Props) => {
  const [selectedTable, setSelectedTable] = useQueryParam<TableType>(
    "selectedTable",
    withDefault(tableTypeConfig, TableType.Aggregation)
  );
  const [currentEpoch] = useState(Date.now());
  const [fromDate, setFromDate] = useQueryParam("fromDate", withDefault(NumberParam, getFromDate(7, currentEpoch)));
  const [toDate, setToDate] = useQueryParam("toDate", withDefault(NumberParam, currentEpoch));
  const [range, setRange] = useQueryParam("range", withDefault(StringParam, "7d"));
  const date: DateType = {
    from: fromDate,
    to: toDate,
    range: range,
  };
  const setDate = (date: DateType) => {
    setFromDate(date.from);
    setToDate(date.to);
    setRange(date.range);
  };

  const costTitle = HAS_NETWORK_REPORT ? "Compute" : "Cost";

  return (
    <div className="p-4 flex flex-col gap-5">
      {reportType === CostReportType.Network && <NetworkAlerts />}
      <div className="flex gap-3 border border-border rounded-lg p-5 bg-white">
        <div className="flex items-center">
          <ScaleopsIcon width={60} height={60} />
        </div>
        <div className="grow">
          <Typography variant="h6" fontWeight="bold">
            {reportType === CostReportType.Network ? "Network" : costTitle} report
          </Typography>
          <Typography variant="body2">
            <b>
              Explore your {reportType === CostReportType.Network && "network"} cost by workloads, namespaces and
              cluster.
            </b>
          </Typography>
        </div>
        <div className="flex items-center">
          <RangePicker date={date} setDate={setDate} rangeOptions={RangeOptions.MEDIUM} />
        </div>
      </div>
      {reportType === CostReportType.Network && <TopNetworkMetrics selectedTable={selectedTable} />}
      <ChartsContainer
        selectedTable={selectedTable}
        reportType={reportType}
        hasSavings={reportType === CostReportType.Compute}
      />
      <TablesContainer selectedTable={selectedTable} setSelectedTable={setSelectedTable} reportType={reportType} />
    </div>
  );
};

export default CostContainer;
