import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { ListItemText, MenuItem } from "@mui/material";
import { StringParam, useQueryParam, withDefault } from "use-query-params";
import { GetWorkloadContainersResponse, GetWorkloadContainers } from "../../../api/fetcher";
import SingleSelect from "../../../components/SingleSelect";

export const SELECTED_CONTAINER_KEY = "selectedContainers";

const ALL_CONTAINERS = "All containers";

const { queryFn, queryKey } = GetWorkloadContainers();

interface Props {
  namespace: string;
  workloadName: string;
  workloadType: string;
}

const ContainerFilter = ({ namespace, workloadName, workloadType }: Props) => {
  const [selectedContainer, setSelectedContainer] = useQueryParam(
    SELECTED_CONTAINER_KEY,
    withDefault(StringParam, ALL_CONTAINERS)
  );
  const [disabled, setDisabled] = useState<boolean>(false);

  const { data, error } = useQuery<GetWorkloadContainersResponse, Error>({
    queryKey: [queryKey, namespace, workloadName, workloadType],
    queryFn: () =>
      queryFn({
        name: workloadName,
        namespace,
        workloadType,
      }),
  });

  useEffect(() => {
    if (!data?.containers || data?.containers?.length < 2) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [data]);

  if (error) {
    console.log("Error fetching workload containers", error);
    return null;
  }

  return (
    <SingleSelect<string | null | undefined>
      label="Container"
      disabled={disabled}
      className="w-[200px]"
      selected={selectedContainer}
      setSelected={(option) => {
        if (option === ALL_CONTAINERS) {
          setSelectedContainer(undefined);
          return;
        }
        setSelectedContainer(option ? option : undefined);
      }}
      renderOptionsFunc={() => {
        const containersList = data?.containers ? data?.containers : [];
        return [ALL_CONTAINERS, ...containersList].map((option) => {
          return (
            <MenuItem value={option} key={String(option)}>
              <ListItemText primary={option ? option : ALL_CONTAINERS} />
            </MenuItem>
          );
        });
      }}
      renderValue={(value) => {
        return value ? value : ALL_CONTAINERS;
      }}
    />
  );
};

export default ContainerFilter;
