import { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import { DataGrid, GridRenderCellParams } from "@mui/x-data-grid";
import clsx from "clsx";
import dayjs from "dayjs";
import CheckedIcon from "../../Icons/CheckedIcon";
import { components } from "../../api/schema";
import useGetApi from "../../api/useGetApi";
import { DEFAULT_DATE_TIME_FORMAT } from "../../utils/formatterUtils";
import { getDataGridSx } from "../../utils/styleUtils";
import Tooltip from "../Tooltip";

export default function WorkloadEventPopup(props: { namespace: string; name: string; className?: string }) {
  const api = useGetApi();

  const [events, setEvents] = useState<components["schemas"]["V1Event"][]>([]);

  function fetchEvents() {
    api
      .getFetcher()
      .path("/api/v1/events/byNamespace/{namespaceName}/byRecommendation/{recommendationName}")
      .method("get")
      .create()({
        namespaceName: props.namespace,
        recommendationName: props.name,
      })
      .then((response) => {
        if (response.ok) {
          if (response.data.events != undefined) {
            setEvents(response.data.events);
          }
        }
      })
      .catch((error) => console.error(error));
  }

  useEffect(() => {
    fetchEvents();
    const interval = setInterval(() => {
      fetchEvents();
    }, 1000 * 5);
    return () => clearInterval(interval);
  }, []);

  const rows = events?.map((event, index) => ({
    id: index,
    ...event,
  }));

  if (rows?.length === 0 || rows === undefined)
    return (
      <div className="w-full h-[300px] flex items-center justify-center">
        <div className="flex flex-col items-center justify-center gap-2">
          <Typography>No events found</Typography>
          <CheckedIcon width={40} height={40} className="animate-pulse" />
        </div>
      </div>
    );

  return (
    <div className={clsx(props?.className, "w-full mt-12")}>
      <DataGrid
        rows={rows}
        sx={{
          ...getDataGridSx(),
        }}
        columns={[
          {
            field: "lastTimestamp",
            headerName: "Time",
            width: 300,
            renderCell: (params: GridRenderCellParams<string, components["schemas"]["V1Event"]>) => {
              const displayValue = dayjs(params.value).format(DEFAULT_DATE_TIME_FORMAT);
              return (
                <Typography variant="body2" className="w-full truncate">
                  {displayValue}
                </Typography>
              );
            },
          },
          {
            field: "message",
            headerName: "Details",
            flex: 1,
            renderCell: (params: GridRenderCellParams<string, components["schemas"]["V1Event"]>) => {
              return (
                <Tooltip title={params.value} className="w-full">
                  <Typography variant="body2" className="w-full truncate">
                    {params.value}
                  </Typography>
                </Tooltip>
              );
            },
          },
        ]}
        pageSize={10}
        rowsPerPageOptions={[10, 25, 50, 100]}
        autoHeight
        disableSelectionOnClick
      />
    </div>
  );
}
