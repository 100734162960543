import { CircularProgress, Typography } from "@mui/material";

import clsx from "clsx";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import {
  Area,
  CartesianGrid,
  ComposedChart as RechartsComposedChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { AxisDomain } from "recharts/types/util/types";
import InfoIcon from "../../Icons/InfoIcon";
import { TooltipTrigger, UpdateActiveTooltips } from "../../pages/Analytics/AnalyticsV2/Graphs/hooks/useFreezeTooltip";
import { FrozenTooltipType } from "../../pages/Analytics/AnalyticsV2/Graphs/hooks/utils";
import {
  currencyFormatter,
  customNumberFormatter,
  getMemoryValue,
  getPercentageValue,
} from "../../utils/formatterUtils";
import CustomLegend from "../CustomLegend";
import InfoTooltip from "../Tooltip";
import useWindowSize from "../useWindowSize";
import CustomTooltip from "./CustomTooltip";
import {
  ChartData,
  ChartElement,
  defaultXAxisTickFormatter,
  INFO_ICON_SIZE,
  MAIN_WRAPPER_CLASS_NAME,
  renderNameFunction,
  YAxisTickFormatterType,
} from "./utils";

const Y_AXIS_HEADROOM = 1.25;
const GRID_OPACITY = 0.4;
const DEFAULT_FILL_OPACITY = 0.2;
const DEFAULT_STROKE_WIDTH = 2;
const MAX_DATA = "dataMax";

interface Props {
  data: ChartData;
  title: React.ReactNode;
  isLoading: boolean;
  elements: ChartElement[];
  yAxisTickFormatterType?: YAxisTickFormatterType;
  wrapDivClassName?: string;
  infoTooltip?: React.ReactNode;
  yAxisDomain?: AxisDomain;
  syncId?: string;
  hasLegend?: boolean;
  height?: number | string;
  infoTooltipMaxWidth?: number;
  xAxisTickFormatter?: (value: number | string | undefined, viewPeriod: number) => string;
  tooltip?: {
    tooltipId?: string;
    tooltipTrigger?: TooltipTrigger;
    frozenTooltipType?: FrozenTooltipType;
    updateActiveTooltips?: UpdateActiveTooltips;
    enableCopyTextOnClick?: boolean;
    hasShowingNumberOfItemsFooterDiv?: boolean;
  };
}

const ComposeChart = ({
  data,
  title,
  isLoading,
  elements,
  yAxisTickFormatterType,
  wrapDivClassName,
  infoTooltip,
  yAxisDomain,
  syncId,
  hasLegend = true,
  height = 200,
  infoTooltipMaxWidth = 660,
  xAxisTickFormatter = defaultXAxisTickFormatter,
  tooltip,
}: Props) => {
  const {
    tooltipId,
    tooltipTrigger,
    frozenTooltipType,
    updateActiveTooltips,
    enableCopyTextOnClick,
    hasShowingNumberOfItemsFooterDiv,
  } = tooltip ?? {};

  const { width: windowWidth } = useWindowSize();

  const [graphData, setGraphData] = useState<Record<string, number | string>[]>([]);
  const [maxDataPoint, setMaxDataPoint] = useState<number | string>("dataMax");
  const [viewPeriod, setViewPeriod] = useState<number>(168);
  const [chartComponents, setChartComponents] = useState<Record<string, string>>({});
  const [selectedChartComponents, setSelectedChartComponents] = useState<string[]>([]);
  const [legendComponentStyle, setLegendComponentStyle] = useState<{ [key: string]: { color: string } }>({});

  useEffect(() => {
    if (data) {
      let max: string | number = 0;
      data.forEach((point) => {
        const keys = Object.keys(point.values);
        keys.forEach((key) => {
          if (point.values[key] > Number(max)) {
            max = point.values[key];
          }
        });
      });

      if (max === 0) {
        max = MAX_DATA;
      } else {
        max = Math.ceil(Number(max) * Y_AXIS_HEADROOM);
      }

      setMaxDataPoint(max);
      setGraphData(data.map((point) => ({ timestamp: point.timestamp, ...point.values })));

      const firstTimestamp = data[0]?.timestamp;
      const lastTimestamp = data[data.length - 1]?.timestamp;
      const firstTimestampEpoch = dayjs(String(firstTimestamp)).unix();
      const lastTimestampEpoch = dayjs(String(lastTimestamp)).unix();
      const diff = lastTimestampEpoch - firstTimestampEpoch;

      setViewPeriod(Math.round(diff / 60 / 60));
      setChartComponents(
        data.reduce((acc, el) => {
          Object.keys(el.values).forEach((key) => {
            acc[key] = key;
          });
          return acc;
        }, {} as { [key: string]: string })
      );
      setSelectedChartComponents(elements.map((el) => el.key));
      setLegendComponentStyle(
        elements.reduce((acc, el) => {
          acc[el.key] = { color: el.color };
          return acc;
        }, {} as { [key: string]: { color: string } })
      );
    }
  }, [data]);

  if (isLoading) {
    return (
      <div className={clsx(wrapDivClassName, MAIN_WRAPPER_CLASS_NAME)} style={{ height }}>
        <CircularProgress size={40} />
      </div>
    );
  }

  const yAxisFormatter = (value: number) => {
    switch (yAxisTickFormatterType) {
      case YAxisTickFormatterType.Currency:
        return currencyFormatter(value);
      case YAxisTickFormatterType.Percentage:
        return getPercentageValue(value);
      case YAxisTickFormatterType.Memory:
        return getMemoryValue(value);
      default:
        return String(customNumberFormatter(value));
    }
  };

  switch (true) {
    case !!yAxisDomain:
      break;
    case maxDataPoint === "dataMax":
      yAxisDomain = ["auto", "auto"];
      break;
    default:
      yAxisDomain = [0, maxDataPoint];
  }

  return (
    <div
      className={clsx(wrapDivClassName, MAIN_WRAPPER_CLASS_NAME, {
        "pt-8 pb-4": hasLegend,
        "py-8": !hasLegend,
      })}
      style={{ height }}
    >
      <Typography variant="body2" className="w-full flex justify-center items-center gap-1">
        {title}
        {infoTooltip && (
          <InfoTooltip title={infoTooltip} placement="top" maxWidth={infoTooltipMaxWidth}>
            <InfoIcon width={INFO_ICON_SIZE} height={INFO_ICON_SIZE} />
          </InfoTooltip>
        )}
      </Typography>
      <ResponsiveContainer width="99%" height="99%">
        <RechartsComposedChart syncId={syncId} data={graphData}>
          <CartesianGrid strokeDasharray="4 4" opacity={GRID_OPACITY} />
          <Tooltip
            content={
              <CustomTooltip
                elements={elements}
                selectedChartComponents={selectedChartComponents}
                valueFormatter={yAxisFormatter}
                renderNameFunction={renderNameFunction}
                tooltipId={tooltipId}
                tooltipTrigger={tooltipTrigger}
                frozenTooltipType={frozenTooltipType}
                updateActiveTooltips={updateActiveTooltips}
                enableCopyTextOnClick={enableCopyTextOnClick}
                hasShowingNumberOfItemsFooterDiv={hasShowingNumberOfItemsFooterDiv}
              />
            }
            trigger={tooltipTrigger}
            wrapperStyle={{
              outline: "none",
            }}
          />
          {elements?.map((element) => {
            if (!selectedChartComponents.includes(element.key)) {
              return null;
            }
            return (
              <Area
                key={element.key}
                type={element.type ?? "linear"}
                dataKey={element.key}
                stroke={element.color}
                fill={element.fill ?? "none"}
                fillOpacity={element.fillOpacity ?? DEFAULT_FILL_OPACITY}
                strokeWidth={element.strokeWidth ?? DEFAULT_STROKE_WIDTH}
              />
            );
          })}
          <XAxis
            dataKey="timestamp"
            style={{ fontSize: "x-small" }}
            interval={Math.floor(data.length / (Number(windowWidth) / 300))}
            strokeWidth={2}
            tickFormatter={(value) => {
              if (graphData?.length === 0) return "";
              return xAxisTickFormatter(String(value), viewPeriod);
            }}
            tickLine={!(graphData?.length === 0)}
          />
          <YAxis style={{ fontSize: "x-small" }} domain={yAxisDomain} strokeWidth={2} tickFormatter={yAxisFormatter} />
        </RechartsComposedChart>
      </ResponsiveContainer>
      {hasLegend && (
        <div>
          <CustomLegend<string>
            selectedChartComponents={selectedChartComponents}
            setSelectedChartComponents={setSelectedChartComponents}
            componentStyle={legendComponentStyle}
            ChartComponents={chartComponents}
            renderNameFunction={(key) => renderNameFunction(key, elements)}
            className="-mt-1"
            fontWeight={500}
            fontSpanClassName="truncate"
            hasTooltip
          />
        </div>
      )}
    </div>
  );
};

export default ComposeChart;
