import clsx from "clsx";
import { CircularProgress, Typography } from "@mui/material";

interface Props {
  hasTitle?: boolean;
  hasFullWrapper?: boolean;
  className?: string;
  wrapperClassName?: string;
}

const Loading = ({ className, hasTitle = true, hasFullWrapper, wrapperClassName }: Props) => {
  return (
    <div
      className={clsx(
        wrapperClassName,
        "text-main-gray flex justify-center items-center",
        {
          "w-full h-full": hasFullWrapper,
        },
        className
      )}
    >
      <div className="flex flex-col items-center justify-center gap-2">
        <CircularProgress />
        {hasTitle && <Typography variant="body2">Loading...</Typography>}
      </div>
    </div>
  );
};

export default Loading;
