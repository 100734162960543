import prettyBytes from "pretty-bytes";

export const tickNumberFormatter = (value: string) => {
  const val = Number(value);
  if (val >= 1000000000000) {
    return `${Math.round((val / 1000000000000) * 10) / 10}t`;
  } else if (val >= 1000000000) {
    return `${Math.round((val / 1000000000) * 10) / 10}b`;
  } else if (val >= 1000000) {
    return `${Math.round((val / 1000000) * 10) / 10}m`;
  } else if (val >= 1000) {
    return `${Math.round((val / 1000) * 10) / 10}k`;
  } else {
    return value;
  }
};

export const memoryDataParser = (value: number) =>
  value && value >= 0 && value < Infinity && value > -Infinity
    ? prettyBytes(value, { bits: false, binary: true })
    : "0 B";
