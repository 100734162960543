import { useState } from "react";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import {
  GetNodeGroupRecommendation,
  GetNodeGroupRecommendationResponse,
  GetNodeGroupShortInfo,
  GetNodeGroupShortInfoResponse,
} from "../../api/fetcher";
import { useQuery } from "@tanstack/react-query";
import { components } from "../../api/schema";
import Button from "../Button";
import Dialog from "../Dialog";
import * as React from "react";
import Box from "@mui/material/Box";
import Tooltip from "../Tooltip";
import { Stack, Typography } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import ResourceBar from "./ResourceBar";
import GreenArrow from "./GreenArrow";
import NiceSwitch from "./Switches";
import MakeRecommendationButton from "./MakeRecommendationButton";
import { NodeGroupMonthlyCost, formatXDigits } from "./Utils";
import CircularProgress from "@mui/material/CircularProgress";
import clsx from "clsx";
import CustomTooltip from "../Tooltip";
import { getDataGridSx } from "../../utils/styleUtils";
const CHIP_CLASS_NAME = "bg-background-chip rounded-full px-4 py-1";

const columns: GridColDef[] = [
  {
    field: "_id",
    headerName: "",
    width: 40,
    type: "string",
    align: "center",
    headerAlign: "center",
    disableColumnMenu: true,
    sortable: false,
  },
  {
    field: "instanceType",
    headerName: "Instance Type",
    description: "Instance Type",
    width: 240,
    type: "string",
    align: "left",
    headerAlign: "center",
    disableColumnMenu: true,
    sortable: true,
    renderCell: (params: GridRenderCellParams<string, rowType, string>) => {
      const chip = params.row.spot ? (
        <div className={clsx("bg-main-blue text-white", CHIP_CLASS_NAME)}>Spot</div>
      ) : (
        <CustomTooltip title={"On Demand"}>
          <div className={clsx("bg-main-orange text-white", CHIP_CLASS_NAME)}>OD</div>
        </CustomTooltip>
      );
      return (
        <div className="flex w-full justify-between items-center">
          <CustomTooltip title={params.row.instanceType}>
            <Typography variant="body2" noWrap={true}>
              {params.row.instanceType}
            </Typography>
          </CustomTooltip>
          {chip}
        </div>
      );

      // }
    },
  },
  {
    field: "cost",
    headerName: "Cost",
    description: "Cost Per Node",
    flex: 1,
    type: "string",
    align: "center",
    headerAlign: "center",
    disableColumnMenu: true,
    sortable: true,
    renderCell: (p: GridRenderCellParams<string, rowType, string>) => {
      const value = "$" + formatXDigits(p.row.cost, 1);
      const totalCost = p.row.cost * p.row.size;
      return (
        <Tooltip title={`Node cost: ${value}`}>
          <p>{`$${formatXDigits(totalCost)}`}</p>
        </Tooltip>
      );
    },
  },
  {
    field: "size",
    headerName: "Qty",
    description: "Number of Instances",
    flex: 1,
    type: "number",
    align: "center",
    headerAlign: "center",
    disableColumnMenu: true,
    sortable: true,
  },
  {
    field: "cpu",
    headerName: "CPU",
    description: "CPU Per Node",
    flex: 1,
    type: "string",
    align: "center",
    headerAlign: "center",
    disableColumnMenu: true,
    sortable: true,
    renderCell: (p: GridRenderCellParams<string, rowType, string>) => {
      const totalCpu = Math.round(p.row.cpu) * p.row.size;
      return (
        <Tooltip title={`Total cpu: ${formatXDigits(totalCpu, 1)}`}>
          <p>
            {formatXDigits(p.row.cpu, 1)}
            {/*<sub>vCpu</sub>*/}
          </p>
        </Tooltip>
      );
    },
  },
  {
    field: "mem",
    headerName: "Memory",
    description: "Memory Per Node",
    width: 110,
    // flex: 1,
    type: "string",
    align: "center",
    headerAlign: "center",
    disableColumnMenu: true,
    sortable: true,
    renderCell: (p: GridRenderCellParams<string, rowType, string>) => {
      const totalMem = Math.round(p.row.mem) * p.row.size;

      return (
        <Tooltip title={`Total mem: ${formatXDigits(totalMem, 1)}`}>
          <p>
            {formatXDigits(p.row.mem, 0)}
            <sub>GiB</sub>
          </p>
        </Tooltip>
      );
    },
  },
];

type rowType = {
  instanceType: string;
  size: number;
  cost: number;
  cpu: number;
  mem: number;
  spot: boolean;
  _id: number;
};

const calcTotals = (
  resp: GetNodeGroupRecommendationResponse["groups"] | GetNodeGroupShortInfoResponse["groups"] | undefined,
  useCapacity = false
) => {
  if (!resp) {
    return { totalCost: 0, totalMem: 0, totalCpu: 0 };
  }
  let totalCpu = 0;
  let totalMem = 0;
  let totalCost = 0;
  for (const group of resp) {
    totalCpu +=
      (useCapacity ? Math.round(group.instanceType.cpu.capacity) : group.instanceType.cpu.allocatable) * group.minNodes;
    totalMem +=
      (useCapacity ? Math.round(group.instanceType.mem.capacity) : group.instanceType.mem.allocatable) * group.minNodes;
    totalCost += NodeGroupMonthlyCost(group, false);
  }
  return { totalCost: Math.floor(totalCost), totalMem: totalMem, totalCpu: totalCpu };
};

interface Props {
  nNodes: number;
  totalCost: number;
  totalMem: number;
  totalCpu: number;
}

const NodeGroupTableSummary = ({ nNodes, totalCpu, totalCost, totalMem }: Props) => {
  const totalColumns: GridColDef[] = [
    {
      field: "_1",
      headerName: "",
      width: 290,
      type: "string",
      align: "left",
      headerAlign: "left",
      disableColumnMenu: true,
      sortable: false,
      renderHeader: () => {
        return <b className="ml-4">Totals:</b>;
      },
    },
    {
      field: "cost",
      headerName: "",
      description: "Total Monthly Cost",
      flex: 1,
      type: "string",
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
      sortable: false,
      renderHeader: () => {
        return (
          <b>
            ${formatXDigits(totalCost, 0)}
            <sub>/month</sub>
          </b>
        );
      },
    },
    {
      field: "qty",
      headerName: "",
      flex: 1,
      type: "number",
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
      sortable: false,
      renderHeader: () => {
        return <b>{nNodes}</b>;
      },
    },
    {
      field: "cpu",
      headerName: "cpu",
      description: "Total CPU",
      flex: 1,
      type: "string",
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
      sortable: false,
      renderHeader: () => {
        return (
          <Tooltip title="Cpu vCores Capacity - not all is allocatable">
            <b>{formatXDigits(totalCpu, 1)}</b>
          </Tooltip>
        );
      },
    },
    {
      field: "mem",
      headerName: "mem",
      description: "Total Memory",
      width: 110,
      type: "string",
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
      sortable: false,
      renderHeader: () => {
        return (
          <Tooltip title="Memory GiB Capacity - not all is allocatable">
            <b>
              {formatXDigits(totalMem, 0)}
              <sub>GiB</sub>
            </b>
          </Tooltip>
        );
      },
    },
  ];

  return (
    <DataGrid
      columns={totalColumns}
      rows={[]}
      hideFooter={true}
      headerHeight={56}
      rowHeight={0}
      autoHeight={true}
      components={{
        NoRowsOverlay: () => <div />,
      }}
    />
  );
};

const NodeGroupsDataGrid = (props: {
  rows: GetNodeGroupRecommendationResponse["groups"] | undefined;
  isLoading: boolean;
}) => {
  const { totalMem, totalCpu, totalCost } = calcTotals(props.rows, true);
  const dataToRow = (groups: GetNodeGroupRecommendationResponse["groups"] | undefined) => {
    if (!groups) {
      return [];
    }
    let counter = 0;
    return groups.map((group: components["schemas"]["NodeGroupsNodeGroupConfiguration"]) => {
      counter++;
      const cost = group.spot ? group.spotCost : group.cost;
      return {
        instanceType: group.instanceType.name,
        cpu: group.instanceType.cpu.capacity,
        size: group.minNodes,
        mem: group.instanceType.mem.capacity,
        cost: cost * 30 * 24,
        spot: group.spot,
        _id: counter,
      };
    });
  };
  const nNodes = props.rows?.map((r) => r.minNodes).reduce((a, b) => a + b, 0) || 0;
  return (
    <div style={{ width: 700 }}>
      <br />
      <Box style={{ padding: 20 }} bgcolor={"whitesmoke"} borderRadius={"10px"}>
        <DataGrid
          columns={columns}
          rows={dataToRow(props.rows)}
          autoHeight={true}
          loading={props.isLoading}
          sx={{
            ...getDataGridSx(),
            "& .MuiDataGrid-iconButtonContainer ": {
              display: "none",
            },
          }}
          hideFooter={true}
          getRowId={(r: rowType) => {
            return String(r.instanceType) + String(r.spot);
          }}
          initialState={{
            sorting: {
              sortModel: [{ field: "cost", sort: "desc" }],
            },
          }}
        />
        <NodeGroupTableSummary totalCost={totalCost} totalCpu={totalCpu} totalMem={totalMem} nNodes={nNodes} />
      </Box>
    </div>
  );
};
export const NodeGroupRecommendationView = () => {
  const [forceSpot, setForceSpot] = useState(true);
  // const [useRightsize, setUseRightsize] = useState(true);
  const useRightsize = false;
  const [showRecommendation, setShowRecommendation] = useState(false);

  const nodeGroupsConfigRecommendationQuery = GetNodeGroupRecommendation();
  const currentNodeGroupInfo = GetNodeGroupShortInfo();

  const recommendedQuery = useQuery<GetNodeGroupRecommendationResponse, Error>({
    queryKey: [nodeGroupsConfigRecommendationQuery.queryKey, useRightsize, forceSpot],
    queryFn: () => {
      return nodeGroupsConfigRecommendationQuery.queryFn({ assumeRightSized: useRightsize, applySpot: forceSpot });
    },
  });

  const recommendedIsError = recommendedQuery.error;
  const recommendedData = recommendedQuery.data;
  const recommendedIsLoading = recommendedQuery.isLoading;

  const oldQuery = useQuery<GetNodeGroupShortInfoResponse, Error>({
    queryKey: [currentNodeGroupInfo.queryKey],
    queryFn: currentNodeGroupInfo.queryFn,
  });

  const oldIsError = oldQuery.error;
  const oldData = oldQuery.data;
  const oldIsLoading = oldQuery.isLoading;

  const oldSummary = calcTotals(oldData?.groups, false);
  const recommendedSummary = calcTotals(recommendedData?.groups);

  const profit = oldSummary.totalCost - recommendedSummary.totalCost;
  const negativeProfit = profit < 0;
  const displayProfit = negativeProfit ? 0 : profit;
  const profitPercent = 100 * (displayProfit / oldSummary.totalCost);

  const arrowPos =
    recommendedData && oldData ? Math.min(recommendedData.groups?.length, oldData.groups?.length) || 0 : null;
  let content: JSX.Element;
  if (recommendedIsError || oldIsError) {
    content = (
      <div className="justify-center text-center">
        <Typography variant="h5" color="error">
          Failed to generate a recommendation{" "}
        </Typography>
        <p>An error has occurred</p>
      </div>
    );
  } else if (oldIsLoading && recommendedIsLoading) {
    content = (
      <Box sx={{ width: "100%" }}>
        <div>
          <h3>Generating a recommendation</h3>
          <LinearProgress />
        </div>
      </Box>
    );
  } else {
    content = (
      <div>
        <div>
          <div className={"flex"}>
            <div>
              <Typography variant="h5" align="center">
                Current
              </Typography>
              <NodeGroupsDataGrid rows={oldData?.groups} isLoading={oldIsLoading} />
            </div>
            {arrowPos !== null && <GreenArrow padding={75 + (56 * (1 + arrowPos)) / 2} />}
            <div>
              <Typography variant="h5" align="center">
                Recommended
              </Typography>
              <NodeGroupsDataGrid
                rows={negativeProfit ? oldData?.groups : recommendedData?.groups}
                isLoading={recommendedIsLoading}
              />
            </div>
          </div>

          <div style={{ display: "flex", width: "100%" }}>
            <div style={{ width: "100%" }}>
              <ResourceBar
                request={oldData?.totalCurrentMemoryRequest || 0}
                allocatable={oldSummary.totalMem}
                units={"GiB"}
                loading={oldIsLoading}
              />
              <p>Memory</p>
              <ResourceBar
                request={oldData?.totalCurrentCpuRequest || 0}
                allocatable={oldSummary.totalCpu}
                units={""}
                loading={oldIsLoading}
              />
              <p>CPU</p>
            </div>
            <GreenArrow padding={50} />
            <div style={{ width: "100%" }}>
              <ResourceBar
                request={
                  (negativeProfit ? oldData?.totalCurrentMemoryRequest : recommendedData?.totalCurrentMemoryRequest) ||
                  0
                }
                allocatable={negativeProfit ? oldSummary.totalMem : recommendedSummary.totalMem}
                units={"GiB"}
                loading={recommendedIsLoading}
              />
              <p>Memory</p>
              <ResourceBar
                request={
                  (negativeProfit ? oldData?.totalCurrentCpuRequest : recommendedData?.totalCurrentCpuRequest) || 0
                }
                allocatable={negativeProfit ? oldSummary.totalCpu : recommendedSummary.totalCpu}
                units={""}
                loading={recommendedIsLoading}
              />
              <p>CPU</p>
            </div>
          </div>
        </div>
        <br />
        <br />
        <div className="flex">
          <Stack direction="row" spacing={2}>
            <NiceSwitch
              label="Optimize Spot Workloads"
              checked={forceSpot}
              onChange={setForceSpot}
              tooltip={"Recommend spot instances for workloads that are spot ready"}
            />
            {/*<NiceSwitch*/}
            {/*  label="Include Workload Rightsizing"*/}
            {/*  checked={useRightsize}*/}
            {/*  onChange={setUseRightsize}*/}
            {/*  tooltip={"Show recommended configuration if right size automation is applied on all workloads"}*/}
            {/*/>*/}
          </Stack>
          <div className="justify-center text-center w-full">
            <Typography variant="h6" align="center">
              Cost Savings
            </Typography>
            {oldIsLoading || recommendedIsLoading ? (
              <CircularProgress />
            ) : (
              <Tooltip title={`%${formatXDigits(profitPercent)} Per Month`}>
                <Typography variant="h5" align="center" sx={{ color: "green" }}>
                  ${formatXDigits(displayProfit, 0)}
                </Typography>
              </Tooltip>
            )}
          </div>
          <div className="w-36" />
        </div>
      </div>
    );
  }

  const closeDialog = () => {
    setShowRecommendation(false);
  };

  return (
    <div>
      <MakeRecommendationButton
        onClick={() => {
          setShowRecommendation(true);
        }}
      />
      <Dialog isOpen={showRecommendation} onClose={closeDialog} title={"Recommended Node Group Configuration"}>
        {content}
        <div className="flex justify-end gap-4">
          <Tooltip title={"Apply Changes (Require permission with the cloud provider)"}>
            <Button onClick={closeDialog} disabled={true} label="Apply" />
          </Tooltip>
          <Tooltip title={"Close (do not apply)"}>
            <Button onClick={closeDialog} label="Close" />
          </Tooltip>
        </div>
      </Dialog>
    </div>
  );
};
