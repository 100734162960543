import { useEffect, useRef, useState } from "react";
import { BooleanParam, useQueryParam } from "use-query-params";
import { CircularProgress, Typography } from "@mui/material";
import { DataGrid, GridColumns } from "@mui/x-data-grid";
import { GetCaConfigurationResponse } from "../../api/fetcher";
import { getDataGridSx } from "../../utils/styleUtils";
import CodeSnippet from "../CodeSnippet";
import useUpdateCaConfiguration from "./useUpdateCaConfiguration";
import { AZURE_CODE, getColumns, getOriginalOrder, getOriginalValue, OrderEntry, RowType } from "./actionsUtils";

const columns: GridColumns<RowType> = getColumns();

const Param = ({ name }: { name: string }) => (
  <span className="bg-background-chip px-2 rounded text-[#0c8a58]" style={{ fontWeight: 500 }}>
    {name}
  </span>
);

const CLI_DESCRIPTION = (
  <>
    Run the command below to update the cluster autoscaler configuration with <b>ScaleOps recommended values</b>.
  </>
);

const AZURE = "azure";

interface Props {
  data: GetCaConfigurationResponse | undefined;
  rawConfigurationData: GetCaConfigurationResponse | undefined;
  isLoading: boolean;
}

const isDisabled = (
  scaleopsNodesAnnotations: GetCaConfigurationResponse["scaleopsNodesAnnotations"],
  configurationKey: string,
  isChecked: boolean
) => (!scaleopsNodesAnnotations || !Object.keys(scaleopsNodesAnnotations).includes(configurationKey)) && !!isChecked;

const NodesActions = ({ isLoading, data, rawConfigurationData }: Props) => {
  const updateCaConfiguration = useUpdateCaConfiguration();
  const [rows, setRows] = useState<RowType[] | undefined>(undefined);
  const [order, setOrder] = useState<OrderEntry[] | undefined>(undefined);
  const [scrollToNodeActions] = useQueryParam("scrollToNodeActions", BooleanParam);
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (scrollToNodeActions) containerRef?.current?.scrollIntoView();
  }, [isLoading, containerRef, scrollToNodeActions]);

  useEffect(() => {
    const hasScaleDownUtilizationThresholdOver69 =
      data?.scaleDownUtilizationThreshold && data?.scaleDownUtilizationThreshold > 0.69 ? true : false;

    const isExpenderStrategyChecked = data?.expander === "least-waste";

    const rowsData: RowType[] = [
      {
        name: "Scale down utilization threshold",
        id: "scale-down-utilization-threshold",
        description: (
          <>
            It is recommended to set the <Param name="--scale-down-utilization-threshold" /> to 70% in order to reduce
            cost.
          </>
        ),
        values: {
          current: data?.scaleDownUtilizationThreshold ?? 0,
          recommended: 0.7,
        },
        isChecked: hasScaleDownUtilizationThresholdOver69,
        originalOrder: Number(getOriginalValue("originalOrder", "scale-down-utilization-threshold", order)),
        isDisabled: isDisabled(
          data?.scaleopsNodesAnnotations,
          "scale-down-utilization-threshold",
          hasScaleDownUtilizationThresholdOver69
        ),
        onChange: (value) => {
          updateCaConfiguration.mutate({
            configurationKey: "scale-down-utilization-threshold",
            optimize: value,
            ownerName: String(data?.ownerName),
            podNamespace: String(data?.podNamespace),
            data,
          });
        },
      },
      {
        name: "Skip nodes with local storage",
        id: "skip-nodes-with-local-storage",
        description: (
          <>
            Avoid skipping nodes with local storage by disabling <Param name="--skip-nodes-with-local-storage" />
          </>
        ),
        values: {
          current: data?.skipNodesWithLocalStorage,
          recommended: false,
        },
        isChecked: !data?.skipNodesWithLocalStorage,
        originalOrder: Number(getOriginalValue("originalOrder", "skip-nodes-with-local-storage", order)),
        isDisabled: isDisabled(
          data?.scaleopsNodesAnnotations,
          "skip-nodes-with-local-storage",
          !data?.skipNodesWithLocalStorage
        ),
        onChange: (value) => {
          updateCaConfiguration.mutate({
            configurationKey: "skip-nodes-with-local-storage",
            optimize: value,
            ownerName: String(data?.ownerName),
            podNamespace: String(data?.podNamespace),
            data,
          });
        },
      },
      {
        name: "Skip nodes with system pods",
        id: "skip-nodes-with-system-pods",
        description: (
          <>
            Evaluate nodes with system pods by disabling <Param name="--skip-nodes-with-system-pods" />
          </>
        ),
        isChecked: !data?.skipNodesWithSystemPods,
        originalOrder: Number(getOriginalValue("originalOrder", "skip-nodes-with-system-pods", order)),
        isDisabled: isDisabled(
          data?.scaleopsNodesAnnotations,
          "skip-nodes-with-system-pods",
          !data?.skipNodesWithSystemPods
        ),
        values: {
          current: data?.skipNodesWithSystemPods,
          recommended: false,
        },
        onChange: (value) => {
          updateCaConfiguration.mutate({
            configurationKey: "skip-nodes-with-system-pods",
            optimize: value,
            ownerName: String(data?.ownerName),
            podNamespace: String(data?.podNamespace),
            data,
          });
        },
      },
      {
        name: "Expender strategy",
        id: "expander",
        description: (
          <>
            Use <Param name="--expander" /> with 'least-waste' to expend node groups by the least amount of waste.
          </>
        ),
        isChecked: isExpenderStrategyChecked,
        originalOrder: Number(getOriginalValue("originalOrder", "expander", order)),
        values: {
          current: data?.expander,
          recommended: "least-waste",
        },
        isDisabled: isDisabled(data?.scaleopsNodesAnnotations, "expander", isExpenderStrategyChecked),
        onChange: (value) => {
          updateCaConfiguration.mutate({
            configurationKey: "expander",
            optimize: value,
            ownerName: String(data?.ownerName),
            podNamespace: String(data?.podNamespace),
            data,
          });
        },
      },
      {
        name: "Scale down unneeded time",
        id: "scale-down-unneeded-time",
        description: (
          <>
            Set <Param name="--scale-down-unneeded-time" /> to 5 minutes to expedite scaling down of nodes.
          </>
        ),
        isChecked: data?.scaleDownUnneededTime === "5m",
        originalOrder: Number(getOriginalValue("originalOrder", "scale-down-unneeded-time", order)),
        values: {
          current: data?.scaleDownUnneededTime,
          recommended: "5m",
        },
        isDisabled: isDisabled(
          data?.scaleopsNodesAnnotations,
          "scale-down-unneeded-time",
          data?.scaleDownUnneededTime === "5m"
        ),
        onChange: (value) => {
          updateCaConfiguration.mutate({
            configurationKey: "scale-down-unneeded-time",
            optimize: value,
            ownerName: String(data?.ownerName),
            podNamespace: String(data?.podNamespace),
            data,
          });
        },
      },
    ];

    // rowsData = rowsData.filter((row) => !row.isDisabled);

    setRows(rowsData);
  }, [data, order]);

  useEffect(() => {
    if (rows && rows.length > 0 && !order && data) {
      setOrder(
        rows.map((r) => {
          return {
            id: r.id,
            originalOrder: getOriginalOrder(r),
          };
        })
      );
    }
  }, [rows]);

  if (rawConfigurationData?.cloudProvider?.toLowerCase() === AZURE && !rawConfigurationData.caFound) {
    return (
      <div className="bg-white rounded-lg items-center relative px-4 py-8">
        <Typography variant="body1" fontWeight={700} className="mt-4">
          AKS configuration
        </Typography>
        <CodeSnippet description={CLI_DESCRIPTION} codeSnippet={AZURE_CODE} />
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="bg-white rounded-lg flex-grow flex items-center justify-center min-w-[700px] h-[300px] w-[100%] left-[0px] top-[0px]">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="bg-white rounded-lg items-center relative" ref={containerRef}>
      <div className="flex flex-col h-full">
        <div className="px-[30px] pt-[30px]  flex-grow flex flex-col overflow-y-scroll">
          {/*<ProvisionersActions hasHrLine={rows && rows.length > 0} />*/}
          {rows && rows.length > 0 && (
            <>
              <Typography variant="body1" fontWeight={700} className="mt-4">
                Cluster Autoscaler configuration
              </Typography>
              <Typography variant="body2" className="mt-4">
                Optimize your cluster Autoscaler by using the recommendations below.
              </Typography>
              <div className="py-4">
                <DataGrid
                  sx={{
                    ...getDataGridSx(),
                  }}
                  rows={rows ?? []}
                  columns={columns}
                  autoHeight={true}
                  rowHeight={80}
                  disableSelectionOnClick
                  hideFooter
                  disableColumnMenu
                  sortModel={[
                    {
                      field: "originalOrder",
                      sort: "asc",
                    },
                  ]}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default NodesActions;
