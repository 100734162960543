import { ResponsiveContainer, BarChart, XAxis, YAxis, Bar } from "recharts";
import CustomizedBarLabel from "../../../components/CustomizedBarLabel";

interface Props {
  currentCost: number;
  optimizedCost: number;
}

const CURRENT_COLOR = "#FFCC43";
const OPTIMIZED_COLOR = "#52D28D";

const CostBarChart = ({ currentCost, optimizedCost }: Props) => {
  currentCost = isNaN(currentCost) || currentCost < 0 ? 0 : currentCost;
  currentCost = Math.round(currentCost * 100) / 100;
  optimizedCost = isNaN(optimizedCost) || optimizedCost < 0 ? 0 : optimizedCost;
  optimizedCost = Math.round(optimizedCost * 100) / 100;

  return (
    <ResponsiveContainer width="99%" height={50}>
      <BarChart
        data={[
          {
            current: currentCost,
            optimized: optimizedCost,
          },
        ]}
        barGap={5}
        barCategoryGap={0}
        layout="vertical"
        margin={{
          top: 0,
          right: 100,
          left: 0,
          bottom: 0,
        }}
      >
        <XAxis type="number" hide={true} />
        <YAxis type="category" dataKey="a" hide={true} />
        <Bar
          dataKey="current"
          fill={CURRENT_COLOR}
          isAnimationActive={false}
          radius={10}
          label={(props) => <CustomizedBarLabel yAdjust={15} prefix="Current: $" {...props} />}
        />
        <Bar
          dataKey="optimized"
          fill={OPTIMIZED_COLOR}
          isAnimationActive={false}
          radius={10}
          label={(props) => <CustomizedBarLabel yAdjust={15} prefix="Optimized: $" {...props} />}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default CostBarChart;
