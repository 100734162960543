import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import {
  Area,
  CartesianGrid,
  ComposedChart,
  Line,
  ReferenceArea,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts";
import { ObjectParam, useQueryParam } from "use-query-params";
import { getTimeFormatFromEpochAndPeriodInHours } from "../../../../utils/formatterUtils";
import { EventPoint } from "../Diagnostics/utils";
import {
  POLICY_TUNING_DATES_URL_PARAM,
  useViewPeriodQueryParams,
  getTriggerComponentColor,
  hpaComponentStyle,
  getPredictionComponentColor,
  displayNameHpaTooltipFormatter,
} from "../utils";
import GraphLoading from "./GraphLoading";
import { AverageUsageColor } from "./Styles";
import CustomHpaTooltip from "./CustomHpaTooltip";

const AVERAGE_USAGE_STROKE_WIDTH = 1;
const REGULAR_STROKE_WIDTH = 2;
const BOLD_STROKE_WIDTH = 2.5;

const defaultYTickFormatter = (tick: string | number) => {
  const value = Number(tick);
  if (value === 0) return "0";
  const roundedValue = Math.round(value * 100) / 100;
  return String(roundedValue);
};

type HpaGraphDataType = {
  [key: string]: number | string | undefined | null;
}[];

export type HpaChartComponent =
  | "currentMinReplicas"
  | "currentReplicas"
  | "originalReplicas"
  | "originalMinReplicas"
  | "recommendedReplicas"
  | "recommendedMinReplicas"
  | "recommendedMinReplicasP60"
  | "recommendedMinReplicasPercentile"
  | "maxValueRecordedReplicas"
  | `triggerBasedReplicas:${string}`
  | `predictionBasedReplicas:${string}`
  | `predictionBasedReplicasWithHeadroom:${string}`;

interface Props {
  title: string;
  selectedChartComponents: HpaChartComponent[];
  data: HpaGraphDataType;
  marginLeft?: number;
  yTickFormatter?: (tick: string | number) => string;
  isLoading?: boolean;
  setEmptyEventArray?: React.Dispatch<React.SetStateAction<EventPoint[] | undefined>>;
  wrapperHeight?: number;
  minMaxYAxisDomainValue?: number;
}

const UsageHpaChart = ({
  title,
  selectedChartComponents,
  data,
  marginLeft,
  yTickFormatter = defaultYTickFormatter,
  isLoading,
  wrapperHeight = 250,
  minMaxYAxisDomainValue = 0,
}: Props) => {
  const [, setDates] = useQueryParam(POLICY_TUNING_DATES_URL_PARAM, ObjectParam);
  const [selectedViewPeriod, setSelectedViewPeriod] = useViewPeriodQueryParams();

  const [selectPosition, setSelectPosition] = useState<
    { from?: number; to?: number; fromX?: string; toX?: string } | undefined
  >(undefined);

  const graphData = data;

  const minXValue = graphData && graphData.length > 0 ? Number(graphData[0]["timestamp"]) : 0;
  const maxXValue = graphData && graphData.length > 0 ? Number(graphData[graphData.length - 1]["timestamp"]) : 0;

  const maxGraphValue = Math.max(
    ...graphData.map((dataPointMap) => {
      /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
      const { timestamp, ...remainingDataPoint } = dataPointMap;
      const valuesAsArray = Object.values(remainingDataPoint);
      return Math.max(...valuesAsArray.map((val) => (val ? Number(val) : 0)));
    })
  );

  const setDateRage = () => {
    if (selectPosition?.from && selectPosition?.to) {
      const from = Math.min(selectPosition?.from || 0, selectPosition?.to || 0);
      const to = Math.max(selectPosition?.from || 0, selectPosition?.to || 0);
      setDates({ from: String(from), to: String(to) });
      if (from && to && setSelectedViewPeriod) {
        setSelectedViewPeriod(String(Math.round((to - from) / 60 / 60)));
      }
    }
    setSelectPosition(undefined);
  };

  useEffect(() => {
    const handleMouseUp = () => {
      if (selectPosition?.from && selectPosition?.to) {
        setDateRage();
      }
    };

    window.addEventListener("mouseup", handleMouseUp);

    return () => {
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [selectPosition]);

  const triggerComponents = selectedChartComponents.filter((component) =>
    component.startsWith("triggerBasedReplicas:")
  );
  const predictionComponents = selectedChartComponents.filter((component) =>
    component.startsWith("predictionBasedReplicas:")
  );
  const predictionWithHeadroomComponents = selectedChartComponents.filter((component) =>
    component.startsWith("predictionBasedReplicasWithHeadroom:")
  );

  return (
    <>
      <div
        className="w-full py-[10px] unselectable-svg-text"
        style={{
          height: wrapperHeight,
        }}
      >
        {isLoading && (
          <GraphLoading className="w-full flex justify-end items-center pr-2  h-[50px] -mb-[50px] z-50 sticky" />
        )}
        <Typography variant="body2" fontWeight={600} className="pl-[5px] text-center">
          {title}
        </Typography>
        <ResponsiveContainer>
          <ComposedChart
            data={graphData}
            margin={{
              top: 15,
              bottom: 10,
              left: marginLeft ?? 0,
            }}
            syncId="policyTuning"
            onMouseDown={(e) => {
              e.activeLabel &&
                setSelectPosition({
                  ...selectPosition,
                  from: Number(e.activeLabel),
                  fromX: e.activeLabel,
                });
            }}
            onMouseMove={(e) => {
              if (selectPosition?.from) {
                setSelectPosition({
                  ...selectPosition,
                  to: Number(e.activeLabel),
                  toX: e.activeLabel ?? String(minXValue),
                });
              }
            }}
            onMouseUp={() => {
              setDateRage();
            }}
            onMouseLeave={() => {
              if (selectPosition?.from && selectPosition?.to && selectPosition?.fromX && selectPosition?.toX) {
                let to = 0;
                switch (true) {
                  case Number(selectPosition?.toX) > Number(selectPosition?.fromX):
                    to = maxXValue;
                    break;
                  case Number(selectPosition?.toX) < Number(selectPosition?.fromX):
                    to = minXValue;
                    break;
                  default:
                    to = maxXValue;
                }

                setSelectPosition({
                  ...selectPosition,
                  to: to,
                  toX: String(to),
                });
              }
            }}
          >
            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="100%" stopColor={AverageUsageColor} stopOpacity={0.5} />
                <stop offset="100%" stopColor={AverageUsageColor} stopOpacity={0.1} />
              </linearGradient>
            </defs>
            <CartesianGrid stroke="#f5f5f5" />
            {selectedChartComponents.includes("originalReplicas") && (
              <Line
                type="monotone"
                dataKey="originalReplicas"
                stroke={hpaComponentStyle["originalReplicas"].color}
                dot={false}
                strokeWidth={REGULAR_STROKE_WIDTH}
              />
            )}
            {selectedChartComponents.includes("originalMinReplicas") && (
              <Line
                type="monotone"
                dataKey="originalMinReplicas"
                stroke={hpaComponentStyle["originalMinReplicas"].color}
                dot={false}
                strokeWidth={REGULAR_STROKE_WIDTH}
              />
            )}
            {selectedChartComponents.includes("currentMinReplicas") && (
              <Line
                type="monotone"
                dataKey="currentMinReplicas"
                stroke={hpaComponentStyle["currentMinReplicas"].color}
                dot={false}
                strokeWidth={REGULAR_STROKE_WIDTH}
              />
            )}
            {selectedChartComponents.find((item) => item.startsWith("triggerBasedReplicas:"))
              ? triggerComponents.map((metricName, index) => (
                  <Area
                    type="linear"
                    dataKey={`${metricName}`}
                    fill="url(#colorUv)"
                    stroke={getTriggerComponentColor(index)}
                    strokeWidth={AVERAGE_USAGE_STROKE_WIDTH}
                  />
                ))
              : null}
            {selectedChartComponents.find((item) => item.startsWith("predictionBasedReplicas:"))
              ? predictionComponents.map((predictionName, index) => (
                  <Line
                    type="linear"
                    dataKey={`${predictionName}`}
                    stroke={getPredictionComponentColor(index)}
                    dot={false}
                    strokeWidth={BOLD_STROKE_WIDTH}
                  />
                ))
              : null}
            {selectedChartComponents.find((item) => item.startsWith("predictionBasedReplicasWithHeadroom:"))
              ? predictionWithHeadroomComponents.map((predictionName, index) => (
                  <Line
                    type="linear"
                    dataKey={`${predictionName}`}
                    stroke={getPredictionComponentColor(index)}
                    dot={false}
                    strokeWidth={BOLD_STROKE_WIDTH}
                  />
                ))
              : null}
            {selectedChartComponents.includes("recommendedReplicas") && (
              <Line
                type="linear" // 'basis' | 'basisClosed' | 'basisOpen' | 'linear' | 'linearClosed' | 'natural' | 'monotoneX' | 'monotoneY' | 'monotone' | 'step' | 'stepBefore' | 'stepAfter' | Function
                dataKey="recommendedReplicas"
                stroke={hpaComponentStyle["recommendedReplicas"].color}
                dot={false}
                strokeWidth={BOLD_STROKE_WIDTH}
              />
            )}
            {selectedChartComponents.includes("currentReplicas") && (
              <Line
                type="monotone"
                dataKey="currentReplicas"
                stroke={hpaComponentStyle["currentReplicas"].color}
                dot={false}
                strokeWidth={BOLD_STROKE_WIDTH}
              />
            )}
            {selectedChartComponents.includes("recommendedMinReplicas") && (
              <Line
                type="linear" // 'basis' | 'basisClosed' | 'basisOpen' | 'linear' | 'linearClosed' | 'natural' | 'monotoneX' | 'monotoneY' | 'monotone' | 'step' | 'stepBefore' | 'stepAfter' | Function
                dataKey="recommendedMinReplicas"
                stroke={hpaComponentStyle["recommendedMinReplicas"].color}
                dot={false}
                strokeWidth={BOLD_STROKE_WIDTH}
              />
            )}
            {selectedChartComponents.includes("recommendedMinReplicasP60") && (
              <Line
                type="linear" // 'basis' | 'basisClosed' | 'basisOpen' | 'linear' | 'linearClosed' | 'natural' | 'monotoneX' | 'monotoneY' | 'monotone' | 'step' | 'stepBefore' | 'stepAfter' | Function
                dataKey="recommendedMinReplicasP60"
                stroke={hpaComponentStyle["recommendedMinReplicasP60"].color}
                dot={false}
                strokeWidth={BOLD_STROKE_WIDTH}
              />
            )}
            {selectedChartComponents.includes("recommendedMinReplicasPercentile") && (
              <Line
                type="linear" // 'basis' | 'basisClosed' | 'basisOpen' | 'linear' | 'linearClosed' | 'natural' | 'monotoneX' | 'monotoneY' | 'monotone' | 'step' | 'stepBefore' | 'stepAfter' | Function
                dataKey="recommendedMinReplicasPercentile"
                stroke={hpaComponentStyle["recommendedMinReplicasPercentile"].color}
                dot={false}
                strokeWidth={BOLD_STROKE_WIDTH}
              />
            )}
            {selectedChartComponents.includes("maxValueRecordedReplicas") && (
              <Line
                type="linear" // 'basis' | 'basisClosed' | 'basisOpen' | 'linear' | 'linearClosed' | 'natural' | 'monotoneX' | 'monotoneY' | 'monotone' | 'step' | 'stepBefore' | 'stepAfter' | Function
                dataKey="maxValueRecordedReplicas"
                stroke={hpaComponentStyle["maxValueRecordedReplicas"].color}
                dot={false}
                strokeWidth={BOLD_STROKE_WIDTH}
              />
            )}
            {selectPosition?.fromX && selectPosition?.toX ? (
              <ReferenceArea
                x1={selectPosition?.fromX}
                x2={selectPosition?.toX}
                stroke="gray"
                fill="gray"
                fillOpacity={0.3}
                strokeOpacity={0.3}
              />
            ) : null}
            <XAxis
              dataKey="timestamp"
              strokeWidth={2}
              type="number"
              tick={{ fontSize: "10px" }}
              domain={[minXValue, maxXValue]}
              tickFormatter={(tick: string) => {
                if (data && data.length === 0) return tick;
                return getTimeFormatFromEpochAndPeriodInHours(Number(tick), selectedViewPeriod);
              }}
            />
            <YAxis
              style={{ fontSize: "x-small" }}
              domain={
                maxGraphValue > 0
                  ? [
                      0,
                      (dataMax: number) => {
                        const maxValue = Number(Number(dataMax * 1.3).toFixed(10));
                        return Math.max(maxValue, minMaxYAxisDomainValue);
                      },
                    ]
                  : undefined
              }
              tickFormatter={yTickFormatter}
              strokeWidth={2}
            />
            <Tooltip
              content={
                <CustomHpaTooltip
                  selectedChartComponents={selectedChartComponents}
                  yTickFormatter={yTickFormatter}
                  displayNameFormatter={displayNameHpaTooltipFormatter}
                  // tooltipTrigger={tooltipTrigger}
                  // frozenTooltipType={frozenTooltipType}
                />
              }
              trigger={"hover"}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </>
  );
};

export type { HpaGraphDataType };
export default UsageHpaChart;
