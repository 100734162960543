import React from "react";
import BetaIcon from "../Icons/BetaIcon";
import Tooltip from "./Tooltip";
import { IsSnapshotServer } from "../utils/FeaturesHelper";

interface Props {
  children?: React.ReactNode;
  isBeta?: boolean;
}

const BetaWrapper = ({ children, isBeta }: Props) => {
  if (isBeta) {
    return <>{children}</>;
  }

  if (!IsSnapshotServer()) {
    return <></>;
  }

  return (
    <div className="relative border-2 border-primary-purpleBlue rounded">
      <Tooltip
        title={
          <>
            This <b>Beta Feature</b> is disabled by the client, and is <b>only visible in snapshot</b>
          </>
        }
        placement="left"
      >
        <div className="absolute z-10 text-primary-purpleBlue bg-white flex px-2 mx-1 translate-y-[-50%] items-center">
          {" "}
          <BetaIcon />
          <small>Snapshot visibility</small>{" "}
        </div>
      </Tooltip>
      {children}
    </div>
  );
};

export default BetaWrapper;
