import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { GetNodeDiagnostics, GetNodeDiagnosticsResponse } from "../../../api/fetcher";
import DiagnosticsChart from "../../../pages/Overview/PolicyTuning/Diagnostics/DiagnosticsChart";
import { EventPoint, EventType } from "../../../pages/Overview/PolicyTuning/Diagnostics/utils";

interface Props {
  name: string | undefined;
  startDate: number;
  endDate: number;
  selectedViewPeriod: string;
  emptyEventArray: EventPoint[] | undefined;
}

const NodeDiagnosticsContainer = ({ name, startDate, endDate, emptyEventArray, selectedViewPeriod }: Props) => {
  const { queryKey, queryFn } = GetNodeDiagnostics();
  const [parsedData, setParsedData] = useState<EventPoint[] | undefined>(undefined);

  const { data } = useQuery<GetNodeDiagnosticsResponse, Error>({
    queryKey: [queryKey, name, endDate, startDate],
    queryFn: () => {
      return queryFn({
        name: String(name),
        from: startDate,
        to: endDate,
      });
    },
  });

  useEffect(() => {
    data?.diagnosticEventsSeries?.forEach((event) => {
      const parsedDataContent = emptyEventArray;
      const eventTime = event.timestamp;

      const index = parsedDataContent?.findIndex((item, i) => {
        if (!parsedDataContent) return false;
        return (
          (eventTime >= Number(item.timestamp) && eventTime < Number(parsedDataContent[i + 1]?.timestamp)) ||
          !parsedDataContent[i + 1]?.timestamp
        );
      });

      if (parsedDataContent && index) {
        parsedDataContent[index] = {
          ...parsedDataContent[index],
          nodeMemoryPressureEvent:
            (parsedDataContent[index]?.nodeMemoryPressureEvent ?? 0) + (event?.nodeMemoryPressureEvent ? 1 : 0),
          highMemoryUtilization:
            (parsedDataContent[index]?.highMemoryUtilization ?? 0) + (event?.highMemoryUtilization ? 1 : 0),
          highCpuUtilization: (parsedDataContent[index]?.highCpuUtilization ?? 0) + (event?.highCpuUtilization ? 1 : 0),
          oomKubelet: (parsedDataContent[index]?.oomKubelet ?? 0) + (event?.oomKubelet ? 1 : 0),
          oomLimit: (parsedDataContent[index]?.oomLimit ?? 0) + (event?.oomLimit ? 1 : 0),
          oomNode: (parsedDataContent[index]?.oomNode ?? 0) + (event?.oomNode ? 1 : 0),
        };
      }
      setParsedData(parsedDataContent);
    });
  }, [data, emptyEventArray]);

  return (
    <DiagnosticsChart
      data={parsedData}
      selectedViewPeriod={selectedViewPeriod}
      showMonthlyDateTimeFormatThreshold={0}
      includedElements={[
        // EventType.NODE_MEMORY_PRESSURE_EVENT,
        // EventType.HIGH_MEMORY_UTILIZATION,
        EventType.HIGH_CPU_UTILIZATION,
        // EventType.OOM_KUBELET,
        // EventType.OOM_LIMIT,
        EventType.OOM_NODE,
      ]}
    />
  );
};

export default NodeDiagnosticsContainer;
