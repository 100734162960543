import FormInput from "../../../components/formComponents/FormInput";
import FormTitleAndDescription from "../../../components/formComponents/FormTitleAndDescription";
import { shouldDisableDefaultPolicyInputs } from "../utils";

interface Props {
  disableCpuOption?: boolean;
  disableMemoryOption?: boolean;
  isCustomizedPolicy?: boolean;
}

const LimitsHeadroom = ({ disableCpuOption, disableMemoryOption, isCustomizedPolicy }: Props) => {
  return (
    <div className="flex flex-col gap-4 pt-5">
      <FormTitleAndDescription
        title="Limits headroom"
        description="Define limits headroom for container resources."
        info={
          <>
            This value calculated by the <b>resource limit percentiles</b> and can be editing on advanced tab
          </>
        }
        titleVariant="body2"
        textDisabled={disableCpuOption && disableMemoryOption}
      />
      <div className="flex gap-12">
        <div>
          <FormInput
            label="CPU (%)"
            name="cpuLimitsHeadroom"
            type="number"
            disabled={disableCpuOption || (!isCustomizedPolicy && shouldDisableDefaultPolicyInputs)}
            disableChange={!isCustomizedPolicy}
          />
        </div>
        <div>
          <FormInput
            label="Memory (%)"
            name="memoryLimitsHeadroom"
            type="number"
            disabled={disableMemoryOption || (!isCustomizedPolicy && shouldDisableDefaultPolicyInputs)}
            disableChange={!isCustomizedPolicy}
          />
        </div>
      </div>
    </div>
  );
};
export default LimitsHeadroom;
