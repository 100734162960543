import { ArrayParam, StringParam, useQueryParam } from "use-query-params";
import { NodePoolFilterQueryParams } from "../NodeGroups";

const useGetRebalanceQueryParams = (queryPrefix = "") => {
  const [searchTerm, setSearchTerm] = useQueryParam(queryPrefix + "setSearchTerm", StringParam);
  const [selectedNodeGroups, setSelectedNodeGroups] = useQueryParam(
    queryPrefix + NodePoolFilterQueryParams.NodeGroups,
    ArrayParam
  );
  const [selectedNodePools, setSelectedNodePools] = useQueryParam(
    queryPrefix + NodePoolFilterQueryParams.NodePools,
    ArrayParam
  );
  const [selectedProvisioners, setSelectedProvisioners] = useQueryParam(
    queryPrefix + NodePoolFilterQueryParams.Provisioners,
    ArrayParam
  );
  const [selectedAvailabilityZones, setSelectedAvailabilityZones] = useQueryParam(
    queryPrefix + NodePoolFilterQueryParams.AvailabilityZones,
    ArrayParam
  );
  const [selectedScaleDownReasons, setSelectedScaleDownReasons] = useQueryParam(
    queryPrefix + NodePoolFilterQueryParams.ScaleDownReasons,
    ArrayParam
  );
  const [selectedLabels, setSelectedLabels] = useQueryParam(
    queryPrefix + NodePoolFilterQueryParams.NodeLabels,
    ArrayParam
  );
  const [selectedWorkloadLabels, setSelectedWorkloadLabels] = useQueryParam(
    queryPrefix + NodePoolFilterQueryParams.WorkloadLabels,
    ArrayParam
  );

  const [selectedWorkloadAnnotations, setSelectedWorkloadAnnotations] = useQueryParam(
    queryPrefix + NodePoolFilterQueryParams.WorkloadAnnotations,
    ArrayParam
  );

  const [selectedWorkloadNames, setSelectedWorkloadNames] = useQueryParam(
    queryPrefix + NodePoolFilterQueryParams.WorkloadNames,
    ArrayParam
  );

  return {
    searchTerm,
    setSearchTerm,
    selectedNodeGroups,
    setSelectedNodeGroups,
    selectedNodePools,
    setSelectedNodePools,
    selectedProvisioners,
    setSelectedProvisioners,
    selectedAvailabilityZones,
    setSelectedAvailabilityZones,
    selectedScaleDownReasons,
    setSelectedScaleDownReasons,
    selectedLabels,
    setSelectedLabels,
    selectedWorkloadLabels,
    setSelectedWorkloadLabels,
    selectedWorkloadAnnotations,
    setSelectedWorkloadAnnotations,
    selectedWorkloadNames,
    setSelectedWorkloadNames,
  };
};

export default useGetRebalanceQueryParams;
