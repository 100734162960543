import { Area, ComposedChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import dayjs from "dayjs";
import {
  GraphDataPoint,
  chartElements,
  ChartElement,
  regularRec,
  StripsSvg,
  TabOptions,
  ChartElementKey,
} from "./nodesCostUtils";
import { Typography } from "@mui/material";
import NodeCostTooltip from "./NodeCostTooltip";

interface Props {
  data: GraphDataPoint[];
  selectedTab: TabOptions;
}

const AXIS_STYLE = {
  fontSize: "10px",
};

const AXIS_LINE = {
  stroke: "black",
};

const AXIS_STROKE_WIDTH = 2;

const NodesCostChart = ({ data, selectedTab }: Props) => {
  let elementsToRender: ChartElement[] = [];

  switch (selectedTab) {
    case TabOptions.Cost:
      elementsToRender = chartElements.filter((element) => {
        return (
          element.dataKey === ChartElementKey.currentCost ||
          element.dataKey === ChartElementKey.recommendedCost ||
          element.dataKey === ChartElementKey.costWasteMask
        );
      });
      break;
    case TabOptions.Nodes:
      elementsToRender = chartElements.filter((element) => {
        return (
          element.dataKey === ChartElementKey.currentNodes ||
          element.dataKey === ChartElementKey.recommendedNodes ||
          element.dataKey === ChartElementKey.nodesWasteMask
        );
      });
      break;
    default:
      elementsToRender = chartElements;
      break;
  }

  const title = selectedTab === TabOptions.Cost ? "Cost" : "Nodes";

  return (
    <div className="w-full h-full relative flex flex-col justify-center items-center py-4 pr-[2%]">
      <Typography variant="body2">{title}</Typography>
      <ResponsiveContainer width="99%" height="99%" className="">
        <ComposedChart data={data}>
          {elementsToRender.map((element) => {
            return (
              <Area
                type="monotone"
                dot={false}
                dataKey={element.dataKey}
                stroke={element.stroke}
                strokeWidth={element.strokeWidth}
                strokeOpacity={element.strokeOpacity}
                fill={element.fill}
                fillOpacity={element.fillOpacity}
              />
            );
          })}
          <Tooltip content={<NodeCostTooltip elementsToRender={elementsToRender} selectedTab={selectedTab} />} />
          <XAxis
            dataKey="timestamp"
            style={AXIS_STYLE}
            // interval={Math.floor((data && data.length / 6) ?? 1)}
            axisLine={AXIS_LINE}
            strokeWidth={AXIS_STROKE_WIDTH}
            tickFormatter={(tick: string) => {
              return dayjs(tick).format("DD/MMM HH:mm");
            }}
          />
          <YAxis
            style={AXIS_STYLE}
            axisLine={AXIS_LINE}
            strokeWidth={AXIS_STROKE_WIDTH}
            domain={[0, (dataMax: number) => Math.ceil(dataMax * 1.2)]}
          />
        </ComposedChart>
      </ResponsiveContainer>
      <div className="w-full flex items-end justify-center gap-2">
        {elementsToRender
          .filter((element) => !element.legendName.includes("waste"))
          .map((element, index) => {
            return (
              <div className="flex items-center gap-2" key={index}>
                {element.legendName === "Waste" ? StripsSvg : regularRec(element.stroke)}
                <Typography
                  variant="caption"
                  style={{
                    color: element.stroke,
                  }}
                >
                  {element.legendName}
                </Typography>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default NodesCostChart;
