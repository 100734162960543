import { useQuery } from "@tanstack/react-query";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { GetNotEvictable, GetNotEvictableResponse } from "../../../api/fetcher";
import { SCALEOPS_COLORS } from "../../../colors";
import { useMainContext } from "../../../MainContext";
import useFreezeTooltip from "../../../pages/Analytics/AnalyticsV2/Graphs/hooks/useFreezeTooltip";
import { getPositiveNumberORZero } from "../../../utils/formatterUtils";
import AccordionSection from "../../AccordionSection";
import { YAxisTickFormatterType } from "../../ComposeChart/utils";
import Tab, { TABS_CONTAINER_CLASS_NAME } from "../../Tab";
import { SelectRowsOnInitialLoading } from "../../WorkloadStatusByNamespace/utils";
import AutomateUevictableWorkloads from "./AutomateUevictableWorkloads";
import PodsWithUnevictableWorkloads from "./PodsWithUnevictableWorkloads";
import TopSection from "./TopSection";
import UnEvictableBarChartContainer from "./UnEvictableBarChart/UnEvictableBarChartContainer";
import UnEvictableChart from "./UnEvictableChart";
import useGetDashboardByNamespace from "./useGetDashboardByNamespace";
import { MainMetrics, TabOptions } from "./utils";

const { queryFn, queryKey } = GetNotEvictable();

const UnEvictableContainer = () => {
  const { currentCluster } = useMainContext();
  const dashboardByNamespace = useGetDashboardByNamespace();
  const { tooltipTrigger, updateActiveTooltips } = useFreezeTooltip({});

  const [selectedTab, setSelectedTab] = useState<TabOptions>(TabOptions.SevenDays);
  const [mainMetrics, setMainMetrics] = useState<MainMetrics>({
    wastedSpend: 0,
    blockedNodes: 0,
    totalOptimizedPods: 0,
    totalUnOptimizedPods: 0,
    totalOptimizedPodsWithUnEvictableWorkloads: 0,
    totalUnOptimizedPodsWithUnEvictableWorkloads: 0,
    numberOfUnevictableWorkloads: 0,
    optimizedPodsWithoutOwner: 0,
    unOptimizedPodsWithoutOwner: 0,
    notSupportingBinPackingPolicies: [],
  });

  const { data, isLoading, isError, error } = useQuery<GetNotEvictableResponse, Error>({
    queryKey: [queryKey],
    queryFn: queryFn,
  });

  useEffect(() => {
    if (data && dashboardByNamespace) {
      const totalNodesCost = getPositiveNumberORZero(dashboardByNamespace?.totalNamespaceSummary?.totalNodesCost);

      let wastedSpend = ((data?.savingsAvailable ?? 0) / totalNodesCost) * 100;
      switch (true) {
        case wastedSpend < 0:
          wastedSpend = 0;
          break;
        case wastedSpend > 100:
          wastedSpend = 100;
          break;
        default:
          break;
      }

      setMainMetrics({
        wastedSpend,
        blockedNodes: getPositiveNumberORZero(data?.blockedNodesCount),
        totalOptimizedPods: getPositiveNumberORZero(data?.unevictablePodsInfo?.overall?.optimized),
        totalUnOptimizedPods: getPositiveNumberORZero(data?.unevictablePodsInfo?.overall?.unoptimized),
        totalOptimizedPodsWithUnEvictableWorkloads: getPositiveNumberORZero(
          data?.unevictablePodsInfo?.withOwner?.optimized
        ),
        totalUnOptimizedPodsWithUnEvictableWorkloads: getPositiveNumberORZero(
          data?.unevictablePodsInfo?.withOwner?.unoptimized
        ),
        numberOfUnevictableWorkloads: getPositiveNumberORZero(data?.unevictableNotHealthyWorkloadsCount),
        optimizedPodsWithoutOwner: getPositiveNumberORZero(data?.unevictablePodsInfo?.withoutOwner?.optimized),
        unOptimizedPodsWithoutOwner: getPositiveNumberORZero(data?.unevictablePodsInfo?.withoutOwner?.unoptimized),
        notSupportingBinPackingPolicies: data?.notSupportingBinPackingPolicies,
      });
    }
  }, [data, dashboardByNamespace]);

  if (isError) {
    console.log("Error fetching not evictable pods", error);
    return null;
  }

  let link = `/?currentClusterURLParam=${
    currentCluster ?? ""
  }&unevictableTypes=notHealthy&unevictableTypes=unevictable&selectRowsOnInitialLoading=${
    SelectRowsOnInitialLoading.Unautomated
  }`;
  if (Number(data?.notSupportingBinPackingPolicies?.length) > 0) {
    link = link + "&isPolicyExclude=1";
    data?.notSupportingBinPackingPolicies?.forEach((policy) => {
      link = link + `&policy=${policy}`;
    });
  }

  return (
    <div className="bg-white rounded-lg p-[1.875rem] flex flex-col justify-center items-center gap-[1.5625rem]">
      <TopSection
        wastedSpend={mainMetrics.wastedSpend}
        isLoading={isLoading}
        blockedNodes={mainMetrics.blockedNodes}
        totalOptimizedPods={mainMetrics.totalOptimizedPods}
        totalUnOptimizedPods={mainMetrics.totalUnOptimizedPods}
      />
      <div className="bg-strongBorder w-full h-[1px]" />
      <div className="flex justify-center items-center w-full h-[317px] gap-12 pt-[21px]">
        <AutomateUevictableWorkloads
          totalOptimizedPodsWithUnEvictableWorkloads={mainMetrics.totalOptimizedPodsWithUnEvictableWorkloads}
          totalUnOptimizedPodsWithUnEvictableWorkloads={mainMetrics.totalUnOptimizedPodsWithUnEvictableWorkloads}
          numberOfUnevictableWorkloads={mainMetrics.numberOfUnevictableWorkloads}
          notSupportingBinPackingPolicies={data?.notSupportingBinPackingPolicies}
          link={link}
        />
        <div className="h-full min-w-[1px] bg-strongBorder" />
        <PodsWithUnevictableWorkloads
          optimizedPodsWithoutOwner={mainMetrics.optimizedPodsWithoutOwner}
          unOptimizedPodsWithoutOwner={mainMetrics.unOptimizedPodsWithoutOwner}
        />
      </div>
      <div className="w-full min-h-[1px] bg-strongBorder mt-[21px]" />
      <AccordionSection title="Unevictable troubleshoot" className="p-0" titleClassName="grow-1">
        <div className="flex flex-col w-full gap-8 mt-[21px]">
          <UnEvictableBarChartContainer />
          <div className="relative">
            <div className={clsx(TABS_CONTAINER_CLASS_NAME, "top-[-1px] w-full")}>
              {Object.entries(TabOptions).map(([key, value]) => {
                return (
                  <Tab
                    key={key}
                    isSelected={selectedTab === value}
                    onClick={() => {
                      setSelectedTab(value);
                    }}
                    name={String(value)}
                    dataTestId={`node-cost-chart-${key}-tab`}
                    hasDefaultGrayBorders
                  />
                );
              })}
            </div>
            <div className="p-4 bg-white w-[100%] border border-border rounded rounded-tl-none mt-[34px] flex flex-col gap-2.5">
              <div className="flex w-full gap-2.5">
                <UnEvictableChart
                  chartType={["waste"]}
                  title="wasted spend over time"
                  daysAgo={selectedTab}
                  elements={[
                    {
                      key: "waste",
                      color: SCALEOPS_COLORS.main.red,
                      name: "waste",
                    },
                  ]}
                  yAxisTickFormatterType={YAxisTickFormatterType.Currency}
                  tooltipTrigger={tooltipTrigger}
                  updateActiveTooltips={updateActiveTooltips}
                />
                <UnEvictableChart
                  chartType={["totalUnevictablePods", "optimizedUnevictablePods"]}
                  title="unevictable pods"
                  daysAgo={selectedTab}
                  elements={[
                    {
                      key: "totalUnevictablePods",
                      color: SCALEOPS_COLORS.primary.purpleBlue,
                      name: "unevictable pods",
                    },
                    {
                      key: "optimizedUnevictablePods",
                      color: SCALEOPS_COLORS.main.green,
                      fill: SCALEOPS_COLORS.main.green,
                      name: "optimized unevictable pods",
                    },
                  ]}
                  yAxisTickFormatterType={YAxisTickFormatterType.Number}
                  tooltipTrigger={tooltipTrigger}
                  updateActiveTooltips={updateActiveTooltips}
                />
              </div>
              <div className="flex w-full gap-2.5">
                <UnEvictableChart
                  chartType={["blockedAllocatableCpu"]}
                  title="blocked allocatable CPU"
                  daysAgo={selectedTab}
                  elements={[
                    {
                      key: "blockedAllocatableCpu",
                      color: SCALEOPS_COLORS.main.orange,
                      name: "blocked allocatable CPU",
                    },
                  ]}
                  yAxisTickFormatterType={YAxisTickFormatterType.Number}
                  tooltipTrigger={tooltipTrigger}
                  updateActiveTooltips={updateActiveTooltips}
                />
                <UnEvictableChart
                  chartType={["blockedAllocatableMemory"]}
                  title="blocked allocatable memory"
                  daysAgo={selectedTab}
                  elements={[
                    {
                      key: "blockedAllocatableMemory",
                      color: SCALEOPS_COLORS.main.orange,
                      name: "blocked allocatable memory",
                    },
                  ]}
                  yAxisTickFormatterType={YAxisTickFormatterType.Memory}
                  tooltipTrigger={tooltipTrigger}
                  updateActiveTooltips={updateActiveTooltips}
                />
              </div>
            </div>
          </div>
        </div>
      </AccordionSection>
    </div>
  );
};

export default UnEvictableContainer;
