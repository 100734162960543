import { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import CircularProgress from "@mui/material/CircularProgress";
import { components } from "../../../api/schema";
import { getWorkloadCost, GetWorkloadCostResponse, GetPolicyTuningConfigParamsResponse } from "../../../api/fetcher";
import CostBarChart from "./CostBarChart";
import { StringParam, useQueryParam } from "use-query-params";
import { SELECTED_CONTAINER_KEY } from "./ContainerFilter";

interface Props {
  selectedWorkload: components["schemas"]["UtilsWorkload"];
  tuningParams: GetPolicyTuningConfigParamsResponse;
}

const CostBarChartContainer = ({ selectedWorkload, tuningParams }: Props) => {
  const [selectedContainer] = useQueryParam(SELECTED_CONTAINER_KEY, StringParam);

  const [currentCost, setCurrentCost] = useState<number>(0);
  const [optimizedCost, setOptimizedCost] = useState<number>(0);

  const cpuHeadroom = tuningParams.cpuPolicyTuningParams?.headroom as number;
  const cpuPercentile = tuningParams.cpuPolicyTuningParams?.percentile as number;
  const cpuWindow = tuningParams.cpuPolicyTuningParams?.historyWindow as string;
  const memoryHeadroom = tuningParams.memoryPolicyTuningParams?.headroom as number;
  const memoryPercentile = tuningParams.memoryPolicyTuningParams?.percentile as number;
  const memoryWindow = tuningParams.memoryPolicyTuningParams?.historyWindow as string;
  const namespace = selectedWorkload.namespace;
  const recommendationName = `${selectedWorkload.type}-${selectedWorkload.workloadName}`.toLowerCase();
  const workloadName = selectedWorkload.workloadName;
  const workloadType = selectedWorkload.type;
  const workloadCost = getWorkloadCost();
  const { data, isLoading, isError } = useQuery<GetWorkloadCostResponse, Error>(
    [
      workloadCost.queryKey,
      cpuHeadroom,
      cpuPercentile,
      cpuWindow,
      memoryHeadroom,
      memoryPercentile,
      memoryWindow,
      namespace,
      recommendationName,
      workloadName,
      workloadType,
      selectedContainer,
    ],
    () =>
      workloadCost.queryFn({
        cpuHeadroom,
        cpuPercentile,
        cpuWindow,
        memoryHeadroom,
        memoryPercentile,
        memoryWindow,
        namespace,
        recommendationName,
        workloadName,
        workloadType,
        container: selectedContainer ? selectedContainer : undefined,
      }),
    {
      keepPreviousData: true,
    }
  );

  useEffect(() => {
    if (data) {
      const roundedCurrentConst = Math.round(Number(data.originCost) || 0);
      const roundedOptimizedCost = Math.round(Number(data.estimatedCost) || 0);
      setCurrentCost(roundedCurrentConst);
      setOptimizedCost(roundedOptimizedCost);
    }
  }, [data]);

  if (isLoading && !data)
    return (
      <div className="flex items-center justify-center w-full h-[80px]">
        <CircularProgress />
      </div>
    );

  if (isError) {
    return <div>Error</div>;
  }

  return <CostBarChart currentCost={currentCost} optimizedCost={optimizedCost} />;
};

export default CostBarChartContainer;
