import { MenuItem, Select, Typography } from "@mui/material";
import { useState } from "react";
import { ArrayParam, useQueryParam } from "use-query-params";
import MainDownloadIcon from "../../Icons/MainDownloadIcon";
import useShouldAddNamespaceFilter from "../../pages/Cost/Tables/Aggregation/hooks/useShouldAddNamespaceFilter";
import CSVWrapper from "../exportCSV/CSVWrapper";
import { WorkloadExportColumns, WorkloadExportColumnsNames } from "../WorkloadStatusByNamespace/overviewUtils";
import {
  AGGREGATION_ANNOTATION_QUERY_KEY,
  AGGREGATION_LABEL_QUERY_KEY,
  AGGREGATION_WORKLOAD,
  ColumnNames,
  Columns,
  getAggregationAsKeyValueObject,
  getAggregationParams,
  getAggregationValuesAsString,
  numericalValue,
  ROW,
} from "./utils";

type CSVExportType = {
  id: string;
  name: string;
  totalCost: number;
  savingsAvailable: number;
  activeSavings: number;
  cpuRequests: number;
  cpuRecommended: number;
  memRequests: number;
  memRecommended: number;
  automationPercentage: number;
  overProvisioned: number;
  underProvisioned: number;
};

interface Props {
  aggregatedRows: ROW[];
  workloads: AGGREGATION_WORKLOAD[] | undefined;
}

export const AggregationExport = ({ aggregatedRows, workloads }: Props) => {
  const shouldAddNamespaceFilter = useShouldAddNamespaceFilter();

  const [aggregatedLabels] = useQueryParam(AGGREGATION_LABEL_QUERY_KEY, ArrayParam);
  const [aggregatedAnnotations] = useQueryParam(AGGREGATION_ANNOTATION_QUERY_KEY, ArrayParam);
  const labelsColumns = aggregatedLabels?.length ? aggregatedLabels.map((label) => label ?? "") : [];
  const annotationsColumns = aggregatedAnnotations?.length
    ? aggregatedAnnotations.map((annotation) => annotation ?? "")
    : [];
  const [open, setOpen] = useState(false);

  return (
    <>
      {aggregatedRows?.length && (
        <div className="mt-[-61px] ml-[10px] z-50 w-fit flex">
          <Typography className="flex gap-1 items-center cursor-pointer hover:text-text-lightBlack borders border border-black rounded-full py-1 px-2">
            <p className="text-[10px]">Export</p>
            <MainDownloadIcon width={12} height={12} />
          </Typography>
          <Select
            MenuProps={{
              anchorOrigin: {
                vertical: "top",
                horizontal: "center",
              },
              transformOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
              onClick: () => {
                setOpen(false);
              },
            }}
            sx={{
              "& .MuiSelect-select": {
                padding: "0px",
              },
            }}
            className={"relative -left-16 w-16 opacity-0"}
            open={open}
            onClick={() => {
              setOpen(!open);
            }}
          >
            <CSVWrapper<CSVExportType>
              columns={[
                Columns.Name,
                Columns.TotalCost,
                Columns.SavingsAvailable,
                Columns.ActiveSavings,
                Columns.CpuRequests,
                Columns.CpuRecommended,
                Columns.MemRequests,
                Columns.MemRecommended,
                Columns.AutomationPercentage,
                Columns.OverProvisioned,
                Columns.UnderProvisioned,
              ]}
              data={
                aggregatedRows.map((row) => {
                  const aggregationParams = getAggregationParams(row.name);
                  const rowName = getAggregationValuesAsString(aggregationParams, shouldAddNamespaceFilter);
                  return {
                    ...row,
                    name: rowName,
                    savingsAvailable: numericalValue(row.savingsAvailable),
                    activeSavings: numericalValue(row.activeSavings),
                  };
                }) as CSVExportType[]
              }
              columnsToRound={[
                Columns.TotalCost,
                Columns.SavingsAvailable,
                Columns.ActiveSavings,
                Columns.CpuRequests,
                Columns.CpuRecommended,
                Columns.MemRequests,
                Columns.MemRecommended,
                Columns.AutomationPercentage,
                Columns.OverProvisioned,
                Columns.UnderProvisioned,
              ]}
              columnsToSum={[
                Columns.TotalCost,
                Columns.SavingsAvailable,
                Columns.ActiveSavings,
                Columns.CpuRequests,
                Columns.CpuRecommended,
                Columns.MemRequests,
                Columns.MemRecommended,
                Columns.OverProvisioned,
                Columns.UnderProvisioned,
              ]}
              customColumnNames={{
                [Columns.Name]: ColumnNames[Columns.Name],
                [Columns.TotalCost]: ColumnNames[Columns.TotalCost],
                [Columns.SavingsAvailable]: ColumnNames[Columns.SavingsAvailable],
                [Columns.ActiveSavings]: ColumnNames[Columns.ActiveSavings],
                [Columns.CpuRequests]: ColumnNames[Columns.CpuRequests],
                [Columns.CpuRecommended]: ColumnNames[Columns.CpuRecommended],
                [Columns.MemRequests]: ColumnNames[Columns.MemRequests],
                [Columns.MemRecommended]: ColumnNames[Columns.MemRecommended],
                [Columns.AutomationPercentage]: ColumnNames[Columns.AutomationPercentage],
                [Columns.OverProvisioned]: ColumnNames[Columns.OverProvisioned],
                [Columns.UnderProvisioned]: ColumnNames[Columns.UnderProvisioned],
              }}
              filename="workloads_aggregation.csv"
            >
              <MenuItem style={{ fontSize: 14 }}>Export aggregation data</MenuItem>
            </CSVWrapper>
            {workloads?.length && (
              <CSVWrapper<AGGREGATION_WORKLOAD & { [key: string]: string }>
                filename={"workloads.csv"}
                columnsToSum={[
                  WorkloadExportColumns.TotalCost,
                  WorkloadExportColumns.ActiveSavings,
                  WorkloadExportColumns.SavingsAvailable,
                ]}
                columnsToRound={[
                  WorkloadExportColumns.TotalCost,
                  WorkloadExportColumns.ActiveSavings,
                  WorkloadExportColumns.SavingsAvailable,
                ]}
                columns={[
                  WorkloadExportColumns.WorkloadName,
                  WorkloadExportColumns.Namespace,
                  ...labelsColumns,
                  ...annotationsColumns,
                  WorkloadExportColumns.Automated,
                  WorkloadExportColumns.TotalCost,
                  WorkloadExportColumns.ActiveSavings,
                  WorkloadExportColumns.SavingsAvailable,
                  WorkloadExportColumns.CPURequests,
                  WorkloadExportColumns.OwnerCPURequests,
                  WorkloadExportColumns.CPURecommended,
                  WorkloadExportColumns.CPUDiff,
                  WorkloadExportColumns.MemoryRequests,
                  WorkloadExportColumns.OwnerMemoryRequests,
                  WorkloadExportColumns.MemoryRecommended,
                  WorkloadExportColumns.MemoryDiff,
                  WorkloadExportColumns.PolicyName,
                  WorkloadExportColumns.Replicas,
                  WorkloadExportColumns.OptimizedReplicas,
                ]}
                data={workloads.map((workload) => {
                  const aggregationParams = getAggregationParams(workload?.group);
                  const filterData = getAggregationAsKeyValueObject(aggregationParams);

                  return {
                    ...workload,
                    ...filterData,
                  } as AGGREGATION_WORKLOAD & { [key: string]: string };
                })}
                customColumnNames={{
                  [WorkloadExportColumns.WorkloadName]: WorkloadExportColumnsNames[WorkloadExportColumns.WorkloadName],
                  [WorkloadExportColumns.Namespace]: WorkloadExportColumnsNames[WorkloadExportColumns.Namespace],
                  [WorkloadExportColumns.Automated]: WorkloadExportColumnsNames[WorkloadExportColumns.Automated],
                  [WorkloadExportColumns.TotalCost]: WorkloadExportColumnsNames[WorkloadExportColumns.TotalCost],
                  [WorkloadExportColumns.ActiveSavings]:
                    WorkloadExportColumnsNames[WorkloadExportColumns.ActiveSavings],
                  [WorkloadExportColumns.SavingsAvailable]:
                    WorkloadExportColumnsNames[WorkloadExportColumns.SavingsAvailable],
                  [WorkloadExportColumns.CPURequests]: WorkloadExportColumnsNames[WorkloadExportColumns.CPURequests],
                  [WorkloadExportColumns.OwnerCPURequests]:
                    WorkloadExportColumnsNames[WorkloadExportColumns.OwnerCPURequests],
                  [WorkloadExportColumns.CPURecommended]:
                    WorkloadExportColumnsNames[WorkloadExportColumns.CPURecommended],
                  [WorkloadExportColumns.CPUDiff]: WorkloadExportColumnsNames[WorkloadExportColumns.CPUDiff],
                  [WorkloadExportColumns.MemoryRequests]:
                    WorkloadExportColumnsNames[WorkloadExportColumns.MemoryRequests],
                  [WorkloadExportColumns.OwnerMemoryRequests]:
                    WorkloadExportColumnsNames[WorkloadExportColumns.OwnerMemoryRequests],
                  [WorkloadExportColumns.MemoryRecommended]:
                    WorkloadExportColumnsNames[WorkloadExportColumns.MemoryRecommended],
                  [WorkloadExportColumns.MemoryDiff]: WorkloadExportColumnsNames[WorkloadExportColumns.MemoryDiff],
                  [WorkloadExportColumns.PolicyName]: WorkloadExportColumnsNames[WorkloadExportColumns.PolicyName],
                  [WorkloadExportColumns.Replicas]: WorkloadExportColumnsNames[WorkloadExportColumns.Replicas],
                  [WorkloadExportColumns.OptimizedReplicas]:
                    WorkloadExportColumnsNames[WorkloadExportColumns.OptimizedReplicas],
                  ...labelsColumns.reduce((acc, label) => {
                    acc[label] = `label_${label}`;
                    return acc;
                  }, {} as Record<string, string>),
                  ...annotationsColumns.reduce((acc, annotation) => {
                    acc[annotation] = `annotation_${annotation}`;
                    return acc;
                  }, {} as Record<string, string>),
                }}
              >
                <MenuItem style={{ fontSize: 14 }}>Export workloads data</MenuItem>
              </CSVWrapper>
            )}
          </Select>
        </div>
      )}
    </>
  );
};
