import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { GetDashboardByNamespaceResponse, RestoreClusterPolicy, getDashboardByNamespace } from "../../api/fetcher";
import { useMainContext } from "../../MainContext";
import { useWorkloadsContext } from "../../WorkloadsContext";

const dashboardByNamespace = getDashboardByNamespace();
const restorePolicy = RestoreClusterPolicy();

const useRestoreClusterPolicy = () => {
  const { currentCluster } = useMainContext();
  const clusterName = currentCluster || "current";
  const queryClient = useQueryClient();
  const { updateOverriddenWorkloadsByUpdateFunction, deleteOverriddenWorkloadsProperties } = useWorkloadsContext();
  const workloadsQueryKey = [dashboardByNamespace.queryKey, "get-all-workloads-for-restoring-policy"];

  const { data } = useQuery<GetDashboardByNamespaceResponse, Error>({
    queryKey: workloadsQueryKey,
    queryFn: () => dashboardByNamespace.queryFn({}),
  });
  const rowsIds = data?.workloads?.map((wl) => wl.id) || [];

  return useMutation(() => restorePolicy.queryFn(), {
    onMutate: () => {
      updateOverriddenWorkloadsByUpdateFunction({
        ids: rowsIds,
        updateFunction: (workload) => {
          const smartPolicyName = data?.workloads?.find((wl) => wl.id === workload.id)?.smartPolicyName;
          return { ...workload, policyName: smartPolicyName || "production" };
        },
      });
    },
    onError: (error: Error) => {
      deleteOverriddenWorkloadsProperties({
        ids: rowsIds,
        propertyNames: ["policyName"],
      });
      updateOverriddenWorkloadsByUpdateFunction({
        ids: rowsIds,
        updateFunction: (workload) => {
          const policyName = data?.workloads?.find((wl) => wl.id === workload.id)?.policyName;
          return { ...workload, policyName };
        },
      });
      console.log(`Error: ${error.message}`);
      toast.error(`Failed to restore suggested policy on cluster: ${clusterName}`);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(workloadsQueryKey).catch((error) => console.log(error));
      toast.success(`Successfully restore suggested policy on cluster: ${clusterName}`);
    },
  });
};

export default useRestoreClusterPolicy;
