import { Typography } from "@mui/material";
import RecommendationIcon from "../../../Icons/RecommendationIcon";
import SelectViewPeriod from "./SelectViewPeriod";

interface Props {
  selectedViewPeriod: string;
  setSelectedViewPeriod: (option: string) => void;
}

const TopSection = ({ selectedViewPeriod, setSelectedViewPeriod }: Props) => {
  return (
    <div className="border border-border rounded-lg w-full p-4 flex">
      <div className="flex justify-start items-center gap-4 grow">
        <RecommendationIcon width={30} height={30} />
        <Typography variant="body2" className="grow">
          <b>Node overview</b>
          <p className="max-w-[100%]">Explore the the nodes resources over time.</p>
        </Typography>
      </div>
      <div className="h-16 w-[1px] bg-border" />
      <div className="pl-4">
        <SelectViewPeriod selectedViewPeriod={selectedViewPeriod} setSelectedViewPeriod={setSelectedViewPeriod} />
      </div>
    </div>
  );
};

export default TopSection;
