import dayjs, { Dayjs } from "dayjs";
import { DEFAULT_DATE_TIME_FORMAT } from "../utils/formatterUtils";

interface Props {
  timestamp: string | number | Dayjs;
  timeFormat?: string;
}

const ChartTooltipTime = ({ timestamp, timeFormat = DEFAULT_DATE_TIME_FORMAT }: Props) => {
  if (typeof timestamp === "string") {
    timestamp = timestamp.replaceAll(/\//g, "-");
  }

  return (
    <p style={{ fontWeight: 600 }} className="text-[10px]">
      {dayjs(timestamp).format(timeFormat)}
    </p>
  );
};

export default ChartTooltipTime;
