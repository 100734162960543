import { useEffect, useState } from "react";
import { Menu } from "@mui/material";
import dayjs from "dayjs";
import DatePicker from "../DatePicker";
import { DEFAULT_DATE_TIME_FORMAT } from "../../utils/formatterUtils";
import { DateType } from "../../pages/Analytics/AnalyticsV2/utils";

export const CustomDateAnchorClassName = "CustomDateAnchor";
const renderDateValue = (date: number) => dayjs(date).format(DEFAULT_DATE_TIME_FORMAT);

interface Props {
  date: DateType;
  setDate: (data: DateType) => void;
  isMenuOpen: boolean;
  setIsMenuOpen: (data: boolean) => void;
  minDateRangeInDays?: number;
}

const getMinDateInMs = (minDateRangeInDays: number | undefined) => {
  if (!minDateRangeInDays) return undefined;
  return new Date(Date.now() - minDateRangeInDays * 24 * 60 * 60 * 1000).toISOString().split("T")[0];
};

const CustomDatesMenu = ({ date, setDate, isMenuOpen, setIsMenuOpen, minDateRangeInDays }: Props) => {
  const [minDateInMs, setMinDateInMs] = useState<string | undefined>(getMinDateInMs(minDateRangeInDays));

  useEffect(() => {
    setMinDateInMs(getMinDateInMs(minDateRangeInDays));
  }, [minDateRangeInDays]);

  return (
    <Menu
      onClose={() => {
        setIsMenuOpen(false);
      }}
      open={isMenuOpen}
      PaperProps={{
        style: {
          maxHeight: 300,
          marginTop: 18,
        },
      }}
      anchorEl={document.querySelector(".CustomDateAnchor")}
    >
      <div className="flex gap-6 px-8 py-4">
        <DatePicker
          date={date.from}
          setDate={(from) => {
            setDate({ from: from * 1000, to: date.to, range: "" });
          }}
          label="From"
          maxDate={date.to}
          minDate={minDateInMs ? dayjs(minDateInMs).unix() : undefined}
          renderDateValue={renderDateValue}
        />
        <DatePicker
          date={date.to}
          setDate={(to) => {
            setDate({ from: date.from, to: to * 1000, range: "" });
          }}
          label="To"
          minDate={date.from || (minDateInMs ? dayjs(minDateInMs).unix() : undefined)}
          renderDateValue={renderDateValue}
        />
      </div>
    </Menu>
  );
};

export default CustomDatesMenu;
