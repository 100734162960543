import { Checkbox, ListItemText, MenuItem } from "@mui/material";
import CustomColumnsFilterButton from "../../../components/CustomColumnsFilterButton";
import MultiSelect from "../../../components/MultiSelect";
import { ColumnNames } from "./utils";

interface Props {
  selectedColumns: (string | undefined)[];
  setSelectedColumns: (columns: (string | undefined)[]) => void;
  options: string[];
  customNames?: Record<string, string>;
}

const ColumnsFilter = ({ selectedColumns, setSelectedColumns, options, customNames }: Props) => (
  <div className="mb-[-20px] w-full flex justify-end">
    <MultiSelect
      selected={selectedColumns}
      setSelected={setSelectedColumns}
      options={options}
      className="w-[85px]"
      customIcon={<CustomColumnsFilterButton isFiltered={selectedColumns.length > 0} />}
      hasSelectAllAndClearSelection={false}
      optionRenderFunction={(option: string, index: number, selected: (string | undefined)[], dataTestId?: string) => {
        const displayName = customNames?.[option] ?? ColumnNames[option as keyof typeof ColumnNames];

        return (
          <MenuItem
            value={option}
            key={`${index}-multi-select-option`}
            data-testid={dataTestId ? `${dataTestId}-option-${index}` : undefined}
            className="min-w-fit"
          >
            <div className="min-w-[235px] flex items-center">
              <Checkbox checked={selected.indexOf(option) > -1} />
              <ListItemText primary={displayName} />
            </div>
          </MenuItem>
        );
      }}
    />
  </div>
);

export default ColumnsFilter;
