import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { StringParam, useQueryParam } from "use-query-params";
import { GetCostReportWorkloads, GetCostReportWorkloadsResponse } from "../../../../api/fetcher";
import ExportCSV from "../../../../components/exportCSV/ExportCSV";
import { Field } from "../utils";
import useWorkloadsFilters from "./hooks/useWorkloadsFilters";
import { WORKLOAD_ROW } from "./utils";

const { queryKey, queryFn } = GetCostReportWorkloads();

type CSVExportType = {
  id: string;
  workloadName: string;
  clusterName: string;
  totalCost: number;
  savingsAvailable: number;
  spot: number;
  onDemand: number;
  ownerCpuRequest: number;
  ownerMemoryRequest: number;
  replicas: number;
};

const ExportWorkloadsCSV = () => {
  const filters = useWorkloadsFilters();

  const [rows, setRows] = useState<WORKLOAD_ROW[]>([]);

  const [searchTerm] = useQueryParam("searchTerms", StringParam);

  const { data, isLoading, isError } = useQuery<GetCostReportWorkloadsResponse, Error>({
    queryKey: [queryKey, filters],
    queryFn: () =>
      queryFn({
        ...filters,
        multiCluster: true,
      }),
  });

  useEffect(() => {
    let sortedData = data
      ? data?.aggregatedWorkloads?.map((wl) => {
          const totalWorkloads = wl.spot + wl.onDemand;

          // Spot %
          let spotPercentage = Math.round((wl.spot / totalWorkloads) * 100);
          if (isNaN(spotPercentage) || spotPercentage < 0 || spotPercentage > 100) spotPercentage = 0;

          // On-demand %
          let onDemandPercentage = Math.round((wl.onDemand / totalWorkloads) * 100);
          if (isNaN(onDemandPercentage) || onDemandPercentage < 0 || onDemandPercentage > 100) onDemandPercentage = 0;

          return {
            ...wl,
            displayWorkloadName: `${wl.namespace}/${wl.workloadName}`,
            spot: spotPercentage,
            onDemand: onDemandPercentage,
          };
        })
      : [];

    // filter by searchTerm
    if (searchTerm) {
      sortedData = sortedData?.filter((wl) => {
        return wl.displayWorkloadName.includes(searchTerm);
      });
    }

    setRows(sortedData);
  }, [data, searchTerm]);

  if (isLoading || isError) {
    return null;
  }

  return (
    <div className="w-fit">
      <ExportCSV<CSVExportType>
        filename="workloads_cost_report.csv"
        columns={[
          Field.workloadName,
          Field.clusterName,
          Field.totalCost,
          Field.savingsAvailable,
          Field.spot,
          Field.onDemand,
          Field.cpu,
          Field.memory,
          Field.replicas,
        ]}
        data={
          rows.map((row) => ({
            id: row.id,
            workloadName: row.workloadName,
            clusterName: row.clusterName,
            totalCost: row.totalCost,
            savingsAvailable: row.savingsAvailable,
            spot: row.spot,
            onDemand: row.onDemand,
            ownerCpuRequest: row.ownerCpuRequest,
            ownerMemoryRequest: row.ownerMemoryRequest,
            replicas: row.replicas,
          })) as CSVExportType[]
        }
        columnsToSum={[Field.totalCost, Field.savingsAvailable, Field.spot, Field.cpu, Field.memory, Field.replicas]}
        columnsToRound={[
          Field.totalCost,
          Field.savingsAvailable,
          Field.spot,
          Field.onDemand,
          Field.cpu,
          Field.memory,
          Field.replicas,
        ]}
        columnsToAverage={[Field.spot, Field.onDemand]}
        customColumnNames={{
          [Field.workloadName]: "Workload",
          [Field.clusterName]: "Cluster",
          [Field.totalCost]: "Total Cost",
          [Field.savingsAvailable]: "Savings Available",
          [Field.spot]: "Spot %",
          [Field.onDemand]: "On-demand %",
          [Field.cpu]: "CPU request",
          [Field.memory]: "Memory request",
          [Field.replicas]: "Replicas",
        }}
      />
    </div>
  );
};

export default ExportWorkloadsCSV;
