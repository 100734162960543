import { StringParam, useQueryParam } from "use-query-params";
import { SCALEOPS_COLORS } from "../../colors";
import Input from "../Input";

interface Props {
  queryParamsPrefix?: string;
}
const SearchNodeFilter = ({ queryParamsPrefix = "" }: Props) => {
  const [searchTerm, setSearchTerm] = useQueryParam(queryParamsPrefix + "setSearchTerm", StringParam);

  return (
    <Input
      placeholder="search..."
      onChange={(e) => {
        setSearchTerm(e.target.value);
      }}
      value={searchTerm ?? ""}
      borderColor={SCALEOPS_COLORS.black}
      height="32px"
    />
  );
};

export default SearchNodeFilter;
