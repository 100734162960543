import FormSwitch from "../../../../components/formComponents/FormSwitch";
import FormTitleAndDescription from "../../../../components/formComponents/FormTitleAndDescription";
import { shouldDisableDefaultPolicyInputs } from "../../utils";

interface Props {
  isCustomizedPolicy?: boolean;
}

const EnableHPA = ({ isCustomizedPolicy }: Props) => {
  return (
    <div className="flex gap-3 pb-10 py-3 border-b border-strongBorder">
      <FormTitleAndDescription
        title="Enable HPA optimization"
        description={
          <>
            Automated HPA workloads are optimized with
            <b> recommended minimum replicas</b> based on HPA metrics history.
            <br />
            For predictable workloads, ScaleOps recommends lower minimum replicas and
            <b> scales ahead of peak periods</b> for optimal performance.
          </>
        }
      />
      <FormSwitch
        name="hasHPAenabled"
        disabled={!isCustomizedPolicy && shouldDisableDefaultPolicyInputs}
        disableChange={!isCustomizedPolicy}
      />
    </div>
  );
};
export default EnableHPA;
