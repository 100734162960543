import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { GetNetworkReportWorkloads, GetNetworkReportWorkloadsResponse } from "../../../../../api/fetcher";
import ExportCSVOptionButton from "../../../../../components/ExportCSVOptionButton";
import ExportCSV from "../../../../../components/exportCSV/ExportCSV";
import useWorkloadsFilters from "../../Workloads/hooks/useWorkloadsFilters";

const BUTTON_TITLE = "Network workloads report";

const { queryKey, queryFn } = GetNetworkReportWorkloads();

type ROW = {
  id: string;
  workload: string;
  clusterName: string;
  // totalCost
  totalCostEgress: number;
  totalCostIngress: number;
  totalCostTotal: number;
  // crossAZCost
  crossAZCostEgress: number;
  crossAZCostIngress: number;
  crossAZCostTotal: number;
  // intraAZCost
  intraAZCostEgress: number;
  intraAZCostIngress: number;
  intraAZCostTotal: number;
  // totalDataTransfer
  totalDataTransferEgress: number;
  totalDataTransferIngress: number;
  totalDataTransferTotal: number;
  // intraAZDataTransfer
  intraAZDataTransferEgress: number;
  intraAZDataTransferIngress: number;
  intraAZDataTransferTotal: number;
  // crossAZDataTransfer
  crossAZDataTransferEgress: number;
  crossAZDataTransferIngress: number;
  crossAZDataTransferTotal: number;
};

enum Field {
  id = "id",
  workload = "workload",
  clusterName = "clusterName",
  totalCostEgress = "totalCostEgress",
  totalCostIngress = "totalCostIngress",
  totalCostTotal = "totalCostTotal",
  crossAZCostEgress = "crossAZCostEgress",
  crossAZCostIngress = "crossAZCostIngress",
  crossAZCostTotal = "crossAZCostTotal",
  intraAZCostEgress = "intraAZCostEgress",
  intraAZCostIngress = "intraAZCostIngress",
  intraAZCostTotal = "intraAZCostTotal",
  totalDataTransferEgress = "totalDataTransferEgress",
  totalDataTransferIngress = "totalDataTransferIngress",
  totalDataTransferTotal = "totalDataTransferTotal",
  intraAZDataTransferEgress = "intraAZDataTransferEgress",
  intraAZDataTransferIngress = "intraAZDataTransferIngress",
  intraAZDataTransferTotal = "intraAZDataTransferTotal",
  crossAZDataTransferEgress = "crossAZDataTransferEgress",
  crossAZDataTransferIngress = "crossAZDataTransferIngress",
  crossAZDataTransferTotal = "crossAZDataTransferTotal",
}

const ExportNetworkWorkloadsCSV = () => {
  const workloadsFilters = useWorkloadsFilters();

  const [rows, setRows] = useState<ROW[]>([]);

  const { data, isError, isLoading } = useQuery<GetNetworkReportWorkloadsResponse, Error>({
    queryKey: [queryKey, workloadsFilters],
    queryFn: () =>
      queryFn({
        ...workloadsFilters,
        multiCluster: true,
      }),
  });

  useEffect(() => {
    if (data?.aggregatedWorkloads) {
      const rowToSet = data.aggregatedWorkloads.map((wl) => {
        return {
          id: wl.id,
          workload: `${wl.namespace}/${wl.workloadName}`,
          clusterName: wl.clusterName,
          totalCostEgress: wl.totalCost.egress,
          totalCostIngress: wl.totalCost.ingress,
          totalCostTotal: wl.totalCost.total,
          crossAZCostEgress: wl.crossAZCost.egress,
          crossAZCostIngress: wl.crossAZCost.ingress,
          crossAZCostTotal: wl.crossAZCost.total,
          intraAZCostEgress: wl.intraAZCost.egress,
          intraAZCostIngress: wl.intraAZCost.ingress,
          intraAZCostTotal: wl.intraAZCost.total,
          totalDataTransferEgress: wl.totalDataTransfer.egress,
          totalDataTransferIngress: wl.totalDataTransfer.ingress,
          totalDataTransferTotal: wl.totalDataTransfer.total,
          intraAZDataTransferEgress: wl.intraAZDataTransfer.egress,
          intraAZDataTransferIngress: wl.intraAZDataTransfer.ingress,
          intraAZDataTransferTotal: wl.intraAZDataTransfer.total,
          crossAZDataTransferEgress: wl.crossAZDataTransfer.egress,
          crossAZDataTransferIngress: wl.crossAZDataTransfer.ingress,
          crossAZDataTransferTotal: wl.crossAZDataTransfer.total,
        };
      });

      setRows(rowToSet);
    }
  }, [data]);

  if (isLoading || isError) {
    return <ExportCSVOptionButton title={BUTTON_TITLE} disabled={true} />;
  }

  return (
    <div className="w-fit">
      <ExportCSV<ROW>
        customButtonElement={<ExportCSVOptionButton title={BUTTON_TITLE} />}
        filename="aggregated_cost_report.csv"
        columns={[
          Field.id,
          Field.workload,
          Field.clusterName,
          Field.totalCostEgress,
          Field.totalCostIngress,
          Field.totalCostTotal,
          Field.crossAZCostEgress,
          Field.crossAZCostIngress,
          Field.crossAZCostTotal,
          Field.intraAZCostEgress,
          Field.intraAZCostIngress,
          Field.intraAZCostTotal,
          Field.totalDataTransferEgress,
          Field.totalDataTransferIngress,
          Field.totalDataTransferTotal,
        ]}
        data={rows}
        columnsToRound={[
          Field.totalCostEgress,
          Field.totalCostIngress,
          Field.totalCostTotal,
          Field.crossAZCostEgress,
          Field.crossAZCostIngress,
          Field.crossAZCostTotal,
          Field.intraAZCostEgress,
          Field.intraAZCostIngress,
          Field.intraAZCostTotal,
          Field.totalDataTransferEgress,
          Field.totalDataTransferIngress,
          Field.totalDataTransferTotal,
        ]}
        columnsToSum={[
          Field.totalCostEgress,
          Field.totalCostIngress,
          Field.totalCostTotal,
          Field.crossAZCostEgress,
          Field.crossAZCostIngress,
          Field.crossAZCostTotal,
          Field.intraAZCostEgress,
          Field.intraAZCostIngress,
          Field.intraAZCostTotal,
          Field.totalDataTransferEgress,
          Field.totalDataTransferIngress,
          Field.totalDataTransferTotal,
        ]}
        customColumnNames={{
          [Field.id]: "id",
          [Field.workload]: "workload",
          [Field.clusterName]: "cluster",
          [Field.totalCostEgress]: "total cost (egress)",
          [Field.totalCostIngress]: "total cost (ingress)",
          [Field.totalCostTotal]: "total cost",
          [Field.crossAZCostEgress]: "cross AZ cost (egress)",
          [Field.crossAZCostIngress]: "cross AZ cost (ingress)",
          [Field.crossAZCostTotal]: "cross AZ cost",
          [Field.intraAZCostEgress]: "intra AZ cost (egress)",
          [Field.intraAZCostIngress]: "intra AZ cost (ingress)",
          [Field.intraAZCostTotal]: "intra AZ cost",
          [Field.totalDataTransferEgress]: "total data transfer (egress)",
          [Field.totalDataTransferIngress]: "total data transfer (ingress)",
          [Field.totalDataTransferTotal]: "total data transfer",
          [Field.intraAZDataTransferEgress]: "intra AZ data transfer (egress)",
          [Field.intraAZDataTransferIngress]: "intra AZ data transfer (ingress)",
          [Field.intraAZDataTransferTotal]: "intra AZ data transfer",
          [Field.crossAZDataTransferEgress]: "cross AZ data transfer (egress)",
          [Field.crossAZDataTransferIngress]: "cross AZ data transfer (ingress)",
          [Field.crossAZDataTransferTotal]: "cross AZ data transfer",
        }}
      />
    </div>
  );
};

export default ExportNetworkWorkloadsCSV;
