import { components } from "../api/schema";
import { getCustomerPrefix, getToken } from "../utils/configUtils";

export const docsBaseUrl = "https://scaledocs:HandsFree22@docs.scaleops.com/docs/";

interface HelmSetupProps {
  conf: components["schemas"]["ConfGetConfResponse"];
  showToken: boolean;
}

export const helmSetup = ({ conf, showToken }: HelmSetupProps) => {
  const displayScaleOpsToken = showToken ? getToken(conf) : getToken(conf).replace(/./g, "*");

  const base = `helm upgrade --install --create-namespace -n scaleops-system \\
  --set scaleopsToken=${displayScaleOpsToken ?? "<SCALEOPS_TOKEN>"} \\
  --set clusterName=${getCustomerPrefix(conf)}-$(kubectl config current-context) \\
  --set parentURL=${encodeURI(window.location.protocol + "//" + window.location.host)} \\
`;

  return base + "scaleops scaleops/scaleops";
};
