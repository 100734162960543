import clsx from "clsx";

const MAIN_CLASS_NAME = "bg-white text-black hover:bg-guideline-lightGray";
interface Props {
  label: string;
  onClick: () => void;
  marginTop?: string;
  selected: boolean;
  disabled?: boolean;
}

const CustomFilterChip = ({ label, onClick, marginTop, selected, disabled }: Props) => (
  <div
    className={clsx("text-[13px] border border-black rounded-full px-[12px] py-[5px] select-none whitespace-nowrap", {
      [`${MAIN_CLASS_NAME} opacity-50 cursor-not-allowed`]: disabled,
      "bg-text-lightBlack text-white cursor-pointer": selected && !disabled,
      [`${MAIN_CLASS_NAME} cursor-pointer`]: !selected && !disabled,
    })}
    onClick={() => {
      if (!disabled) {
        onClick();
      }
    }}
    style={{
      marginTop,
    }}
  >
    {label}
  </div>
);

export default CustomFilterChip;
