import dayjs from "dayjs";
import dayjsPluginUTC from "dayjs/plugin/utc";
import { DEFAULT_DATE_TIME_FORMAT, roundedOrTwoDecimal } from "../../../../utils/formatterUtils";
import { EVENT_COLORS, EventName, EventPoint, EventType } from "./utils";

dayjs.extend(dayjsPluginUTC);

interface CustomTooltipProps {
  active?: boolean;
  payload?: {
    payload: EventPoint;
  }[];
  selectedEvents: EventType[];
  tooltipPayloadContext: EventPoint | undefined;
  displayNameStringTransformer: (key: string) => string;
  timeFormat?: string;
}

const Event = ({ name, value, color }: { name: string; value: React.ReactNode | null | undefined; color: string }) => {
  const displayValue = roundedOrTwoDecimal(value);

  return (
    <div className="rounded-lg py-[0.5px] text-[10px] flex items-center gap-1" key={name}>
      <div
        style={{
          backgroundColor: color,
        }}
        className="h-1 w-8 rounded-full"
      />
      <div className="w-full flex">
        <p className="grow">
          {name}: {displayValue}
        </p>
      </div>
    </div>
  );
};

const CustomTooltip = ({
  active,
  payload,
  selectedEvents,
  tooltipPayloadContext,
  timeFormat = DEFAULT_DATE_TIME_FORMAT,
  displayNameStringTransformer,
}: CustomTooltipProps) => {
  if (active && payload && payload.length) {
    const payloadContent = tooltipPayloadContext ?? payload[0].payload;
    const evictionValue = payloadContent[EventType.EVICTION];
    const ommEventValue = payloadContent[EventType.OOM_EVENT];
    const oomKubeletValue = payloadContent[EventType.OOM_KUBELET];
    const oomLimitValue = payloadContent[EventType.OOM_LIMIT];
    const oomNodeValue = payloadContent[EventType.OOM_NODE];
    const highUtilizationNodesValue = payloadContent[EventType.HIGH_UTILIZATION_NODES];
    const cpuThrottlingValue = payloadContent[EventType.CPU_THROTTLING];
    const autoHealingValue = payloadContent[EventType.AUTO_HEALING];
    const cpuFastReactionValue = payloadContent[EventType.CPU_FAST_REACTION];
    const memoryFastReactionValue = payloadContent[EventType.MEMORY_FAST_REACTION];
    const fastReactionValue = payloadContent[EventType.FAST_REACTION];

    const events = [
      {
        type: EventType.OOM_EVENT,
        value: ommEventValue,
        color: EVENT_COLORS.oomEvent,
      },
      {
        type: EventType.CPU_FAST_REACTION,
        value: cpuFastReactionValue,
        color: EVENT_COLORS.cpuFastReaction,
      },
      {
        type: EventType.MEMORY_FAST_REACTION,
        value: memoryFastReactionValue,
        color: EVENT_COLORS.memoryFastReaction,
      },
      {
        type: EventType.OOM_KUBELET,
        value: oomKubeletValue,
        color: EVENT_COLORS.oomKubelet,
      },
      {
        type: EventType.OOM_LIMIT,
        value: oomLimitValue,
        color: EVENT_COLORS.oomLimit,
      },
      {
        type: EventType.OOM_NODE,
        value: oomNodeValue,
        color: EVENT_COLORS.oomNode,
      },
      {
        type: EventType.HIGH_UTILIZATION_NODES,
        value: highUtilizationNodesValue,
        color: EVENT_COLORS.highUtilizationNodes,
      },
      {
        type: EventType.CPU_THROTTLING,
        value: cpuThrottlingValue,
        color: EVENT_COLORS.cpuThrottling,
      },
      {
        type: EventType.EVICTION,
        value: evictionValue,
        color: EVENT_COLORS.eviction,
      },
      {
        type: EventType.AUTO_HEALING,
        value: autoHealingValue,
        color: EVENT_COLORS.autoHealing,
      },
      {
        type: EventType.HIGH_CPU_UTILIZATION,
        value: payloadContent[EventType.HIGH_CPU_UTILIZATION],
        color: EVENT_COLORS.highCpuUtilization,
      },
      {
        type: EventType.HIGH_MEMORY_UTILIZATION,
        value: payloadContent[EventType.HIGH_MEMORY_UTILIZATION],
        color: EVENT_COLORS.highMemoryUtilization,
      },
      {
        type: EventType.NODE_MEMORY_PRESSURE_EVENT,
        value: payloadContent[EventType.NODE_MEMORY_PRESSURE_EVENT],
        color: EVENT_COLORS.nodeMemoryPressureEvent,
      },
      {
        type: EventType.AUTO,
        value: payloadContent[EventType.AUTO],
        color: EVENT_COLORS.auto,
      },
      {
        type: EventType.IMAGE_CHANGE,
        value: payloadContent[EventType.IMAGE_CHANGE],
        color: EVENT_COLORS.imageChange,
      },
      {
        type: EventType.FAST_REACTION,
        value: fastReactionValue,
        color: EVENT_COLORS.fastReaction,
      },
    ];

    return (
      <div className="bg-[rgba(255,255,255,0.75)] text-black py-4 px-8 rounded-lg border border-border flex flex-col">
        <div className="font-bold text-[10px]">
          <p>{dayjs.unix(Number(payload[0].payload.timestamp)).format(timeFormat)}</p>
        </div>
        <div className="flex flex-col">
          {events.map((event) => {
            const isSelected = selectedEvents.includes(event.type);
            const eventCountMoreThanZero = event.value && event.value > 0;
            const isFastReaction = event.type === EventType.FAST_REACTION;
            if (isFastReaction && isSelected && eventCountMoreThanZero)
              return (
                <>
                  <Event
                    name={displayNameStringTransformer(EventName[EventType.CPU_FAST_REACTION])}
                    value={cpuFastReactionValue}
                    color={EVENT_COLORS.cpuFastReaction}
                  />
                  <Event
                    name={displayNameStringTransformer(EventName[EventType.MEMORY_FAST_REACTION])}
                    value={memoryFastReactionValue}
                    color={EVENT_COLORS.memoryFastReaction}
                  />
                </>
              );
            if (!isSelected || !eventCountMoreThanZero) return null;

            return (
              <Event
                name={displayNameStringTransformer(EventName[event.type])}
                value={event.value}
                color={event.color}
              />
            );
          })}
        </div>
      </div>
    );
  }

  return null;
};

export default CustomTooltip;
