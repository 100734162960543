import { Typography } from "@mui/material";
import clsx from "clsx";

type VARIANTS =
  | "regular"
  | "mediumPurple"
  | "mediumDarkPurple"
  | "mediumDarkPurpleWidthShadow"
  | "mediumWhite"
  | "mediumGray"
  | "mediumGreen"
  | "small"
  | "smallGreen"
  | "extraSmall";

export enum BUTTON_VARIANTS {
  regular = "regular",
  mediumPurple = "mediumPurple",
  mediumDarkPurple = "mediumDarkPurple",
  mediumDarkPurpleWidthShadow = "mediumDarkPurpleWidthShadow",
  mediumWhite = "mediumWhite",
  mediumGray = "mediumGray",
  mediumGreen = "mediumGreen",
  small = "small",
  smallGreen = "smallGreen",
  extraSmall = "extraSmall",
}

export enum FontSize {
  Medium = 16,
  Small = 12,
}
interface Props {
  onClick?: (event: React.MouseEvent<HTMLElement>) => void | (() => void) | (() => boolean | undefined | null);
  className?: string;
  type?: "button" | "submit" | "reset";
  disabled?: boolean;
  label: React.ReactNode;
  fontSize?: number;
  fontWeight?: string | number;
  variant?: VARIANTS;
  dataTestId?: string;
}

const CLASS_NAMES = {
  regular: {
    main: "bg-transparent font-semibold py-2 px-4 border rounded",
    notDisabled: "hover:bg-guideline-lessDarkPurple text-black hover:text-white hover:border-transparent border-black",
    disabled: "text-text-disable",
    fontSize: FontSize.Medium,
    fontWeight: "regular",
  },
  mediumGreen: {
    main: "bg-guideline-darkGreen text-white font-semibold py-2 px-4 rounded-[10px] h-[42px]",
    notDisabled: "hover:bg-guideline-darkerGreen text-black hover:text-white border-black",
    disabled: "text-text-disable opacity-50 cursor-not-allowed",
    fontSize: FontSize.Medium,
    fontWeight: "regular",
  },
  mediumPurple: {
    main: "bg-guideline-lessDarkPurple text-white font-semibold py-2 px-4 rounded",
    notDisabled: "hover:bg-guideline-darkPurple text-black hover:text-white border-black",
    disabled: "text-text-disable opacity-50 cursor-not-allowed",
    fontSize: FontSize.Medium,
    fontWeight: "regular",
  },
  mediumDarkPurple: {
    main: "bg-guideline-darkPurple text-white font-semibold py-2 px-4 rounded h-[42px] border border-black",
    notDisabled: "hover:bg-guideline-darkPurpleHover text-black hover:text-white",
    disabled: "text-text-disable opacity-50 cursor-not-allowed",
    fontSize: FontSize.Medium,
    fontWeight: 500,
  },
  mediumDarkPurpleWidthShadow: {
    main: "bg-guideline-darkPurple text-white font-semibold py-2 px-4 rounded-[10px] h-[42px] scaleopsShadow",
    notDisabled: "hover:bg-guideline-lessDarkBlue text-black hover:text-white border-black",
    disabled: "text-text-disable opacity-50 cursor-not-allowed",
    fontSize: FontSize.Medium,
    fontWeight: "regular",
  },
  mediumWhite: {
    main: "bg-white text-black hover:bg-guideline-lessDarkPurple hover:text-white h-[42px] py-2 px-4 border rounded",
    notDisabled: "hover:bg-guideline-lessDarkBlue text-black hover:text-white border-black border-black",
    disabled: "text-text-disable opacity-50 cursor-not-allowed border-border",
    fontSize: FontSize.Medium,
    fontWeight: "regular",
  },
  mediumGray: {
    main: "bg-background-gray text-white font-semibold py-2 px-4 rounded-[10px] h-[42px]",
    notDisabled: "hover:bg-background-scrollbar text-black hover:text-white border-black",
    disabled: "text-text-disable opacity-50 cursor-not-allowed",
    fontSize: FontSize.Medium,
    fontWeight: "regular",
  },
  small: {
    main: "w-fit px-4 py-2 border border-strongBorder rounded-lg",
    notDisabled: "hover:bg-background-chipHover cursor-pointer",
    disabled: "text-text-disable",
    fontSize: FontSize.Small,
    fontWeight: "regular",
  },
  smallGreen: {
    main: "w-fit px-4 py-2 border rounded-lg",
    notDisabled:
      "bg-guideline-darkGreen text-white border-guideline-darkGreen cursor-pointer hover:bg-guideline-darkerGreen",
    disabled: "border-strongBorder text-text-disable",
    fontSize: FontSize.Small,
    fontWeight: "regular",
  },
  extraSmall: {
    main: "w-fit px-2 <py-1></py-1> border border-strongBorder rounded-lg",
    notDisabled: "hover:bg-background-chipHover cursor-pointer",
    disabled: "text-text-disable",
    fontSize: FontSize.Small,
    fontWeight: "regular",
  },
};

const Button = ({
  onClick,
  className,
  disabled,
  label,
  fontSize,
  fontWeight,
  type,
  variant = "regular",
  dataTestId,
}: Props) => {
  return (
    <button
      disabled={disabled}
      data-testid={dataTestId}
      onClick={onClick}
      type={type}
      className={clsx(className, "flex flex-col justify-center items-center", CLASS_NAMES[variant].main, {
        [CLASS_NAMES[variant].notDisabled]: !disabled,
        [CLASS_NAMES[variant].disabled]: disabled,
      })}
    >
      <Typography
        fontSize={fontSize || CLASS_NAMES[variant].fontSize}
        fontWeight={fontWeight || CLASS_NAMES[variant].fontWeight}
      >
        {label}
      </Typography>
    </button>
  );
};

export default Button;
