import prettyBytes from "pretty-bytes";
import dayjs from "dayjs";

export type WorkloadSummary = {
  workloadName?: string;
  workloadType?: string;
  id?: string | number;
  originalId?: string;
  replicas: number;
  optimizedReplicas: number;
  managedReplicas: number;
  runningReplicas: number;
  originalCPURequests: number;
  CPURequest: number;
  cpuDiff: number;
  originalMemoryRequests: number;
  memoryRequest: number;
  memoryDiff: number;
  activeSavings: number;
  clusterName: string;
  percentageOfOptimizedReplicas?: number;
};

export const cpuFormat = (value: number | undefined): number => {
  value = Number(value) ?? 0;
  value = isNaN(value) ? 0 : value;
  value = value / 1000;
  value = Math.round(value * 100) / 100;
  return value;
};

export const getMemoryFormat = (value: number | undefined): [number, string] => {
  value = isNaN(value || 0.0) || !value ? 0 : value;
  const values = prettyBytes(value, { bits: false, binary: true }).split(" ");
  return [Number(values[0]), values[1]];
};

type DataPoint = {
  [key: string]: string | number | undefined;
};

export const getDeducedGraphData = (data: DataPoint[]) => {
  const uniqueProperties = [...new Set(data.map((e) => Object.keys(e)).flat())].filter((e) => e !== "date") ?? [];

  const preAndNextValues = uniqueProperties.reduce((acc, property) => {
    acc[property] = {
      previousValue: undefined,
      nextValidValueDate: undefined,
    };
    return acc;
  }, {} as Record<string, { previousValue: number | undefined; nextValidValueDate: string | undefined }>);

  return data.map((dataPoint, index) => {
    /**
     * for each data point
     */
    const deducedDataPoint = {
      date: String(dataPoint.date),
    } as DataPoint;

    uniqueProperties.map((property) => {
      /**
       * for each property
       */
      const propertyValue = dataPoint[property] ?? undefined;

      if (propertyValue) {
        deducedDataPoint[property] = propertyValue;
        preAndNextValues[property].previousValue = propertyValue ? Number(propertyValue) : undefined;
      } else {
        if (!preAndNextValues[property].nextValidValueDate) {
          const nextValueDate = data.slice(index).find((e) => !!e[property])?.date;
          preAndNextValues[property].nextValidValueDate = nextValueDate ? String(nextValueDate) : undefined;
        }

        const diffFromNextValidValueDateInHours = dayjs(preAndNextValues[property].nextValidValueDate).diff(
          dayjs(dataPoint.date),
          "hour"
        );

        if (diffFromNextValidValueDateInHours > 3) {
          deducedDataPoint[property] = undefined;
        } else {
          deducedDataPoint[property] = preAndNextValues[property].previousValue;
        }
      }
    });

    return deducedDataPoint;
  });
};
