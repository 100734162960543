const AverageUsageColor = "#3B8BFF";
const MaxUsageColor = "#18135B";
const PercentileUsageColor = "#6C70FF";
const DIFF_COLOR = "#FF6161";
const ALLOCATABLE_COLOR = "#FF9141";
const Styles = {
  currentRequest: {
    stroke: "#EAB832",
    strokeDasharray: "",
  },
  originRequest: {
    stroke: "#ff517a",
    strokeDasharray: "",
  },
  currentLimit: {
    stroke: "#FFCC43",
    strokeDasharray: "8 8",
  },
  recommendedLimit: {
    stroke: "#10AE6E",
    strokeDasharray: "8 8",
  },
  recommendedAllocatable: {
    stroke: "#52D39D",
    strokeDasharray: "8 8",
  },
  recommendedRequest: {
    stroke: "#52D39D",
    strokeDasharray: "",
  },
  podsAvgUsage: {
    stroke: AverageUsageColor,
    strokeDasharray: "",
  },
  podsMaxUsage: {
    stroke: MaxUsageColor,
    strokeDasharray: "",
  },
  podsP90Usage: {
    stroke: PercentileUsageColor,
    strokeDasharray: "",
  },
};

export { AverageUsageColor, MaxUsageColor, PercentileUsageColor, DIFF_COLOR, ALLOCATABLE_COLOR };
export default Styles;
