import { Typography } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import prettyBytes from "pretty-bytes";
import { components } from "../../../../api/schema";
import { SCALEOPS_COLORS } from "../../../../colors";
import Bar from "../../../../components/Bar";
import Tooltip from "../../../../components/Tooltip";
import { getClusterNameRow } from "../../../../components/WorkloadStatusByNamespace/utils";
import WorkloadNameRow from "../../../../components/WorkloadStatusByNamespace/WorkloadNameRow";
import { currencyFormatter } from "../../../../utils/formatterUtils";
import { PolicyTuningTabs } from "../../../Overview/PolicyTuning/utils";
import { ColumnNames, Field } from "../utils";

const COST_COlUMN_MIN_WIDTH = 175;

export type WORKLOAD_ROW = components["schemas"]["NetworkCostWorkloadNetworkReport"] & {
  displayWorkloadName?: string;
};

const getPercentageValue = (value: number | undefined, total: number | undefined, displayValue: string) => {
  const percentage = displayValue !== "$0" && value && total ? (value / total) * 100 : 0;
  return Math.round(percentage * 100) / 100;
};

export const getCostRow = ({
  cost,
}: {
  cost?: {
    egress: number;
    ingress: number;
    total: number;
  };
}) => {
  const totalDisplayValue = currencyFormatter(cost?.total && cost.total > 0 ? cost.total : 0);
  const ingressDisplayValue = currencyFormatter(cost?.ingress && cost.ingress > 0 ? cost.ingress : 0);
  const egressDisplayValue = currencyFormatter(cost?.egress && cost.egress > 0 ? cost.egress : 0);

  const ingressPercentage = getPercentageValue(cost?.ingress, cost?.total, ingressDisplayValue);
  const egressPercentage = getPercentageValue(cost?.egress, cost?.total, egressDisplayValue);

  const tooltipContent = (
    <Typography variant="caption" className="flex flex-col">
      <div className="flex items-center gap-1">
        <span>Total:</span> {totalDisplayValue}
      </div>
      <div className="flex items-center gap-1">
        <div className="bg-network-ingress w-[30px] h-[10px] rounded-lg" />
        <span>Ingress:</span>
        {ingressDisplayValue}
        {ingressPercentage > 0 && <span className="text-strongerBorder">({ingressPercentage}%)</span>}
      </div>
      <div className="flex items-center gap-1">
        <div className="bg-network-egress w-[30px] h-[10px] rounded-lg" />
        <span>Egress:</span>
        {egressDisplayValue}
        {egressPercentage > 0 && <span className="text-strongerBorder">({egressPercentage}%)</span>}
      </div>
    </Typography>
  );

  return (
    <Tooltip
      title={tooltipContent}
      disabled={!cost}
      className="cursor-default w-full flex flex-col items-center justify-center"
      maxWidth={800}
    >
      <span>{totalDisplayValue}</span>
      <Bar
        style={{
          color: SCALEOPS_COLORS.network.ingress,
          backgroundColor: egressDisplayValue !== "$0" ? SCALEOPS_COLORS.network.egress : SCALEOPS_COLORS.network.total,
        }}
        value={ingressPercentage}
      />
    </Tooltip>
  );
};

export const getDataRow = ({
  value,
}: {
  value?: {
    egress: number;
    ingress: number;
    total: number;
  };
}) => {
  const totalDisplayValue = prettyBytes(value?.total && value.total > 0 ? value.total : 0.0, {
    bits: false,
    binary: true,
  });

  const ingressDisplayValue = prettyBytes(value?.ingress && value.ingress > 0 ? value.ingress : 0.0, {
    bits: false,
    binary: true,
  });

  const egressDisplayValue = prettyBytes(value?.egress && value.egress > 0 ? value.egress : 0.0, {
    bits: false,
    binary: true,
  });

  let ingressPercentage = value?.ingress && value?.total ? (value.ingress / value.total) * 100 : 0;
  ingressPercentage = Math.round(ingressPercentage * 100) / 100;
  let egressPercentage = value?.egress && value?.total ? (value.egress / value.total) * 100 : 0;
  egressPercentage = Math.round(egressPercentage * 100) / 100;

  const tooltipContent = (
    <Typography variant="caption" className="flex flex-col">
      <div>
        <div className="flex items-center gap-1">
          <div className="bg-network-total w-[30px] h-[10px] rounded-lg" />
          <span>Total:</span> {totalDisplayValue}
        </div>
        <div className="flex items-center gap-1">
          <div className="bg-network-ingress w-[30px] h-[10px] rounded-lg" />
          <span>Ingress:</span> {ingressPercentage > 0 && ingressDisplayValue === "0 B" && <>&gt;</>}
          {ingressDisplayValue}
          {ingressPercentage > 0 && <span className="text-strongerBorder">({ingressPercentage}%)</span>}
        </div>
        <div className="flex items-center gap-1">
          <div className="bg-network-egress w-[30px] h-[10px] rounded-lg" />
          <span>Egress:</span> {egressPercentage > 0 && egressDisplayValue === "0 B" && <>&gt;</>}
          {egressDisplayValue}
          {egressPercentage > 0 && <span className="text-strongerBorder">({egressPercentage}%)</span>}
        </div>
      </div>
    </Typography>
  );

  return (
    <Tooltip
      title={tooltipContent}
      disabled={!value}
      className="cursor-default w-full flex flex-col items-center justify-center"
      maxWidth={800}
    >
      <span>{totalDisplayValue}</span>
      <Bar
        style={{
          color: SCALEOPS_COLORS.network.ingress,
          backgroundColor: value?.egress ? SCALEOPS_COLORS.network.egress : SCALEOPS_COLORS.network.total,
        }}
        value={ingressPercentage}
      />
    </Tooltip>
  );
};

export const getColumns = (selectedColumns: (string | undefined)[]): GridColDef[] => [
  {
    field: Field.workloadName,
    headerName: ColumnNames[Field.workloadName],
    hide: !selectedColumns.includes(Field.workloadName),
    flex: 3,
    minWidth: 150,
    type: "string",
    align: "left",
    headerAlign: "center",
    disableColumnMenu: true,
    sortable: true,
    renderCell: (
      params: GridRenderCellParams<
        components["schemas"]["NetworkCostWorkloadNetworkReport"],
        components["schemas"]["NetworkCostWorkloadNetworkReport"]
      >
    ) => (
      <WorkloadNameRow
        name={params.row.workloadName}
        namespace={params.row.namespace}
        type={params.row.workloadType}
        cluster={params.row.clusterName}
        targetTab={PolicyTuningTabs.Network}
      />
    ),
  },
  {
    field: Field.clusterName,
    headerName: ColumnNames[Field.clusterName],
    hide: !selectedColumns.includes(Field.clusterName),
    flex: 1,
    minWidth: 200,
    type: "string",
    align: "left",
    headerAlign: "center",
    disableColumnMenu: true,
    sortable: true,
    renderCell: (
      params: GridRenderCellParams<
        components["schemas"]["NetworkCostWorkloadNetworkReport"],
        components["schemas"]["NetworkCostWorkloadNetworkReport"]
      >
    ) =>
      getClusterNameRow({
        clusterName: params.row.clusterName,
      }),
  },
  {
    field: Field.replicas,
    headerName: ColumnNames[Field.replicas],
    hide: !selectedColumns.includes(Field.replicas),
    flex: 1,
    minWidth: 150,
    type: "number",
    align: "center",
    headerAlign: "center",
    disableColumnMenu: true,
    sortable: true,
    renderCell: (
      params: GridRenderCellParams<
        components["schemas"]["NetworkCostWorkloadNetworkReport"],
        components["schemas"]["NetworkCostWorkloadNetworkReport"]
      >
    ) => Math.round(params.row?.replicas ?? 0),
  },
  {
    field: Field.totalCost,
    headerName: ColumnNames[Field.totalCost],
    hide: !selectedColumns.includes(Field.totalCost),
    flex: 1,
    minWidth: COST_COlUMN_MIN_WIDTH,
    type: "number",
    align: "center",
    headerAlign: "center",
    disableColumnMenu: true,
    sortable: true,
    renderCell: (
      params: GridRenderCellParams<
        components["schemas"]["NetworkCostWorkloadNetworkReport"],
        components["schemas"]["NetworkCostWorkloadNetworkReport"]
      >
    ) =>
      getCostRow({
        cost: params.row.totalCost,
      }),
  },
  {
    field: Field.crossAZCost,
    headerName: ColumnNames[Field.crossAZCost],
    hide: !selectedColumns.includes(Field.crossAZCost),
    flex: 1,
    minWidth: COST_COlUMN_MIN_WIDTH,
    type: "number",
    align: "center",
    headerAlign: "center",
    disableColumnMenu: true,
    sortable: true,
    renderCell: (
      params: GridRenderCellParams<
        components["schemas"]["NetworkCostWorkloadNetworkReport"],
        components["schemas"]["NetworkCostWorkloadNetworkReport"]
      >
    ) =>
      getCostRow({
        cost: params.row.crossAZCost,
      }),
  },
  {
    field: Field.intraAZCost,
    headerName: ColumnNames[Field.intraAZCost],
    hide: !selectedColumns.includes(Field.intraAZCost),
    flex: 1,
    minWidth: COST_COlUMN_MIN_WIDTH,
    type: "number",
    align: "center",
    headerAlign: "center",
    disableColumnMenu: true,
    sortable: true,
    renderCell: (
      params: GridRenderCellParams<
        components["schemas"]["NetworkCostWorkloadNetworkReport"],
        components["schemas"]["NetworkCostWorkloadNetworkReport"]
      >
    ) =>
      getCostRow({
        cost: params.row.intraAZCost,
      }),
  },
  {
    field: Field.totalDataTransfer,
    headerName: ColumnNames[Field.totalDataTransfer],
    hide: !selectedColumns.includes(Field.totalDataTransfer),
    flex: 1,
    minWidth: COST_COlUMN_MIN_WIDTH,
    type: "number",
    align: "center",
    headerAlign: "center",
    disableColumnMenu: true,
    sortable: true,
    renderCell: (
      params: GridRenderCellParams<
        components["schemas"]["NetworkCostWorkloadNetworkReport"],
        components["schemas"]["NetworkCostWorkloadNetworkReport"]
      >
    ) =>
      getDataRow({
        value: params.row.totalDataTransfer,
      }),
  },
  {
    field: Field.crossAZDataTransfer,
    headerName: ColumnNames[Field.crossAZDataTransfer],
    hide: !selectedColumns.includes(Field.crossAZDataTransfer),
    flex: 1,
    minWidth: COST_COlUMN_MIN_WIDTH,
    type: "number",
    align: "center",
    headerAlign: "center",
    disableColumnMenu: true,
    sortable: true,
    renderCell: (
      params: GridRenderCellParams<
        components["schemas"]["NetworkCostWorkloadNetworkReport"],
        components["schemas"]["NetworkCostWorkloadNetworkReport"]
      >
    ) =>
      getDataRow({
        value: params.row.crossAZDataTransfer,
      }),
  },
  {
    field: Field.intraAZDataTransfer,
    headerName: ColumnNames[Field.intraAZDataTransfer],
    hide: !selectedColumns.includes(Field.intraAZDataTransfer),
    flex: 1,
    minWidth: COST_COlUMN_MIN_WIDTH,
    type: "number",
    align: "center",
    headerAlign: "center",
    disableColumnMenu: true,
    sortable: true,
    renderCell: (
      params: GridRenderCellParams<
        components["schemas"]["NetworkCostWorkloadNetworkReport"],
        components["schemas"]["NetworkCostWorkloadNetworkReport"]
      >
    ) =>
      getDataRow({
        value: params.row.intraAZDataTransfer,
      }),
  },
];
