import FormInput from "../../../components/formComponents/FormInput";
import FormTitleAndDescription from "../../../components/formComponents/FormTitleAndDescription";
import { shouldDisableDefaultPolicyInputs } from "../utils";

interface Props {
  disableCpuOption?: boolean;
  disableMemoryOption?: boolean;
  isCustomizedPolicy?: boolean;
  disableCpuInput?: boolean;
  disableMemoryInput?: boolean;
}

const SetLimits = ({ disableCpuOption, disableMemoryOption, isCustomizedPolicy }: Props) => {
  return (
    <div className="flex flex-col gap-4 pt-5">
      <FormTitleAndDescription
        title="Set custom limits"
        description="Define fixed limits for container resources."
        titleVariant="body2"
        textDisabled={disableCpuOption && disableMemoryOption}
      />
      <div className="flex flex-wrap gap-4">
        <div>
          <FormInput
            label="CPU"
            name="cpuLimitsSetLimitValue"
            type="number"
            disabled={disableCpuOption || (!isCustomizedPolicy && shouldDisableDefaultPolicyInputs)}
            disableChange={!isCustomizedPolicy}
          />
        </div>
        <div>
          <FormInput
            label="Memory (GiB)"
            name="memoryLimitsSetLimitValue"
            type="number"
            disabled={disableMemoryOption || (!isCustomizedPolicy && shouldDisableDefaultPolicyInputs)}
            disableChange={!isCustomizedPolicy}
          />
        </div>
      </div>
    </div>
  );
};

export default SetLimits;
