import { useState, useEffect } from "react";
import dayjs from "dayjs";
import { EventPoint } from "../../../pages/Overview/PolicyTuning/Diagnostics/utils";
import Dialog from "../../Dialog";
import DialogTitle from "../../DialogTitle";
import { ViewPeriodOptions } from "./SelectViewPeriod";
import ResourcesOverTimeCharts from "./ResourcesOverTimeCharts";
import TopSection from "./TopSection";
import NodeDiagnosticsContainer from "./NodeDiagnosticsContainer";

const HAS_DIAGNOSTICS = true;

interface Props {
  selectedNodeName: string | undefined;
  setSelectedNodeName: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const NodeOverviewContainer = ({ selectedNodeName, setSelectedNodeName }: Props) => {
  const [emptyEventArray, setEmptyEventArray] = useState<EventPoint[] | undefined>(undefined);

  const [from, setFrom] = useState<number>(dayjs().subtract(1, "day").unix());
  const [to, setTo] = useState<number>(dayjs().unix());
  const [selectedViewPeriod, setSelectedViewPeriod] = useState<string>(ViewPeriodOptions["1 day"]);

  useEffect(() => {
    setFrom(dayjs().subtract(Number(selectedViewPeriod), "hour").unix());
    setTo(dayjs().unix());
  }, [selectedViewPeriod]);

  return (
    <Dialog
      isOpen={!!selectedNodeName}
      onClose={() => setSelectedNodeName(undefined)}
      title={<DialogTitle title="Node overview" value={selectedNodeName} />}
      dialogContentStyle={{
        padding: "0px",
      }}
    >
      <div className="p-6 flex flex-col justify-center items-center min-w-[600px] min-h-[400px] gap-4 mb-2">
        <TopSection selectedViewPeriod={selectedViewPeriod} setSelectedViewPeriod={setSelectedViewPeriod} />
        <ResourcesOverTimeCharts
          from={from}
          to={to}
          selectedNodeName={selectedNodeName}
          setEmptyEventArray={setEmptyEventArray}
          selectedViewPeriod={selectedViewPeriod}
        />
        {HAS_DIAGNOSTICS && (
          <div className="w-full">
            <NodeDiagnosticsContainer
              name={selectedNodeName}
              endDate={to}
              startDate={from}
              selectedViewPeriod={selectedViewPeriod}
              emptyEventArray={emptyEventArray}
            />
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default NodeOverviewContainer;
