import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { GetAggregations, GetAggregationsResponse } from "../../../../../api/fetcher";
import ExportCSV from "../../../../../components/exportCSV/ExportCSV";
import ExportCSVOptionButton from "../../../../../components/ExportCSVOptionButton";
import { Field } from "../../utils";
import useAggregationFilters from "../hooks/useAggregationFilters";

const BUTTON_TITLE = "Aggregated cost report";
const { queryKey, queryFn } = GetAggregations();

type CSVExportType = {
  id: string;
  clusterName: string;
  totalCost: number;
  savingsAvailable: number;
  spot: number;
  onDemand: number;
  ownerCpuRequest: number;
  ownerMemoryRequest: number;
  replicas: number;
  amountOfWorkloads: number;
};

const ExportAggregationCSV = () => {
  const filters = useAggregationFilters();

  const [rows, setRows] = useState<GetAggregationsResponse["aggregatedWorkloads"]>([]);

  const { data, isLoading, isError } = useQuery<GetAggregationsResponse, Error>({
    queryKey: [queryKey, filters],
    queryFn: () =>
      queryFn({
        ...filters,
        multiCluster: true,
      }),
    enabled: Object.keys(filters).length > 0,
  });

  useEffect(() => {
    if (data?.aggregatedWorkloads) {
      const dataToSet = data?.aggregatedWorkloads.map((wl) => {
        const totalWorkloads = wl.spot + wl.onDemand;

        // Spot %
        let spotPercentage = Math.round((wl.spot / totalWorkloads) * 100);
        if (isNaN(spotPercentage) || spotPercentage < 0 || spotPercentage > 100) spotPercentage = 0;

        // On-demand %
        let onDemandPercentage = Math.round((wl.onDemand / totalWorkloads) * 100);
        if (isNaN(onDemandPercentage) || onDemandPercentage < 0 || onDemandPercentage > 100) onDemandPercentage = 0;

        return {
          ...wl,
          spot: spotPercentage,
          onDemand: onDemandPercentage,
        };
      });

      setRows(dataToSet);
    }
  }, [data]);

  if (isLoading || isError) {
    return <ExportCSVOptionButton title={BUTTON_TITLE} disabled={true} />;
  }

  return (
    <div className="w-fit">
      <ExportCSV<CSVExportType>
        customButtonElement={<ExportCSVOptionButton title={BUTTON_TITLE} />}
        filename="aggregated_cost_report.csv"
        columns={[
          Field.id,
          Field.clusterName,
          Field.totalCost,
          Field.savingsAvailable,
          Field.spot,
          Field.onDemand,
          Field.cpu,
          Field.memory,
          Field.replicas,
          Field.amountOfWorkloads,
        ]}
        data={
          rows.map((row) => ({
            id: row.id,
            clusterName: row.clusterName,
            totalCost: row.totalCost,
            savingsAvailable: row.savingsAvailable,
            spot: row.spot,
            onDemand: row.onDemand,
            ownerCpuRequest: row.ownerCpuRequest,
            ownerMemoryRequest: row.ownerMemoryRequest,
            replicas: row.replicas,
            amountOfWorkloads: row.amountOfWorkloads,
          })) as CSVExportType[]
        }
        columnsToRound={[
          Field.totalCost,
          Field.savingsAvailable,
          Field.spot,
          Field.onDemand,
          Field.cpu,
          Field.memory,
          Field.replicas,
          Field.amountOfWorkloads,
        ]}
        columnsToSum={[
          Field.totalCost,
          Field.savingsAvailable,
          Field.cpu,
          Field.memory,
          Field.replicas,
          Field.amountOfWorkloads,
        ]}
        columnsToAverage={[Field.spot, Field.onDemand]}
        customColumnNames={{
          [Field.id]: "Name",
          [Field.clusterName]: "Cluster",
          [Field.totalCost]: "Total Cost",
          [Field.savingsAvailable]: "Savings Available",
          [Field.spot]: "Spot %",
          [Field.onDemand]: "On-demand %",
          [Field.cpu]: "CPU request",
          [Field.memory]: "Memory request",
          [Field.replicas]: "Number of Pods",
          [Field.amountOfWorkloads]: "Amount of Workloads",
        }}
      />
    </div>
  );
};

export default ExportAggregationCSV;
