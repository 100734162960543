import MultiSelect from "../MultiSelect";
import * as React from "react";
import { useEffect } from "react";
import CustomColumnsFilterButton from "../CustomColumnsFilterButton";
import { GridColumnVisibilityModel } from "@mui/x-data-grid";
import { ColumnNames, Columns } from "./utils";

interface Props {
  setColumnsVisibility: React.Dispatch<React.SetStateAction<GridColumnVisibilityModel>>;
}

export const AggregationColumnsFilter = ({ setColumnsVisibility }: Props) => {
  const [selectedColumns, setSelectedColumns] = React.useState<string[]>([
    ColumnNames[Columns.Name],
    ColumnNames[Columns.TotalCost],
    ColumnNames[Columns.SavingsAvailable],
    ColumnNames[Columns.ActiveSavings],
    ColumnNames[Columns.CpuDiff],
    ColumnNames[Columns.MemDiff],
    ColumnNames[Columns.AutomationPercentage],
  ]);

  const filterColumns = [
    ColumnNames[Columns.Name],
    ColumnNames[Columns.TotalCost],
    ColumnNames[Columns.SavingsAvailable],
    ColumnNames[Columns.ActiveSavings],
    ColumnNames[Columns.CpuDiff],
    ColumnNames[Columns.MemDiff],
    ColumnNames[Columns.AutomationPercentage],
    ColumnNames[Columns.OverProvisioned],
    ColumnNames[Columns.UnderProvisioned],
  ];

  useEffect(() => {
    const columns = Object.values(Columns);
    const ColumnsVisibility: GridColumnVisibilityModel = {};
    columns.map((column) => {
      const columnKey = column as Columns;
      if (selectedColumns.includes(ColumnNames[columnKey])) {
        ColumnsVisibility[columnKey] = true;
      } else {
        ColumnsVisibility[columnKey] = false;
      }
    });
    setColumnsVisibility(ColumnsVisibility);
  }, [selectedColumns]);

  return (
    <MultiSelect
      selected={selectedColumns}
      setSelected={setSelectedColumns as React.Dispatch<React.SetStateAction<(string | undefined)[]>>}
      options={filterColumns}
      className="top-[9px] w-[85px]"
      customIcon={<CustomColumnsFilterButton isFiltered={selectedColumns.length > 0} />}
    />
  );
};
