import dayjs from "dayjs";
import { CustomTooltipProps, WASTE_COLOR, TabOptions, StripsSvg } from "./nodesCostUtils";

const TooltipOption = ({
  name,
  value,
  background,
  hasRec,
}: {
  name: string;
  value: number;
  background: string;
  hasRec?: boolean;
}) => {
  return (
    <p
      className="flex gap-2 items-center"
      style={{
        color: background,
      }}
    >
      {hasRec && StripsSvg}
      {!hasRec && (
        <div
          style={{
            background: background,
          }}
          className="w-6 h-2"
        />
      )}
      {name}: {value}
    </p>
  );
};

const NodeCostTooltip = ({ active, payload, elementsToRender, selectedTab }: CustomTooltipProps) => {
  if (active && payload && payload.length) {
    const { timestamp, currentCost, recommendedCost, currentNodes, recommendedNodes } = payload[0].payload;

    const costDiff = Math.round((currentCost - recommendedCost) * 100) / 100;
    const nodesDiff = Math.round((currentNodes - recommendedNodes) * 100) / 100;

    return (
      <div className="bg-white text-black py-4 px-8 flex flex-col rounded-md text-[14px] opacity-90">
        <p>{dayjs(timestamp).format("DD/MMM/YYYY HH:mm")}</p>
        {elementsToRender.map((element) => {
          let value;

          switch (element.dataKey) {
            case "currentCost":
              value = currentCost;
              break;
            case "recommendedCost":
              value = recommendedCost;
              break;
            case "currentNodes":
              value = currentNodes;
              break;
            case "recommendedNodes":
              value = recommendedNodes;
              break;
            default:
              value = 0;
              break;
          }

          if (Number.isNaN(value) || value < 0 || element.legendName?.includes("waste")) return null;

          return <TooltipOption name={element.legendName} value={value} background={element.stroke} />;
        })}
        {selectedTab === TabOptions.Cost && costDiff > 0 && (
          <TooltipOption name="Waste" value={costDiff} background={WASTE_COLOR} hasRec />
        )}
        {selectedTab === TabOptions.Nodes && nodesDiff > 0 && (
          <TooltipOption name="Waste" value={nodesDiff} background={WASTE_COLOR} hasRec />
        )}
      </div>
    );
  }
  return null;
};

export default NodeCostTooltip;
