import { useQuery } from "@tanstack/react-query";
import { GetNamespacesResponse, getNamespaces } from "../../../api/fetcher";
import { components } from "../../../api/schema";

const { queryKey, queryFn } = getNamespaces();

const useGetNamespaces = () => {
  const { data, isLoading, error } = useQuery<GetNamespacesResponse>({
    queryKey: [queryKey],
    queryFn: () => queryFn({}),
  });

  if (error) {
    console.log("Error fetching labels", error);
    return [];
  }

  if (isLoading || !data?.namespaces) {
    return [];
  }

  return data.namespaces
    .filter((ns) => ns !== undefined)
    .map((ns: components["schemas"]["V1Namespace"]) => ns.metadata?.name ?? "")
    .filter((ns) => ns != "");
};

export default useGetNamespaces;
