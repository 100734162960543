import { Typography } from "@mui/material";
import { components } from "../../../api/schema";
import BoxSwitch, { THEMES } from "../../../components/BoxSwitch";
import Tooltip from "../../../components/Tooltip";
import YouHaveReadOnlyAccess from "../../../components/YouHaveReadOnlyAccess";
import useIsReadyOnlyFrontEnd from "../../../utils/useIsReadyOnlyFrontEnd";
import useUpdateAutomation from "./mutation/useUpdateAutomation";

interface Props {
  isAutomate: boolean;
  setIsAutomate: (isAutomate: boolean) => void;
  fetchWorkloads: () => void;
  selectedWorkload: components["schemas"]["UtilsWorkload"];
}

const AutomationSwitch = ({ isAutomate, setIsAutomate, fetchWorkloads, selectedWorkload }: Props) => {
  const isEditable = selectedWorkload?.isEditable;
  const isReadyOnlyFrontEnd = useIsReadyOnlyFrontEnd();
  const isScaleopsSystemWorkload = selectedWorkload?.namespace === "scaleops-system";
  const updateAutomation = useUpdateAutomation(fetchWorkloads);

  const handleClick = () => {
    updateAutomation.mutate({
      id: selectedWorkload.id,
      namespace: selectedWorkload.namespace,
      workloadName: selectedWorkload.workloadName,
      workloadType: selectedWorkload.type,
      state: !isAutomate,
    });
    setIsAutomate(!isAutomate);
  };

  const isAutomationExcluded = selectedWorkload.isAutomationExcluded;
  let tooltipContent = <></>;
  switch (true) {
    case isReadyOnlyFrontEnd:
      tooltipContent = <YouHaveReadOnlyAccess />;
      break;
    case !isEditable:
      tooltipContent = (
        <Typography variant="caption">
          You don't have <b>permissions</b> to save this workload.
        </Typography>
      );
      break;
    case isScaleopsSystemWorkload:
      tooltipContent = <Typography variant="caption">Can't modify scaleops-system workloads.</Typography>;
      break;
    default:
      tooltipContent = <>Enable continuous automation to automatically apply recommendations to your workload.</>;
      break;
  }

  const disabled = isReadyOnlyFrontEnd || !isEditable || isScaleopsSystemWorkload || isAutomationExcluded;

  return (
    <Tooltip title={tooltipContent} maxWidth={500}>
      <BoxSwitch
        title="Automate"
        handleClick={handleClick}
        checked={isAutomate}
        disabled={disabled}
        notAllowed={isAutomationExcluded}
        theme={THEMES.Purple}
      />
    </Tooltip>
  );
};

export default AutomationSwitch;
