import FormInput from "../../../components/formComponents/FormInput";
import FormTitleAndDescription from "../../../components/formComponents/FormTitleAndDescription";
import { shouldDisableDefaultPolicyInputs } from "../utils";

interface Props {
  isCustomizedPolicy?: boolean;
  disableCpuOption?: boolean;
  disableMemoryOption?: boolean;
}

const HistogramLimitPercentileConfiguration = ({
  isCustomizedPolicy,
  disableCpuOption,
  disableMemoryOption,
}: Props) => {
  return (
    <div className="flex flex-col gap-4 pt-5">
      <FormTitleAndDescription
        title="Histogram limit percentile configuration"
        description="Define how close should ScaleOps be to the usage that measured."
        titleVariant="body2"
        textDisabled={disableCpuOption && disableMemoryOption}
      />
      <div className="flex flex-wrap gap-12">
        <FormInput
          label="CPU Limits (%)"
          name="histogramCpuLimitsPercentile"
          type="number"
          disabled={disableCpuOption || (!isCustomizedPolicy && shouldDisableDefaultPolicyInputs)}
          disableChange={!isCustomizedPolicy}
        />
        <FormInput
          label="Memory Limits (%)"
          name="histogramMemoryLimitsPercentile"
          type="number"
          disabled={disableMemoryOption || (!isCustomizedPolicy && shouldDisableDefaultPolicyInputs)}
          disableChange={!isCustomizedPolicy}
        />
      </div>
    </div>
  );
};

export default HistogramLimitPercentileConfiguration;
