export enum OverviewChartColors {
  Allocatable = "#FF9141",
  Request = "#EAB832",
  Usage = "#3B8BFF",
  Waste = "#FF6161",
  Recommended = "#52D39D",
}

export enum TabOptions {
  Day = "24 Hours",
  Week = "7 Days",
  Month = "30 Days",
}

export type RequestAndUsageByTimestampKey = {
  usage: number;
  request: number;
  requestMask?: number;
  allocatable: number;
  recommendedAllocatable?: number;
  allocatableMask?: number;
  recommended?: number;
  timestampAggregators?: string | null | undefined;
  timestamps: string | null | undefined;
};

export type ParsedData = {
  date: string;
  toDate?: string;
  fromDate?: string;
  allocatable: number;
  request: number;
  usage: number;
  waste?: number | undefined;
  recommended?: number;
};

export const groupData = (data: (RequestAndUsageByTimestampKey & { day?: string })[], groupByWeek?: boolean) => {
  data.forEach((item) => {
    item.day = item.timestamps?.split(" ")[0];
  });

  const responseData: ParsedData[] = [];
  const groupByDate = data.reduce((acc, item) => {
    if (!acc[item.day as string]) {
      acc[item.day as string] = [];
    }
    acc[item.day as string].push(item);
    return acc;
  }, {} as { [key: string]: RequestAndUsageByTimestampKey[] });

  for (const [key, value] of Object.entries(groupByDate)) {
    const usage = value.reduce((acc, item) => acc + item.usage, 0);
    const request = value.reduce((acc, item) => acc + item.request, 0);
    const allocatable = value.reduce((acc, item) => acc + item.allocatable, 0);
    const recommended = value.reduce((acc, item) => acc + (item.recommended ?? 0), 0);
    responseData.push({
      date: key,
      usage: Math.round((usage / value.length + Number.EPSILON) * 100) / 100,
      request: Math.round((request / value.length + Number.EPSILON) * 100) / 100,
      allocatable: Math.round((allocatable / value.length + Number.EPSILON) * 100) / 100,
      recommended: Math.round((recommended / value.length + Number.EPSILON) * 100) / 100,
    });
  }

  const sortedData = responseData.sort((a, b) => {
    return new Date(a.date).getTime() - new Date(b.date).getTime();
  });

  if (groupByWeek) {
    const groupedData: ParsedData[] = [];
    const groupByWeek = sortedData.reduce((acc, item, index) => {
      if (index % 7 === 0) {
        acc.push([]);
      }
      acc[acc.length - 1].push(item);
      return acc;
    }, [] as ParsedData[][]);

    for (const [key, value] of Object.entries(groupByWeek)) {
      const usage = value.reduce((acc, item) => acc + item.usage, 0);
      const request = value.reduce((acc, item) => acc + item.request, 0);
      const allocatable = value.reduce((acc, item) => acc + item.allocatable, 0);
      const recommended = value.reduce((acc, item) => acc + (item.recommended ?? 0), 0);
      groupedData.push({
        date: `${value[0].date} - ${value[value.length - 1].date}`,
        usage: Math.round((usage / value.length + Number.EPSILON) * 100) / 100,
        request: Math.round((request / value.length + Number.EPSILON) * 100) / 100,
        allocatable: Math.round((allocatable / value.length + Number.EPSILON) * 100) / 100,
        recommended: Math.round((recommended / value.length + Number.EPSILON) * 100) / 100,
      });

      if (key === "0") {
        groupedData[0].date = `${value[value.length - 1].date} - ${value[0].date}`;
      }

      if (key === `${groupByWeek.length - 1}`) {
        groupedData[groupedData.length - 1].date = `${value[0].date} - ${value[value.length - 1].date}`;
      }

      groupedData[groupedData.length - 1].fromDate = value[0].date;
      groupedData[groupedData.length - 1].toDate = value[value.length - 1].date;
    }

    return groupedData;
  }

  return sortedData;
};
