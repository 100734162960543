import dayjs from "dayjs";
import ChartTooltipElement from "../../../../components/ChartTooltipElement";
import ChartTooltipTime from "../../../../components/ChartTooltipTime";
import { TOOLTIP_WRAPPER_CLASS_NAME } from "../../../../utils/styleUtils";
import { HpaChartComponent } from "./UsageHpaChart";
import FreezeTooltipWarning from "../../../Analytics/AnalyticsV2/Graphs/hooks/FreezeTooltipWarning";
import { TooltipTrigger } from "../../../Analytics/AnalyticsV2/Graphs/hooks/useFreezeTooltip";
import { FrozenTooltipType } from "../../../Analytics/AnalyticsV2/Graphs/hooks/utils";

interface CustomHpaTooltipProps {
  active?: boolean;
  yTickFormatter?: (tick: string | number) => string;
  payload?: {
    fill: string;
    stroke: string;
    strokeWidth: number;
    fillOpacity: number;
    dataKey: string;
    name: string;
    color: string;
    value: number;
    payload: {
      timestamp: string;
    };
  }[];
  selectedChartComponents: HpaChartComponent[];
  tooltipTrigger?: TooltipTrigger;
  frozenTooltipType?: FrozenTooltipType;
  displayNameFormatter?: (name: string) => string;
}

const CustomHpaTooltip = ({
  active,
  payload,
  yTickFormatter,
  tooltipTrigger,
  frozenTooltipType,
  displayNameFormatter,
}: CustomHpaTooltipProps) => {
  if (active && payload && payload.length) {
    const { timestamp } = payload[0].payload;
    return (
      <div className={TOOLTIP_WRAPPER_CLASS_NAME}>
        <ChartTooltipTime timestamp={dayjs.unix(Number(timestamp))} />
        {payload.map(({ name, value, color }) => {
          return (
            <ChartTooltipElement
              color={color}
              value={<>{yTickFormatter ? yTickFormatter(value) : value}</>}
              label={displayNameFormatter ? displayNameFormatter(name) : name}
            />
          );
        })}
        <FreezeTooltipWarning tooltipTrigger={tooltipTrigger} frozenTooltipType={frozenTooltipType} />
      </div>
    );
  }

  return null;
};

export default CustomHpaTooltip;
