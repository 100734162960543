import { Typography } from "@mui/material";
import clsx from "clsx";
import Tooltip from "../../../components/Tooltip";
import { METRIC_BOX_CLASS_NAME } from "./utils";
import CreativeInfoIcon from "../../../Icons/CreativeInfoIcon";

interface Props {
  eventReason?: string;
  eventAction?: string;
}

const PendingWarning = ({ eventReason, eventAction }: Props) => {
  if (!eventReason) {
    return null;
  }

  return (
    <Tooltip
      title={
        <Typography variant="caption">
          <b>Workload has optimization gap due to:</b>
          <p className="italic">{eventReason}</p>
          {eventAction && <p className="italic pt-3">{eventAction}</p>}
        </Typography>
      }
      placement="left"
      maxWidth={400}
    >
      <div className={clsx(METRIC_BOX_CLASS_NAME, "mt-2 flex items-center justify-center gap-1")}>
        Optimization gap{" "}
        <div className="w-[12px]">
          <CreativeInfoIcon width={12} height={12} />
        </div>
      </div>
    </Tooltip>
  );
};

export default PendingWarning;
