import clsx from "clsx";
import prettyBytes from "pretty-bytes";
import { currencyFormatter, customNumberFormatter } from "../../utils/formatterUtils";
import Tooltip from "../Tooltip";

// @barel22 the new utils currently only used as part of the cost page and should be added to the workload overview‚

/** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** **
 *
 * General costs
 *
 ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** **/
export const SCALEOPS_ROLLOUT = "scaleops-rollout";
export const ASC = "asc";
export const DESC = "desc";

/** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** **
 *
 * General enums, types and interfaces
 *
 ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** **/

export enum WorkloadType {
  AlgoRollout = "argo-rollout",
  GenericType = "Custom Workload",
  Family = "Family",
}

/** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** **
 *
 * Get the cluster name row
 *
 ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** **/

interface GetClusterNameRowProps {
  clusterName: string;
}

export const getClusterNameRow = ({ clusterName }: GetClusterNameRowProps) => {
  return (
    <Tooltip title={<div>{clusterName}</div>} maxWidth={500} className="truncate">
      {clusterName}
    </Tooltip>
  );
};

/** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** **
 *
 * Get cost row
 *
 ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** **/

interface GetCostRowProps {
  cost?: number;
  colorBySavings?: boolean;
}

export const getCostRow = ({ cost, colorBySavings }: GetCostRowProps) => {
  const displayValue = currencyFormatter(cost && cost > 0 ? cost : 0);
  return (
    <span
      className={clsx({
        "text-guideline-darkGreen": colorBySavings && displayValue !== "$0" && cost && cost > -1,
      })}
    >
      {displayValue}
    </span>
  );
};

/** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** **
 *
 * Get percentage row
 *
 ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** **/

interface GetPercentageRowProps {
  percentage?: number;
}

export const getPercentageRow = ({ percentage }: GetPercentageRowProps) => {
  percentage = !percentage || percentage > 100 || percentage < 0 ? 0 : percentage;
  const displayValue = Math.round((percentage ?? 0) * 100) / 100;
  return <span>{displayValue}%</span>;
};

/** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** **
 *
 * get Number Row percentage row
 *
 ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** **/

interface GetNumberRowProps {
  value?: number;
}

export const getNumberRow = ({ value }: GetNumberRowProps) => {
  return <span>{customNumberFormatter(value ?? 0)}</span>;
};

/** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** **
 *
 * get Memory row
 *
 ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** **/

interface GetMemoryRowProps {
  memory?: number;
}

export const getMemoryRow = ({ memory }: GetMemoryRowProps) => {
  const displayValue = prettyBytes(memory || 0.0, {
    bits: false,
    binary: true,
  });
  return <span>{displayValue}</span>;
};

export const CantEditScaleopsWorkloadsTooltip = (
  <div className="flex flex-row gap-1">
    <div>
      Cannot modify policy or automation for <b>workloads</b> in scaleops-system <b>namespace</b>
    </div>
  </div>
);

/** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ***
 *
 * Select rows on loading query param types
 *
 ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ***/
export enum SelectRowsOnInitialLoading {
  All = "all",
  Unautomated = "unautomated",
}
