import { useState } from "react";
import MultiSelectByQueryParams, { MultiSelectByQueryParamsProps } from "../MultiSelectByQueryParams";
import { AnnotationOptions, WorkloadAnnotation } from "./BulkPolicyEditorToolbar";

// Disable this frontend ability for now
const ENABLE_KEY_OR_VALUE_SELECTION = false;

type AnnotationMultiSelectProps = Omit<MultiSelectByQueryParamsProps, "options"> & {
  workloadsAnnotations: WorkloadAnnotation[];
};

export const AnnotationMultiSelect = ({
  workloadsAnnotations,
  queryKey,
  tooltipPrefix,
  hasVirtualizedList,
  hasLogicalAndOR,
  logicalOperatorQueryKey,
}: AnnotationMultiSelectProps) => {

  const [currentOption, setCurrentOption] = useState<AnnotationOptions>(AnnotationOptions.KeyValue);

  // We prepend the option with the enum value so the backend can filter based on the selected option
  const options =
    currentOption === AnnotationOptions.KeyValue
      ? workloadsAnnotations.map((annotation) => `${annotation.key}=${annotation.value}`)
      : currentOption === AnnotationOptions.Key
      ? workloadsAnnotations.map((annotation) => `<${AnnotationOptions.Key}>${annotation.key}`)
      : workloadsAnnotations.map((annotation) => `<${AnnotationOptions.Value}>${annotation.value}`);

  const uniqueOptions = Array.from(new Set(options));

  const customSelectOptions = ENABLE_KEY_OR_VALUE_SELECTION ? {
    options: Object.values(AnnotationOptions),
    setOption: (option: string) => setCurrentOption(option as AnnotationOptions),
    currentOption,
  } : undefined;

  const modifyOptionText = ENABLE_KEY_OR_VALUE_SELECTION ? (option: string) => {
    Object.values(AnnotationOptions).forEach((optionEnum) => {
      if (option.startsWith(`<${optionEnum}>`)) {
        option = option.slice(`<${optionEnum}>`.length);
      }
    });

    return option;
  } : undefined;

  return (
    <>
      <MultiSelectByQueryParams
        options={uniqueOptions}
        queryKey={queryKey}
        tooltipPrefix={tooltipPrefix}
        hasVirtualizedList={hasVirtualizedList}
        hasLogicalAndOR={hasLogicalAndOR}
        logicalOperatorQueryKey={logicalOperatorQueryKey}
        customSelectOptions={customSelectOptions}
        modifyOptionText={modifyOptionText}
      />
    </>
  );
};
