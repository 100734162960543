import { Typography } from "@mui/material";
import { memo } from "react";
import { useWorkloadsContext } from "../../../WorkloadsContext";
import { components } from "../../../api/schema";
import Button from "../../../components/Button";
import Tooltip from "../../../components/Tooltip";
import YouHaveReadOnlyAccess from "../../../components/YouHaveReadOnlyAccess";
import useIsReadyOnlyFrontEnd from "../../../utils/useIsReadyOnlyFrontEnd";
import { Policy } from "./PolicyTuning";
import useUpdateRecommendation from "./mutation/useUpdateRecommendation";

const NotEditableTootip = () => (
  <Typography variant="caption">
    You don't have <b>permissions</b> to save this workload.
  </Typography>
);

interface Props {
  selectedWorkload: components["schemas"]["UtilsWorkload"];
  selectedPolicy: Policy | undefined;
}

const SaveButtonContainer = memo(({ selectedWorkload, selectedPolicy }: Props) => {
  const isEditable = selectedWorkload?.isEditable;
  const isReadyOnlyFrontEnd = useIsReadyOnlyFrontEnd();
  const isScaleopsSystemWorkload = selectedWorkload?.namespace === "scaleops-system";
  const updateRecommendation = useUpdateRecommendation();

  const { updateOverriddenWorkloads } = useWorkloadsContext();

  const handleSave = () => {
    if (selectedPolicy?.name == selectedPolicy?.displayName) {
      updateOverriddenWorkloads({
        ids: [selectedWorkload.id],
        props: {
          policyName: selectedPolicy?.name,
        },
      });
      updateRecommendation.mutate({
        name: `${selectedWorkload.type}-${selectedWorkload.workloadName}`.toLowerCase(),
        namespace: selectedWorkload.namespace,
        policyName: String(selectedPolicy?.displayName),
      });
    }
  };

  let tooltipContent = undefined;
  switch (true) {
    case isReadyOnlyFrontEnd:
      tooltipContent = <YouHaveReadOnlyAccess />;
      break;
    case !isEditable:
      tooltipContent = <NotEditableTootip />;
      break;
    case isScaleopsSystemWorkload:
        tooltipContent = <Typography variant="caption">Can't modify scaleops-system workloads.</Typography>;
        break;
  }

  return (
    <Tooltip title={tooltipContent} maxWidth={500} disabled={!tooltipContent}>
      <Button label="Save policy" onClick={handleSave} disabled={isReadyOnlyFrontEnd || !isEditable || isScaleopsSystemWorkload} />
    </Tooltip>
  );
});

export default SaveButtonContainer;
