import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { getDashboardByNamespace, SetAdmissionOptions, SetAdmissionOptionsParams } from "../../../api/fetcher";

export const unEvictableWorkloadsInfo = (
  <>
    <b>Unevictable workloads</b> have <b>annotation</b>, or are associated with <b>PDB</b> (Pod Disruption Budgets) with
    a configuration preventing <b>Cluster Autoscaler</b> from <b>scale down nodes</b>.
    {/* <br />
    <br />
    <b>Bin pack pods</b> have <b>no owners</b>, therefore the cluster autoscaler cannot evict them. */}
  </>
);

export const blockedNodesInfo = (
  <>
    <b>Blocked nodes</b> have at least one <b>unevictable workloads</b>.
  </>
);

export const useUpdateAdmissionOptions = (onSuccess?: () => void, onError?: () => void) => {
  const queryClient = useQueryClient();
  const setAdmissionOptions = SetAdmissionOptions();

  return useMutation((params: SetAdmissionOptionsParams) => setAdmissionOptions.queryFn(params), {
    onError: (error: Error) => {
      toast.error("An error occurred while updating workload");
      console.log(`Error: ${error.message}`);
      onError && onError();
    },
    onSuccess: () => {
      queryClient.invalidateQueries([setAdmissionOptions.queryKey]).catch((error) => console.log(error));
      toast.success("Saved successfully!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1000,
      });
      onSuccess && onSuccess();
    },
    onSettled: () => {
      queryClient.invalidateQueries([getDashboardByNamespace().queryKey]);
    },
  });
};
