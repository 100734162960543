import { useMutation, useQueryClient } from "@tanstack/react-query";
import { getDashboardByNamespace, GetDashboardByNamespaceResponse, updateAutomation } from "../../../../api/fetcher";
import { useWorkloadsContext } from "../../../../WorkloadsContext";
import { toast } from "react-toastify";
import useFilterQueryParams, {
  FilterParamObject,
} from "../../../../components/WorkloadStatusByNamespace/useFilterQueryParams";
import dayjs from "dayjs";

type mutationProps = {
  id: string;
  namespace: string;
  workloadName: string;
  workloadType: string;
  state: boolean;
};

const useUpdateAutomation = (onSuccess: (isAutomated: boolean) => void) => {
  const queryClient = useQueryClient();
  const automation = updateAutomation();

  const { updateOverriddenWorkloads } = useWorkloadsContext();
  const filterQueryParams: FilterParamObject = useFilterQueryParams();
  const dashboardByNamespace = getDashboardByNamespace();
  return useMutation((params: mutationProps) => automation.queryFn(params), {
    onMutate: (params: mutationProps) => {
      const now = dayjs.utc().toString();
      queryClient.setQueryData(
        [dashboardByNamespace.queryKey, filterQueryParams],
        (oldData: GetDashboardByNamespaceResponse | undefined) => {
          return {
            ...oldData,
            workloads: oldData?.workloads?.map((wl) => {
              if (wl.id === params.id) {
                return { ...wl, auto: params.state, automationTime: now };
              }
              return wl;
            }),
          };
        }
      );

      updateOverriddenWorkloads({
        ids: [params.id],
        props: {
          auto: params.state,
          automationTime: now,
        },
      });
    },
    onError: (error: Error, params: mutationProps) => {
      queryClient.setQueryData(
        [dashboardByNamespace.queryKey, filterQueryParams],
        (oldData: GetDashboardByNamespaceResponse | undefined) => {
          return {
            ...oldData,
            workloads: oldData?.workloads?.map((wl) => {
              if (wl.id === params.id) {
                return { ...wl, auto: !params.state };
              }
              return wl;
            }),
          };
        }
      );

      updateOverriddenWorkloads({
        ids: [params.id],
        props: {
          auto: !params.state,
        },
      });

      toast.error(`Failed to automate workload`, {
        position: toast.POSITION.TOP_CENTER,
      });

      console.log(`Error updating ${params.id} automation error log:`, error);
    },
    onSuccess: (data, variables) => {
      onSuccess(variables.state);
    },
    onSettled: () => {
      queryClient.invalidateQueries([dashboardByNamespace.queryKey, filterQueryParams]);
    },
  });
};

export default useUpdateAutomation;
