import { BooleanParam, StringParam, useQueryParam, withDefault } from "use-query-params";
import { SCALEOPS_COLORS } from "../../../../colors";
import CustomFilterChip from "../../../../components/CustomFilterChip";
import FilterChip, { FilterType } from "../../../../components/FilterChip";
import Input from "../../../../components/Input";
import LabelsAndAnnotationsFilter from "../../../../components/WorkloadsAggregation/LabelsAndAnnotationsFilter";
import ColumnsFilter from "../ColumnsFilter";
import { Field } from "../utils";
import ClearAggregationFilters from "./ClearAggregationFilters";
import { FilterOptions, useMultiClusterUseQueryParam } from "./hooks/useAggregationFilters";

interface Props {
  selectedColumns: (string | undefined)[];
  setSelectedColumns: (selectedColumns: (string | undefined)[]) => void;
  columnOptions: string[];
}

const AggregationFilters = ({ selectedColumns, setSelectedColumns, columnOptions }: Props) => {
  const [searchTerm, setSearchTerm] = useQueryParam("searchTerms", StringParam);
  const [groupByNamespaceParams, setGroupByNamespaceParams] = useQueryParam(
    FilterOptions.GroupByNamespace,
    withDefault(BooleanParam, true)
  );
  const [multiClusterParams, setMultiClusterParams] = useMultiClusterUseQueryParam();

  return (
    <div className="w-full flex flex-col gap-2">
      <div className="flex gap-2 items-center">
        <Input
          className="-mt-1 min-w-[150px]"
          borderColor={SCALEOPS_COLORS.black}
          placeholder="search..."
          onChange={(e) => {
            setSearchTerm(e.target.value);
          }}
          value={searchTerm ?? ""}
        />
        <CustomFilterChip
          label="clusters"
          onClick={() => {
            setMultiClusterParams((s) => !s);
          }}
          selected={!!multiClusterParams}
        />
        <CustomFilterChip
          label="namespaces"
          onClick={() => {
            setGroupByNamespaceParams((s) => !s);
          }}
          selected={!!groupByNamespaceParams}
        />
        <LabelsAndAnnotationsFilter
          labelsQueryKey={FilterOptions.GroupByLabels}
          annotationsQueryKey={FilterOptions.GroupByAnnotations}
        />
        <ColumnsFilter
          selectedColumns={selectedColumns}
          setSelectedColumns={setSelectedColumns}
          options={columnOptions}
          customNames={{
            [Field.replicas]: "Number of Pods",
          }}
        />
      </div>
      <div className="flex gap-2 flex-wrap">
        <FilterChip
          label="clusters"
          filterType={FilterType.BOOLEAN}
          queryParam={FilterOptions.groupByCluster}
          isSelectedByDefault
        />
        <FilterChip
          label="namespaces"
          filterType={FilterType.BOOLEAN}
          queryParam={FilterOptions.GroupByNamespace}
          isSelectedByDefault
        />
        <FilterChip label="labels" filterType={FilterType.ARRAY} queryParam={FilterOptions.GroupByLabels} />
        <FilterChip label="annotations" filterType={FilterType.ARRAY} queryParam={FilterOptions.GroupByAnnotations} />
      </div>
      <ClearAggregationFilters />
    </div>
  );
};

export default AggregationFilters;
