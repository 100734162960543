import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import clsx from "clsx";
import { StringParam, useQueryParam } from "use-query-params";
import InfoIcon from "../../../Icons/InfoIcon";
import { GetPolicyTuningConfigParams, GetPolicyTuningConfigParamsResponse } from "../../../api/fetcher";
import { components } from "../../../api/schema";
import Tooltip from "../../../components/Tooltip";
import { numberFormatter } from "../../../utils/formatterUtils";
import { SELECTED_CONTAINER_KEY } from "./ContainerFilter";
import { METRIC_BOX_CLASS_NAME } from "./utils";

const FLEX_CLASS_NAME = "flex flex-col gap-3";

const { queryKey, queryFn } = GetPolicyTuningConfigParams();
interface CappingBadgeProps {
  cpuCappingConfig?: components["schemas"]["UtilsPolicyTuningCappingConfig"];
  memoryCappingConfig?: components["schemas"]["UtilsPolicyTuningCappingConfig"];
  namespace: string;
  name: string;
  policyName: string;
}

const CappingBadge = ({ namespace, name, policyName }: CappingBadgeProps) => {
  const [selectedContainer] = useQueryParam(SELECTED_CONTAINER_KEY, StringParam);

  const { data, error } = useQuery<GetPolicyTuningConfigParamsResponse, Error>({
    queryKey: [queryKey, policyName, namespace, name, selectedContainer],
    queryFn: () =>
      queryFn({
        policyName,
        namespace,
        name,
        container: selectedContainer ? selectedContainer : undefined,
      }),
  });

  if (error) {
    console.log("Error in fetching policy tuning config params in CappingBadge.tsx: ", error);
  }

  const [hpaCapping, setHpaCapping] = useState<{
    memory?: components["schemas"]["UtilsPolicyTuningCappingConfig"];
    cpu?: components["schemas"]["UtilsPolicyTuningCappingConfig"];
  }>({});
  const [generalCapping, setGeneralCapping] = useState<{
    memory?: components["schemas"]["UtilsPolicyTuningCappingConfig"];
    cpu?: components["schemas"]["UtilsPolicyTuningCappingConfig"];
  }>({});
  const [generalCapped, setGeneralCapped] = useState<boolean>(false);
  const [hpaCapped, setHpaCapped] = useState<boolean>(false);

  useEffect(() => {
    setHpaCapping({
      cpu:
        data?.cpuPolicyTuningParams?.cappingInfo?.isCapped &&
        data.cpuPolicyTuningParams.cappingInfo.cappingSource === "hpa"
          ? data.cpuPolicyTuningParams?.cappingInfo
          : undefined,
      memory:
        data?.memoryPolicyTuningParams?.cappingInfo?.isCapped &&
        data.memoryPolicyTuningParams.cappingInfo.cappingSource === "hpa"
          ? data.memoryPolicyTuningParams?.cappingInfo
          : undefined,
    });
    setGeneralCapping({
      cpu:
        data?.cpuPolicyTuningParams?.cappingInfo?.isCapped &&
        data.cpuPolicyTuningParams.cappingInfo.cappingSource !== "hpa"
          ? data.cpuPolicyTuningParams?.cappingInfo
          : undefined,
      memory:
        data?.memoryPolicyTuningParams?.cappingInfo?.isCapped &&
        data.memoryPolicyTuningParams.cappingInfo.cappingSource !== "hpa"
          ? data.memoryPolicyTuningParams?.cappingInfo
          : undefined,
    });
  }, [data]);

  useEffect(() => {
    setHpaCapped(hpaCapping.cpu !== undefined || hpaCapping.memory !== undefined);
    setGeneralCapped(generalCapping.cpu !== undefined || generalCapping.memory !== undefined);
  }, [hpaCapping, generalCapping]);

  const cappedMessage = (capping: {
    memory?: components["schemas"]["UtilsPolicyTuningCappingConfig"];
    cpu?: components["schemas"]["UtilsPolicyTuningCappingConfig"];
  }) => {
    return (
      <>
        <div className={FLEX_CLASS_NAME}>
          {capping.cpu?.isCapped && (
            <div>
              <b>CPU:</b>
              <p>
                {capping.cpu.cappedMessage}
                {capping.cpu.cappedMessage && !capping.cpu.cappedMessage.endsWith(".") && "."}
              </p>
              <div className="mt-2">
                {capping.cpu.maxAllowed && (
                  <p>
                    <span className="underline">Upper Bound:</span>{" "}
                    {capping.cpu.maxAllowed && numberFormatter(capping.cpu.maxAllowed / 1000, false, 2)}
                  </p>
                )}
                {capping.cpu.minAllowed && (
                  <p>
                    <span className="underline">Lower Bound:</span>{" "}
                    {capping.cpu.minAllowed && numberFormatter(capping.cpu.minAllowed / 1000, false, 2)}
                  </p>
                )}
              </div>
            </div>
          )}
          {capping.memory?.isCapped && (
            <div>
              <b>Memory:</b>
              <p>
                {capping.memory.cappedMessage}
                {capping.memory.cappedMessage && !capping.memory.cappedMessage.endsWith(".") && "."}
              </p>
              <div className="mt-2">
                {capping.memory.maxAllowed && (
                  <p>
                    <span className="underline">Upper Bound:</span>{" "}
                    {capping.memory.maxAllowed && numberFormatter(capping.memory.maxAllowed / 1024 / 1024, false, 2)}
                    MiB
                  </p>
                )}
                {capping.memory.minAllowed && (
                  <p>
                    <span className="underline">Lower Bound:</span>{" "}
                    {capping.memory.minAllowed && numberFormatter(capping.memory.minAllowed / 1024 / 1024, false, 2)}
                    MiB
                  </p>
                )}
              </div>
            </div>
          )}
        </div>
      </>
    );
  };

  return (
    <div>
      {(generalCapped || hpaCapped) && (
        <Tooltip
          title={
            <div className={FLEX_CLASS_NAME}>
              {generalCapped && cappedMessage(generalCapping)}
              {hpaCapped && cappedMessage(hpaCapping)}
            </div>
          }
          placement="left"
        >
          <div className={clsx(METRIC_BOX_CLASS_NAME, "mt-2 flex items-center justify-center gap-1")}>
            Request Capped <InfoIcon width={14} height={14} />
          </div>
        </Tooltip>
      )}
    </div>
  );
};

export default CappingBadge;
