import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { Typography } from "@mui/material";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import DollarIcon from "../Icons/DollarIcon";
import { useMainContext } from "../MainContext";
import MainMenuItem from "./MainMenuItem";

const ARROW_ICON_SIZE = 18;

const TO_URL = {
  cost: "/multiCluster/cost",
  network: "/multiCluster/network",
};

const URL_ARRAY_VALUES = Object.values(TO_URL);

const ReportsMenu = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { currentCluster } = useMainContext();
  const [isOpen, setIsOpen] = useState(false);
  const [isInnerItemsSelected, setIsInnerItemsSelected] = useState(false);

  useEffect(() => {
    const isInnerItemsSelectedValue = URL_ARRAY_VALUES.some((url) => location.pathname.startsWith(url));
    setIsInnerItemsSelected(isInnerItemsSelectedValue);
    isInnerItemsSelectedValue && setIsOpen(isInnerItemsSelectedValue);
  }, [location.pathname]);

  return (
    <div
      className={clsx("flex flex-col", {
        "gap-2": isOpen,
      })}
    >
      <Typography
        onClick={() => {
          const isUrlIncluded = URL_ARRAY_VALUES.some((url) => location.pathname.startsWith(url));
          if (isUrlIncluded || isOpen) {
            setIsOpen(!isOpen);
          } else {
            navigate(`${TO_URL.cost}${currentCluster ? `?currentClusterURLParam=${currentCluster}` : ""}`);
          }
        }}
        className={clsx("flex px-4 py-1 rounded-lg cursor-pointer items-center")}
        variant="body2"
      >
        <div
          className={clsx("grow flex justify-start items-center gap-2", {
            underline: isInnerItemsSelected,
          })}
        >
          <DollarIcon width={17} height={17} />
          Cost Report
        </div>
        <div>
          {isOpen ? (
            <KeyboardArrowUp
              sx={{
                width: ARROW_ICON_SIZE,
                height: ARROW_ICON_SIZE,
              }}
            />
          ) : (
            <KeyboardArrowDown
              sx={{
                width: ARROW_ICON_SIZE,
                height: ARROW_ICON_SIZE,
              }}
            />
          )}
        </div>
      </Typography>
      <div
        className="ml-[18px] border-l border-text-lightBlack pl-4"
        style={{
          maxHeight: isOpen ? "100vh" : 0,
          overflow: "hidden",
          transition: isOpen ? "max-height 0.2s ease-in" : undefined,
        }}
      >
        <MainMenuItem title="Compute" to={TO_URL.cost} variant="caption" />
        <MainMenuItem title="Network" to={TO_URL.network} variant="caption" />
      </div>
    </div>
  );
};

export default ReportsMenu;
