import { Typography } from "@mui/material";
import ReadOnlyIcon from "../Icons/ReadOnlyIcon";
import clsx from "clsx";

interface Props {
  className?: string;
  chipDesign?: boolean;
}

const YouHaveReadOnlyAccess = ({ className, chipDesign }: Props) => (
  <div
    className={clsx(className, "flex items-center gap-2", {
      "border border-black rounded-full w-fit px-2 py-1": chipDesign,
    })}
  >
    <ReadOnlyIcon />
    <Typography variant="caption">
      you have <b>read-only</b> access.
    </Typography>
  </div>
);

export default YouHaveReadOnlyAccess;
