import { TooltipTrigger } from "./useFreezeTooltip";
import { FREEZE_KEY, FrozenTooltipType } from "./utils";

interface Props {
  tooltipTrigger: TooltipTrigger | undefined;
  frozenTooltipType?: FrozenTooltipType;
}

const FreezeTooltipWarning = ({ tooltipTrigger, frozenTooltipType = FrozenTooltipType.Disabled }: Props) => {
  if (frozenTooltipType === FrozenTooltipType.Disabled) {
    return null;
  }

  return (
    <p className="text-[10px] mt-4 p-2 rounded border border-border bg-guideline-lighterPurple">
      {!tooltipTrigger || tooltipTrigger === TooltipTrigger.Hover ? (
        <>
          Press <b>'{FREEZE_KEY.toUpperCase()}' to freeze</b> the tooltip,
        </>
      ) : (
        <>
          Press <b>'{FREEZE_KEY.toUpperCase()}' to un-freeze</b> the tooltip,
        </>
      )}
      <br />
      {frozenTooltipType === FrozenTooltipType.FrozenAndClickable && (
        <>
          Click to explore a specific <b>workload</b>.
        </>
      )}
    </p>
  );
};

export default FreezeTooltipWarning;
