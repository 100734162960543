import dayjs from "dayjs";
import { TabOptions, OverviewChartColors } from "./overviewUtils";
import { Typography } from "@mui/material";

interface CustomTooltipProps {
  active?: boolean;
  payload?: {
    value: string | number | undefined;
    payload: {
      allocatable: number | string;
      waste: number | string;
      request: number | string;
      usage: number | string;
      recommended: number | string;
      timestampAggregators?: number;
      timestamps?: string;
      date?: string;
      fromDate?: string;
      toDate?: string;
    };
  }[];
  selectedTabOption: TabOptions;
  showWaste?: boolean;
  showRecommended?: boolean;
  showAllocatable?: boolean;
}

const CustomOverviewTooltip = ({
  active,
  payload,
  selectedTabOption,
  showWaste,
  showRecommended,
  showAllocatable,
}: CustomTooltipProps) => {
  if (active && payload && payload.length) {
    const { allocatable, waste, request, usage, timestampAggregators, timestamps, date, fromDate, toDate } =
      payload[0].payload;

    let displayDate: string | React.ReactNode = "";

    switch (true) {
      case selectedTabOption === TabOptions.Month && date && date.length > 0:
        displayDate = (
          <>
            {fromDate?.replace(/-/g, "/")} - {toDate?.replace(/-/g, "/")}
          </>
        );
        break;
      case selectedTabOption !== TabOptions.Day && date && date.length > 0:
        displayDate = String(date).replace(/-/g, "/");
        break;
      case selectedTabOption === TabOptions.Day && !date:
        displayDate = String(timestampAggregators);
        break;
      case selectedTabOption !== TabOptions.Day && !date:
        displayDate = "";
        break;
      default:
        displayDate = dayjs(timestamps).format("YYYY/MM/DD HH:mm");
    }

    return (
      <div className="bg-guideline-lightGray text-black py-4 px-10 flex flex-col rounded-md gap-1">
        <Typography variant="body2">{displayDate}</Typography>
        {showAllocatable && (
          <Typography
            variant="body2"
            style={{
              color: OverviewChartColors.Allocatable,
            }}
          >
            Allocatable: {allocatable}
          </Typography>
        )}
        {showRecommended && (
          <Typography
            variant="body2"
            style={{
              color: OverviewChartColors.Recommended,
            }}
          >
            Recommended: {payload[0].payload.recommended}
          </Typography>
        )}
        <Typography
          variant="body2"
          style={{
            color: OverviewChartColors.Request,
          }}
        >
          Request: {request}
        </Typography>
        {showWaste && (
          <Typography
            variant="body2"
            style={{
              color: OverviewChartColors.Waste,
            }}
          >
            Waste: {waste}
          </Typography>
        )}
        <Typography
          variant="body2"
          style={{
            color: OverviewChartColors.Usage,
          }}
        >
          Usage: {usage}
        </Typography>
      </div>
    );
  }

  return null;
};

export default CustomOverviewTooltip;
