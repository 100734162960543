import clsx from "clsx";
import { Input as MUIInput, Typography } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Tooltip from "./Tooltip";
import { memo } from "react";

type Props = {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  value?: string | number | undefined;
  label?: string;
  info?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  placeholder?: string;
  borderColor?: string;
  height?: string;
  dataTestId?: string;
  fontSize?: string;
  type?: "text" | "number" | "password";
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
};

const Input = memo(
  ({
    label,
    onChange,
    onBlur,
    value,
    info,
    className,
    disabled,
    placeholder,
    borderColor = "#c4c4c4",
    height = "34px",
    dataTestId,
    fontSize,
    type = "text",
    onKeyPress,
  }: Props) => {
    return (
      <div
        className={clsx(className, "flex flex-col gap-1 w-fit relative", {
          "opacity-29": disabled,
        })}
      >
        <Typography variant="body2" className="text-text-lightBlack">
          {label}
          {info && (
            <Tooltip title={info}>
              <InfoOutlinedIcon style={{ width: 14 }} />
            </Tooltip>
          )}
        </Typography>
        <MUIInput
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          placeholder={placeholder}
          type={type}
          disabled={disabled}
          data-testid={dataTestId}
          sx={{
            fontSize,
            height,
            border: `1px solid ${borderColor}`,
            "&:before, &:after": {
              borderBottom: "none",
            },
            "&:hover:not(.Mui-disabled):before": {
              borderBottom: "none",
            },
            padding: "0 10px",
            borderRadius: "4px",

            "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
              "-webkit-appearance": "none",
              margin: 0,
            },
            "&.Mui-disabled:before": {
              borderBottom: "none",
            },
          }}
          onKeyUp={onKeyPress}
        />
      </div>
    );
  }
);

export default Input;
