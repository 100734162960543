import { Typography } from "@mui/material";
import { NavLink } from "react-router-dom";
import InfoIcon from "../../../Icons/InfoIcon";
import MultipleCubes from "../../../Icons/MultipleCubes";
import ActiveChip, { ActiveChipVariant } from "../../ActiveChip";
import Button, { BUTTON_VARIANTS } from "../../Button";
import Tooltip from "../../Tooltip";
import useWindowSize from "../../useWindowSize";
import NotSupportingBinPackingPoliciesLinks from "./NotSupportingBinPackingPoliciesLinks";
import TotalAutomatedPieChart from "./TotalAutomatedPieChart";
import { unEvictableWorkloadsInfo } from "./utils";

interface Props {
  totalOptimizedPodsWithUnEvictableWorkloads: number;
  totalUnOptimizedPodsWithUnEvictableWorkloads: number;
  numberOfUnevictableWorkloads: number;
  notSupportingBinPackingPolicies: string[] | undefined;
  link: string;
}

const AutomateUevictableWorkloads = ({
  totalOptimizedPodsWithUnEvictableWorkloads,
  totalUnOptimizedPodsWithUnEvictableWorkloads,
  numberOfUnevictableWorkloads,
  notSupportingBinPackingPolicies,
  link,
}: Props) => {
  const size = useWindowSize();
  return (
    <div className="w-full flex flex-col gap-6">
      <div className="flex gap-10 items-center">
        <div className="w-[5.625rem] h-[5.625rem] rounded-full bg-primary-purpleBlue flex justify-center items-center">
          <MultipleCubes width={65} height={65} className="text-white" />
        </div>
        <div>
          <Tooltip title={unEvictableWorkloadsInfo} className="w-max flex items-center gap-1" maxWidth={500}>
            <Typography variant="h6" fontWeight={700}>
              Automate uevictable workloads
            </Typography>
            <InfoIcon width={14} height={14} />
          </Tooltip>
          <Typography variant="body2" className="text-text-lightBlack">
            Review and automate your unevictable and unhealthy pods
          </Typography>
        </div>
      </div>
      <div className="grow flex relative w-full justify-center h-[152px]">
        <div className="grow flex flex-col justify-center gap-2.5">
          <div className="flex items-center gap-2">
            <Tooltip
              title={
                <NotSupportingBinPackingPoliciesLinks
                  notSupportingBinPackingPolicies={notSupportingBinPackingPolicies}
                />
              }
              className="w-max flex justify-center"
              maxWidth={600}
            >
              <NavLink to={link}>
                <div className="relative">
                  <Button
                    label="EXPLORE WORKLOADS"
                    variant={BUTTON_VARIANTS.mediumDarkPurple}
                    onClick={() => {
                      return;
                    }}
                    fontSize={14}
                  />
                  {numberOfUnevictableWorkloads > 0 && (
                    <ActiveChip value={numberOfUnevictableWorkloads} variant={ActiveChipVariant.Purple} />
                  )}
                </div>
              </NavLink>
            </Tooltip>
          </div>
          <Typography variant="body2" className="text-text-lightBlack w-[200px]">
            Click to explore <b>unevictable</b> and <b>unoptimized</b> workloads
          </Typography>
        </div>
        <div className="bg-strongBorder h-full w-[1px] mx-12" />
        <div className="grow">
          <TotalAutomatedPieChart
            automatedLabel="optimized"
            unAutomatedLabel="un-optimized"
            title="Optimized"
            subtitle={
              <>
                pods with unevictable
                <br />
                worklaods
              </>
            }
            totalAutomated={totalOptimizedPodsWithUnEvictableWorkloads}
            totalUnAutomated={totalUnOptimizedPodsWithUnEvictableWorkloads}
            showAutomatedAndUnAutomatedLabels={!!(size.width && size.width > 1560)}
          />
        </div>
      </div>
    </div>
  );
};

export default AutomateUevictableWorkloads;
