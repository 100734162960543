import { Typography } from "@mui/material";
import clsx from "clsx";
import Tooltip from "../../../components/Tooltip";
import { METRIC_BOX_CLASS_NAME } from "./utils";
import CreativeInfoIcon from "../../../Icons/CreativeInfoIcon";
import { components } from "../../../api/schema";
import { camelCaseToSnakeSpace } from "../../../utils/formatterUtils";

interface Props {
  predictionMetadata?: components["schemas"]["UtilsPredictionMetadata"];
  optimizationGap?: string | null;
}

const PredictableBadge = ({ predictionMetadata, optimizationGap }: Props) => {
  return (
    <>
      <Tooltip
        title={
          <Typography variant="caption">
            <b>HPA optimization type</b>
            <p className="italic">{predictionMetadata?.predictable}</p>
            {predictionMetadata?.predictable &&
            predictionMetadata?.predictionErrorScore &&
            predictionMetadata?.predictionErrorScore >= 0
              ? "Min replicas are calculated by policy percentile "
              : "Min replicas are calculated by max value "}
          </Typography>
        }
        placement="left"
        maxWidth={400}
      >
        <div className={clsx(METRIC_BOX_CLASS_NAME, "mt-2 flex items-center justify-center gap-1")}>
          HPA Optimization Type
          <div className="w-[12px]">
            <CreativeInfoIcon width={16} height={12} />
          </div>
        </div>
      </Tooltip>
      <Tooltip
        title={
          <Typography variant="caption">
            <b>Workload predictability</b>
            <p className="italic">{predictionMetadata?.predictable}</p>
            {predictionMetadata?.predictable &&
            predictionMetadata?.predictionErrorScore &&
            predictionMetadata?.predictionErrorScore >= 0 ? (
              <p className="italic pt-3">{`Workload is Predictable based on its periodical behavior`}</p>
            ) : (
              "Workload is Unpredictable "
            )}
          </Typography>
        }
        placement="left"
        maxWidth={400}
      >
        <div className={clsx(METRIC_BOX_CLASS_NAME, "mt-2 flex items-center justify-center gap-1")}>
          {predictionMetadata?.predictable ? "Predictable" : "Unpredictable"}
          <div className="w-[12px]">
            <CreativeInfoIcon width={16} height={12} />
          </div>
        </div>
      </Tooltip>
      {/* <Tooltip
        title={
          <Typography variant="caption">
            <b>Workload stable trigger</b>
            <p className="italic">{predictionMetadata?.idleStable}</p>
            {predictionMetadata?.idleStable
              ? "Workload is Stable based on its trigger values "
              : "Workload is Unstable "}
          </Typography>
        }
        placement="left"
        maxWidth={400}
      >
        <div className={clsx(METRIC_BOX_CLASS_NAME, "mt-2 flex items-center justify-center gap-1")}>
          {predictionMetadata?.idleStable ? "Stable" : "Unstable"}{" "}
          <div className="w-[12px]">
            <CreativeInfoIcon width={16} height={12} />
          </div>
        </div>
      </Tooltip> */}
      {/* <Tooltip
        title={
          <Typography variant="caption">
            <b>Workload max value recorded</b>
            <p className="italic">{!!predictionMetadata?.maxValueMinReplicas}</p>
            {`Workload max recorded value replicas are ${predictionMetadata?.maxValueMinReplicas ?? "N/A "}`}
          </Typography>
        }
        placement="left"
        maxWidth={400}
      >
        <div className={clsx(METRIC_BOX_CLASS_NAME, "mt-2 flex items-center justify-center gap-1")}>
          Max Value Replicas
          <div className="w-[12px]">
            <CreativeInfoIcon width={16} height={12} />
          </div>
        </div>
      </Tooltip> */}
      {optimizationGap ? (
        <Tooltip
          title={
            <Typography variant="caption">
              <b>Optimization gap</b>
              <p className="italic">HPA optimization could not be achieved</p>
              {`Workload has optimization gap due to: ${camelCaseToSnakeSpace(optimizationGap)}`}
            </Typography>
          }
          placement="left"
          maxWidth={400}
        >
          <div className={clsx(METRIC_BOX_CLASS_NAME, "mt-2 flex items-center justify-center gap-1")}>
            Optimization gap
            <div className="w-[12px]">
              <CreativeInfoIcon width={16} height={12} />
            </div>
          </div>
        </Tooltip>
      ) : (
        <></>
      )}
    </>
  );
};

export default PredictableBadge;
