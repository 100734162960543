import clsx from "clsx";
import { toast } from "react-toastify";
import DuplicateIcon from "../Icons/DuplicateIcon";
import { TOAST_SETTINGS } from "../pages/Roles/mutations/utils";

const ICON_WIDTH = 12;

interface Props {
  color: string;
  value: React.ReactNode;
  label: React.ReactNode;
  isDashed?: boolean;
  className?: string;
  enableCopyTextOnClick?: string;
}

const ChartTooltipElement = ({ label, value, color, isDashed, className, enableCopyTextOnClick }: Props) => (
  <div className={clsx(className, "flex gap-2 items-center text-[10px]")}>
    <div className="relative h-[4px] w-[24px]">
      <div className="h-full w-full rounded-lg" style={{ background: color }} />
      {isDashed && (
        <div className="absolute w-full top-0 right-0 flex justify-evenly">
          {[...new Array<number>(3)].map((_, index) => (
            <div key={index} className="h-[4px] w-[10%] bg-white" />
          ))}
        </div>
      )}
    </div>
    {enableCopyTextOnClick && (
      <DuplicateIcon
        className="cursor-pointer"
        width={ICON_WIDTH}
        height={ICON_WIDTH}
        onClick={(e) => {
          if (enableCopyTextOnClick && label) {
            navigator.clipboard.writeText(enableCopyTextOnClick);
            e.stopPropagation();
            e.preventDefault();
            toast.success("Value copied to clipboard", TOAST_SETTINGS);
          }
        }}
      />
    )}
    <div className="flex">
      {label}: {value}
    </div>
  </div>
);

export default ChartTooltipElement;
