import { useState } from "react";
import { GetAnalyticsGraphResponseParams } from "../../../../api/fetcher";
import { DateType } from "../utils";
import CustomLegend from "../../../../components/CustomLegend";
import { GraphLine, graphLineNames, LineStyle, ResourceType } from "./graphUtils";
import ResourceGraph from "./ResourceGraph";

const UnknownSpan = () => <span className="text-[12px] text-strongBorder">(unknown owner)</span>;

const regularLineElements = {
  [graphLineNames[GraphLine.Usage]]: GraphLine.Usage,
  [graphLineNames[GraphLine.Requests]]: GraphLine.Requests,
  [graphLineNames[GraphLine.RequestsOrigin]]: GraphLine.RequestsOrigin,
  [graphLineNames[GraphLine.Recommendation]]: GraphLine.Recommendation,
  [graphLineNames[GraphLine.Allocatable]]: GraphLine.Allocatable,
};

const regularIncludedGraphLines = [
  GraphLine.Usage,
  GraphLine.Requests,
  GraphLine.RequestsOrigin,
  GraphLine.Recommendation,
  GraphLine.Allocatable,
];

const unknownLineElements = {
  [graphLineNames[GraphLine.UsageUnknown]]: GraphLine.UsageUnknown,
  [graphLineNames[GraphLine.RequestsUnknown]]: GraphLine.RequestsUnknown,
};

const unknownIncludedGraphLines = [GraphLine.UsageUnknown, GraphLine.RequestsUnknown];

interface Props {
  queryParams: GetAnalyticsGraphResponseParams;
  setDate: (date: DateType) => void;
  displayNameFormatter?: (key: string) => string;
  isMulticluster?: boolean;
  showUnknownGraphs?: boolean;
}

const ResourcesOverTimeChartsContainer = ({
  queryParams,
  setDate,
  displayNameFormatter,
  isMulticluster,
  showUnknownGraphs,
}: Props) => {
  const [hasError, setHasError] = useState<boolean>(false);
  const [selectedGraphLines, setSelectedGraphLines] = useState<GraphLine[]>(
    showUnknownGraphs
      ? [GraphLine.UsageUnknown, GraphLine.RequestsUnknown]
      : [GraphLine.Requests, GraphLine.Recommendation, GraphLine.Allocatable]
  );

  const legendComponentStyle: { [key: string]: { color: string } } = {};

  Object.values(GraphLine).forEach((key) => {
    legendComponentStyle[key] = {
      color: LineStyle[key].stroke,
    };
  });

  if (hasError) return null;

  return (
    <div className="px-4 pb-8 bg-white w-[100%] border border-border rounded">
      <div className="flex">
        <ResourceGraph
          queryParams={queryParams}
          selectedGraphLines={selectedGraphLines}
          resourceType={ResourceType.CPU}
          setDate={setDate}
          displayNameFormatter={displayNameFormatter}
          isMulticluster={isMulticluster}
          includedGraphLines={showUnknownGraphs ? unknownIncludedGraphLines : regularIncludedGraphLines}
          types={showUnknownGraphs ? ["cpuUsageUnknownOwner", "cpuRequestsUnknownOwner"] : undefined}
          LabelSpan={showUnknownGraphs ? <UnknownSpan /> : null}
          setHasError={setHasError}
        />
        <ResourceGraph
          queryParams={queryParams}
          selectedGraphLines={selectedGraphLines}
          resourceType={ResourceType.Memory}
          setDate={setDate}
          displayNameFormatter={displayNameFormatter}
          isMulticluster={isMulticluster}
          includedGraphLines={showUnknownGraphs ? unknownIncludedGraphLines : regularIncludedGraphLines}
          types={showUnknownGraphs ? ["memoryUsageUnknownOwner", "memoryRequestsUnknownOwner"] : undefined}
          LabelSpan={showUnknownGraphs ? <UnknownSpan /> : null}
          setHasError={setHasError}
        />
      </div>
      {!hasError && (
        <div className="">
          <CustomLegend<GraphLine>
            displayNameFormatter={displayNameFormatter}
            selectedChartComponents={selectedGraphLines}
            setSelectedChartComponents={setSelectedGraphLines}
            componentStyle={legendComponentStyle}
            ChartComponents={showUnknownGraphs ? unknownLineElements : regularLineElements}
            className="-mt-1"
            fontWeight={500}
          />
        </div>
      )}
    </div>
  );
};

export default ResourcesOverTimeChartsContainer;
