import clsx from "clsx";

interface Props {
  children: React.ReactNode;
  right?: boolean;
  left?: boolean;
  top?: boolean;
  bottom?: boolean;
  length?: number;
  thickness?: number;
  color?: string;
  className?: string;
  wrapperClassName?: string;
}

const PartialBorders = ({
  right = false,
  left = false,
  top = false,
  bottom = false,
  children,
  length = 80,
  thickness = 1,
  className = "bg-strongBorder",
  wrapperClassName,
}: Props) => {
  return (
    <div className={clsx(wrapperClassName, "flex flex-col items-center justify-center h-[100%] w-full")}>
      {top && <div className={className} style={{ width: `${length}%`, height: thickness }} id="topBorder" />}
      <div className="flex items-center justify-center w-full h-[100%]">
        {left && <div className={className} style={{ width: thickness, height: `${length}%` }} id="leftBorder" />}
        <div className="w-full">{children}</div>
        {right && <div className={className} style={{ width: thickness, height: `${length}%` }} id="rightBorder" />}
      </div>
      {bottom && <div className={className} style={{ width: `${length}%`, height: thickness }} id="bottomBorder" />}
    </div>
  );
};

export default PartialBorders;
