import { Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { SCALEOPS_COLORS } from "../../../../colors";
import { customNumberFormatter } from "../../../../utils/formatterUtils";
import { TOOLTIP_WRAPPER_CLASS_NAME } from "../../../../utils/styleUtils";
import CustomTooltip from "./CustomTooltip";
import { CHART_COLORS, ChartDataEntry } from "./utils";

const MAX_ELEMENTS = 20;
const MAX_X_AXIS_CHARACTERS = 25;

type XAxisTooltip = { value: string | number; coordinate: number; index: number } | null;

const AxisTooltip = ({ tooltip }: { tooltip: XAxisTooltip }) => {
  if (tooltip?.index === undefined) return null;

  return (
    <div
      style={{
        position: "absolute",
        left: tooltip.coordinate - 50,
        bottom: 50,
        width: 100,
        height: "fit-content",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflow: "visible",
        whiteSpace: "nowrap",
        zIndex: 999,
      }}
    >
      <div className={TOOLTIP_WRAPPER_CLASS_NAME}>{tooltip.value}</div>
    </div>
  );
};
interface Props {
  data: ChartDataEntry[];
  selectedChartNodes: string[];
}

const UnEvictableBarChart = ({ data, selectedChartNodes }: Props) => {
  const hasNoData = !data || data.length === 0;
  const numberOfXTickLines = data.length || undefined;

  const [numberOfUniqueNames, setNumberOfUniqueNames] = useState<number>(0);
  const [numberOfAdditionalElements, setNumberOfAdditionalElements] = useState<number>(0);
  const [tooltip, setTooltip] = useState<XAxisTooltip>(null);

  const maxValue = data.reduce((acc, curr) => {
    const currMax = Math.max(
      ...Object.values(curr)
        .filter((value) => value !== undefined)
        .map((value) => Number(value))
    );
    return currMax > acc ? currMax : acc;
  }, 0);

  useEffect(() => {
    setNumberOfUniqueNames(new Set(data.map((d) => d.name)).size);

    data.length && data.length > MAX_ELEMENTS
      ? setNumberOfAdditionalElements(data.length - MAX_ELEMENTS)
      : setNumberOfAdditionalElements(0);
  }, [data]);

  return (
    <div className="w-full h-full relative">
      <ResponsiveContainer height="100%" width="100%">
        <BarChart
          data={data.slice(0, MAX_ELEMENTS)}
          margin={{
            top: 20,
            right: 30,
            left: -20,
            bottom: 5,
          }}
        >
          <Tooltip content={<CustomTooltip />} cursor={{ fill: SCALEOPS_COLORS.background.ghostWhite }} />
          <XAxis
            dataKey="name"
            style={{ fontSize: "x-small" }}
            strokeWidth={2}
            tickFormatter={(value: string) => {
              if (hasNoData) return "";
              const displayValue = String(value);
              let maxCharacters = MAX_X_AXIS_CHARACTERS - numberOfUniqueNames;
              maxCharacters = maxCharacters < 1 || !maxCharacters ? MAX_X_AXIS_CHARACTERS : maxCharacters;
              return displayValue.length > maxCharacters
                ? `...${displayValue.toLowerCase().substring(displayValue.length - maxCharacters)}`
                : value.toLocaleLowerCase();
            }}
            tickLine={hasNoData ? false : undefined}
            tickCount={numberOfXTickLines}
            onMouseEnter={(params) => {
              setTooltip(params as unknown as XAxisTooltip);
            }}
            onMouseLeave={() => setTooltip(null)}
          />
          <YAxis
            style={{ fontSize: "x-small" }}
            strokeWidth={2}
            tickFormatter={(tick: number) => {
              return hasNoData || tick === undefined ? "" : String(customNumberFormatter(tick));
            }}
            tickLine={hasNoData ? false : undefined}
            tickCount={maxValue > 5 ? undefined : 3}
          />
          {selectedChartNodes.map((key, index) => {
            if (!selectedChartNodes.includes(key)) return null;

            let color;
            if (key in CHART_COLORS) {
              color = CHART_COLORS[key as keyof typeof CHART_COLORS];
            } else {
              color = SCALEOPS_COLORS.main.blue;
            }

            return (
              <Bar
                dataKey={key}
                stackId="a"
                fill={color}
                key={key}
                radius={index === selectedChartNodes.length - 1 ? [5, 5, 0, 0] : undefined}
              />
            );
          })}
        </BarChart>
      </ResponsiveContainer>
      <AxisTooltip tooltip={tooltip} />
      {numberOfAdditionalElements ? (
        <Typography
          variant="caption"
          fontSize={10}
          className="absolute right-[40px] bottom-[-27px] text-text-lightBlack italic"
          style={{
            zIndex: 999,
          }}
        >
          showing {MAX_ELEMENTS} of {data.length} nodes
        </Typography>
      ) : null}
    </div>
  );
};

export default UnEvictableBarChart;
