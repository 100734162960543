import numeral from "numeral";
import ChartTooltipElement from "../../../../components/ChartTooltipElement";
import ChartTooltipTime from "../../../../components/ChartTooltipTime";
import { DEFAULT_DATE_FORMAT } from "../../../../utils/formatterUtils";
import { TOOLTIP_WRAPPER_CLASS_NAME } from "../../../../utils/styleUtils";
interface CustomTooltipProps {
  active?: boolean;
  payload?: { value: string | number; name?: string }[];
  label?: string;
  color: string;
  tooltipValuePrefix?: string;
  tooltipValueSuffix?: string;
}

const CustomTooltip = ({
  active,
  payload,
  label,
  color,
  tooltipValuePrefix,
  tooltipValueSuffix,
}: CustomTooltipProps) => {
  if (active && payload && payload.length) {
    return (
      <div className={TOOLTIP_WRAPPER_CLASS_NAME}>
        {label && <ChartTooltipTime timestamp={label} timeFormat={DEFAULT_DATE_FORMAT} />}
        <ChartTooltipElement
          color={color}
          value={`${tooltipValuePrefix ?? ""}${numeral(Number(payload[0].value ?? 0)).format("0,0")}${
            tooltipValueSuffix ?? ""
          }`}
          label={payload[0].name}
        />
      </div>
    );
  }

  return null;
};

export default CustomTooltip;
