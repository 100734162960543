import { IconProps } from "./utils/utils";

const ScheduleIcon = (props: IconProps) => {
  const { height = 20, width = 20, className } = props;
  return (
    <svg height={height} width={width} className={className} fill="currentColor" viewBox="0 0 20 20" {...props}>
      <g clip-path="url(#clip0_1_85)">
        <path
          d="M12.0202 18.2164C11.8553 18.2575 11.6872 18.294 11.5203 18.3248C11.084 18.4059 10.7956 18.8255 10.8763 19.2622C10.9161 19.477 11.0381 19.6559 11.2038 19.7728C11.3745 19.8929 11.5918 19.9472 11.8132 19.906C12.012 19.8691 12.2123 19.8257 12.4088 19.7767C12.8396 19.6694 13.1021 19.233 12.9946 18.8024C12.8874 18.3714 12.4513 18.1091 12.0202 18.2164Z"
          fill="black"
        />
        <path
          d="M17.9659 7.43969C18.0222 7.60942 18.1297 7.74799 18.2655 7.84373C18.4669 7.98556 18.7304 8.03329 18.9819 7.95014C19.4035 7.81016 19.6319 7.35556 19.4924 6.93419C19.4289 6.7421 19.3584 6.54941 19.2832 6.36179C19.1183 5.94949 18.6507 5.74876 18.2382 5.91368C17.8261 6.07851 17.6253 6.54631 17.7903 6.95874C17.8534 7.11643 17.9125 7.2783 17.9659 7.43969Z"
          fill="black"
        />
        <path
          d="M14.6328 17.0685C14.491 17.1621 14.3446 17.2528 14.1971 17.3377C13.8124 17.5596 13.6806 18.0514 13.9025 18.436C13.9627 18.5406 14.0429 18.6261 14.1353 18.6914C14.3833 18.8659 14.7202 18.8921 15.0006 18.7305C15.1759 18.6294 15.3503 18.5218 15.5192 18.4101C15.8895 18.1654 15.9914 17.6666 15.7466 17.2961C15.5019 16.9255 15.0033 16.8237 14.6328 17.0685Z"
          fill="black"
        />
        <path
          d="M19.9923 9.68528C19.9748 9.24151 19.6011 8.89611 19.1572 8.91344C18.7138 8.93093 18.3682 9.30484 18.3856 9.74843C18.3922 9.91804 18.394 10.0902 18.3901 10.2596C18.3838 10.5379 18.5199 10.786 18.7315 10.9351C18.8575 11.0238 19.0104 11.0775 19.1762 11.0813C19.62 11.0911 19.9877 10.7391 19.9976 10.2952C20.002 10.0927 20.0003 9.8876 19.9923 9.68528Z"
          fill="black"
        />
        <path
          d="M17.8347 14.9534C17.4788 14.6864 16.9754 14.7589 16.7089 15.1141C16.6068 15.2501 16.4993 15.3845 16.3893 15.5139C16.1017 15.8518 16.1423 16.3593 16.4802 16.6472C16.4995 16.6635 16.5189 16.6785 16.5391 16.6926C16.8751 16.9295 17.342 16.875 17.6136 16.5563C17.745 16.4019 17.8731 16.2415 17.9951 16.079C18.2617 15.7238 18.1895 15.22 17.8347 14.9534Z"
          fill="black"
        />
        <path
          d="M19.0165 12.047C18.5928 11.9142 18.1416 12.15 18.0089 12.5737C17.9581 12.7356 17.9017 12.8982 17.8409 13.0575C17.7074 13.4081 17.8354 13.794 18.1286 14.0007C18.1823 14.0385 18.2416 14.0705 18.3059 14.0949C18.7208 14.2532 19.1853 14.045 19.3434 13.63C19.4156 13.4406 19.4826 13.2471 19.5432 13.0547C19.6758 12.6309 19.4401 12.1799 19.0165 12.047Z"
          fill="black"
        />
        <path
          d="M8.51241 18.332C7.79358 18.2029 7.10402 17.9832 6.45016 17.6766C6.44242 17.6726 6.4355 17.668 6.42737 17.6643C6.27329 17.5918 6.11946 17.5141 5.97036 17.4329C5.96985 17.4323 5.9689 17.4319 5.96808 17.4316C5.69452 17.2809 5.42754 17.1142 5.16817 16.9317C1.38605 14.2674 0.476682 9.02289 3.14108 5.24081C3.72045 4.41872 4.42157 3.73286 5.20321 3.18927C5.21284 3.18256 5.22247 3.1759 5.23201 3.16915C7.9864 1.2713 11.7264 1.14339 14.6511 3.09361L14.023 4.00122C13.8483 4.25384 13.9558 4.43793 14.2615 4.41042L16.9901 4.16614C17.2962 4.13862 17.4793 3.87383 17.3969 3.57822L16.6642 0.938235C16.5822 0.642276 16.3722 0.60685 16.1974 0.85943L15.5677 1.76919C13.4214 0.328389 10.8471 -0.221009 8.2919 0.222069C8.03455 0.266609 7.78081 0.321124 7.53055 0.38458C7.52862 0.384924 7.52707 0.385139 7.52552 0.385483C7.51585 0.387848 7.50605 0.390986 7.49663 0.393609C5.29328 0.95943 3.37088 2.24451 1.99912 4.07805C1.98756 4.09176 1.97565 4.10517 1.96473 4.12009C1.91912 4.18153 1.87384 4.24438 1.82952 4.30724C1.75703 4.41025 1.68558 4.51584 1.61722 4.62142C1.60867 4.63415 1.60213 4.64709 1.59465 4.65995C0.462666 6.41407 -0.0833353 8.44202 0.0103018 10.5066C0.0105168 10.5134 0.0101299 10.5202 0.0103018 10.5272C0.0193732 10.7289 0.0353663 10.9333 0.0570774 11.1346C0.0582382 11.1476 0.0611187 11.1599 0.0633113 11.1729C0.0857533 11.3753 0.113913 11.5782 0.14921 11.781C0.507937 13.8506 1.48421 15.7129 2.9471 17.1619C2.9505 17.1653 2.95402 17.169 2.95746 17.1725C2.95867 17.1739 2.96 17.1746 2.96116 17.1758C3.3542 17.5635 3.78171 17.922 4.24203 18.2462C5.44667 19.0951 6.78807 19.6562 8.22866 19.9147C8.66576 19.9932 9.0833 19.7022 9.16172 19.2654C9.2401 18.8282 8.94938 18.4103 8.51241 18.332Z"
          fill="black"
        />
        <path
          d="M9.50661 3.57687C9.14706 3.57687 8.85583 3.86836 8.85583 4.22739V10.7083L14.7832 13.7724C14.8786 13.8218 14.9807 13.8451 15.0812 13.8451C15.3167 13.8451 15.5441 13.7169 15.6597 13.4932C15.8245 13.1739 15.6998 12.7817 15.3805 12.6169L10.1567 9.91617V4.22739C10.1566 3.86836 9.86572 3.57687 9.50661 3.57687Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_85">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ScheduleIcon;
