import { Typography } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import RightArrowWithFill from "../../Icons/RightArrowWithFill";
import WarningIcon from "../../Icons/WarningIcon";
import { components } from "../../api/schema";
import { LIGHT_PURPLE_BLUE, MAIN_YELLOW } from "../../colors";
import {
  BlockingOwner,
  NodeReasons,
  PodReasons,
  noPlaceToMovePodsReasonsMessages,
  nodeReasonsMessages,
  podReasonsMessages,
} from "./Utils";

const ArrowLine = ({ content }: { content: React.ReactNode }) => (
  <div className="flex gap-2.5 items-start">
    <div className="h-[20px] flex items-center">
      <RightArrowWithFill width={14} height={14} fill={LIGHT_PURPLE_BLUE} outline="none" />
    </div>
    <Typography variant="caption">{content}</Typography>
  </div>
);

const DotLine = ({ content }: { content: React.ReactNode }) => (
  <div className="flex gap-2.5 items-start">
    <div className="h-[20px] flex items-center">
      <span className="bg-primary-lightPurpleBlue rounded-full w-1.5 h-1.5" />
    </div>
    <Typography variant="caption">{content}</Typography>
  </div>
);

interface NotScalingDownTooltipContentProps {
  params: GridRenderCellParams<string, components["schemas"]["UtilsNodeStats"], string>;
  nodeGroups: components["schemas"]["UtilsNodeGroupInfo"][] | undefined;
  icon?: React.ReactNode;
}

export const NotScalingDownTooltipContent = ({
  params,
  nodeGroups,
  icon = <WarningIcon fill={MAIN_YELLOW} />,
}: NotScalingDownTooltipContentProps) => {
  const blockingOwner = params.row.blockingOwner as keyof typeof BlockingOwner;
  let reason: React.ReactNode;

  const message = nodeReasonsMessages[params?.row?.limitScaleDownReason as unknown as NodeReasons];

  switch (blockingOwner) {
    case BlockingOwner.Node:
      reason =
        params.row.limitScaleDownReason &&
        Object.values(NodeReasons).includes(params.row.limitScaleDownReason as unknown as NodeReasons)
          ? message.renderFunction
            ? message.renderFunction(params.row.nodeGroup, nodeGroups, params.row.limitScaleDownMessage)
            : message.jsx
          : undefined;
      break;
    case BlockingOwner.Pod:
      reason =
        params.row.limitScaleDownReason &&
        Object.values(PodReasons).includes(params.row.limitScaleDownReason as unknown as PodReasons)
          ? podReasonsMessages[params.row.limitScaleDownReason as unknown as PodReasons](
              params.row.blockingName,
              params.row.limitScaleDownMessage
            )
          : undefined;
      break;
    default:
      break;
  }

  if (!reason) {
    reason = params.row.blockingMessage;
  }

  const hasNoPlaceToMovePods = params.row.limitScaleDownReason === PodReasons.NoPlaceToMovePods;

  let noPlaceToMovePodsReasonsMessagesArray: (string | undefined)[] | undefined;

  if (hasNoPlaceToMovePods) {
    noPlaceToMovePodsReasonsMessagesArray =
      params?.row?.nodeReasonDetails &&
      Object.keys(params?.row?.nodeReasonDetails).map((key) => {
        if (params.row.nodeReasonDetails) return params.row.nodeReasonDetails[key]?.message;
      });
  }

  return (
    <div className="flex flex-col gap-5">
      <div className="bg-primary-lightPurpleBlue  h-[30px] p-[5px] flex gap-[10px] justify-left items-center w-full rounded-sm">
        {icon}
        <Typography variant="caption" fontWeight={700}>
          Scale down blockers
        </Typography>
      </div>
      <Typography variant="caption">
        <p className="underline">Reasons:</p>
        <div>
          <ArrowLine content={reason} />
        </div>
        {hasNoPlaceToMovePods && (
          <div className="pl-6">
            {noPlaceToMovePodsReasonsMessagesArray?.map((reason, key) => {
              const messageObject = Object.values(noPlaceToMovePodsReasonsMessages).find((message) =>
                reason?.includes(message.string)
              );

              if (!messageObject && reason) return <DotLine key={key} content={reason} />;
              if (!reason || !messageObject) return null;

              const splitContent = reason.split(messageObject.string);

              const content = (
                <>
                  {splitContent[0]}
                  {messageObject.jsx}
                  {splitContent[1]}
                </>
              );

              return <DotLine key={key} content={content} />;
            })}
          </div>
        )}
      </Typography>
    </div>
  );
};

export default NotScalingDownTooltipContent;
