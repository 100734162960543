import { RequestAndUsageByTimestampKey } from "../../componentUtils/overviewUtils";
import { DIFF_COLOR } from "./Styles";

export enum ChartComponents {
  Usage = "usage",
  Request = "request",
  Waste = "waste",
  Allocatable = "allocatable",
  Recommended = "recommended",
  RecommendedAllocatable = "recommendedAllocatable",
  WasteFromAllocatableToRequest = "wasteFromAllocatableToRequest",
  WasteFromRequestToRecommended = "wasteFromRequestToRecommended",
  WasteFromAllocatableToRecommendedAllocatable = "wasteFromAllocatableToRecommendedAllocatable",
}

export const STROKE_WIDTH = 2;
export const FBATR_OPACITY = 0.4;

export const pattern = (
  <pattern id="color-stripe" width="8" height="8" patternUnits="userSpaceOnUse" patternTransform="rotate(45)">
    <rect width="2" height="8" fill={DIFF_COLOR} />
  </pattern>
);

export const getDeducedData = (data: RequestAndUsageByTimestampKey[]) => {
  const latestState = {
    request: 0,
    recommended: 0,
    allocatable: 0,
    recommendedAllocatable: 0,
    usage: 0,
  };

  const parsedData = data.map((item) => {
    const request = Number.isNaN(item.request) || !item.request ? latestState.request : item.request;
    const recommended =
      Number.isNaN(item.recommended) || !item.recommended ? latestState.recommended : item.recommended;
    const allocatable =
      Number.isNaN(item.allocatable) || !item.allocatable ? latestState.allocatable : item.allocatable;
    const recommendedAllocatable =
      Number.isNaN(item.recommendedAllocatable) || !item.recommendedAllocatable
        ? latestState.recommendedAllocatable
        : item.recommendedAllocatable;
    const usage = Number.isNaN(item.usage) || !item.usage ? latestState.usage : item.usage;

    latestState.request = request;
    latestState.recommended = recommended;
    latestState.allocatable = allocatable;
    latestState.recommendedAllocatable = recommendedAllocatable;
    latestState.usage = usage;

    return {
      request,
      recommended,
      allocatable,
      recommendedAllocatable,
      usage,
      timestampAggregators: item.timestampAggregators,
      timestamps: item.timestamps,
    };
  });
  return parsedData;
};
