import { useEffect, useState } from "react";

interface Props<T> {
  localStorageKey: string;
  defaultValue?: T;
  valueFormatter?: (value: string) => T;
}

const useStateWithLocalStorage = <T extends string | undefined | null | number>({
  localStorageKey,
  defaultValue,
  valueFormatter,
}: Props<T>) => {
  const [value, setValue] = useState<T | undefined>(defaultValue);

  useEffect(() => {
    const localStorageValue = localStorage.getItem(localStorageKey);
    if (localStorageValue !== null) {
      setValue(valueFormatter ? valueFormatter(localStorageValue) : (localStorageValue as T));
    }
  }, []);

  useEffect(() => {
    if (value !== undefined && value !== null) {
      localStorage.setItem(localStorageKey, String(value));
    }
  }, [value]);

  return [value, setValue] as [T, (value: T) => void];
};

export default useStateWithLocalStorage;
