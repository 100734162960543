import { DialogContent, DialogTitle, Drawer, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import yaml from "js-yaml";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import * as Yup from "yup";
import AdvancedSettingsIcon from "../../Icons/AdvancedSettingsIcon";
import BasicSettingsIcon from "../../Icons/BasicSettingsIcon";
import CustomIcon from "../../Icons/CustomIcon";
import LockIcon from "../../Icons/LockIcon";
import ReplicasIcon from "../../Icons/ReplicasIcon";
import { components } from "../../api/schema";
import Button from "../../components/Button";
import SideMenu, { MenuItem } from "../../components/SideMenu/SideMenu";
import Tooltip from "../../components/Tooltip";
import { numberFormatter } from "../../utils/formatterUtils";
import * as policyUtils from "../../utils/policyUtils";
import { hasManagedLimit, isBuiltInPolicy } from "../../utils/policyUtils";
import {
  cronExpressionValidation,
  nullablePositiveLimitValidation,
  nullablePositivePercentileValidation,
} from "../../utils/yupUtils";
import AllowRolloutPeriod from "./EditFormSections/AllowRolloutPeriod";
import ApplyingRecommendationSettings from "./EditFormSections/ApplyingRecommendationSettings";
import ChangeThresholds from "./EditFormSections/ChangeThresholds";
import HistogramLimitPercentileConfiguration from "./EditFormSections/HistogramLimitPercentileConfiguration";
import HistogramRequestPercentileConfiguration from "./EditFormSections/HistogramRequestPercentileConfiguration";
import HistoryWindow from "./EditFormSections/HistoryWindow";
import LimitsHeadroom from "./EditFormSections/LimitsHeadroom";
import ManageHPA from "./EditFormSections/ManageHPA";
import RequestsHeadroom from "./EditFormSections/RequestsHeadroom";
import RequiredWindowCoverage from "./EditFormSections/RequiredWindowCoverage";
import RolloutSettings from "./EditFormSections/RolloutSettings";
// import StabilizationScaleDownWindow from "./EditFormSections/StabilizationScaleDownWindow";
import ZeroDowntimeRollout from "./EditFormSections/ZeroDowntimeRollout";
import useSavePolicy from "./mutations/useSavePolicy";
import useUpdatePolicy from "./mutations/useUpdatePolicy";
// import AutoHealingSettings from "./EditFormSections/AutoHealingSettings";
import { StringParam, useQueryParam, withDefault } from "use-query-params";
import AutomationIcon from "../../Icons/AutomationIcon";
import LimitIcon from "../../Icons/LimitIcon";
import MainDownloadIcon from "../../Icons/MainDownloadIcon";
import useHpaOptimizationEnabled from "../../components/WorkloadStatusByNamespace/useHpaOptimizationEnabled";
import { exportStringAsFile, FileExtension } from "../../utils/exportUtils";
import useIsReadyOnlyFrontEnd from "../../utils/useIsReadyOnlyFrontEnd";
import AutoHealingSwitch from "./EditFormSections/AutoHealingSwitch";
import BinPackUnEvictable from "./EditFormSections/BinPackUnEvictable";
import CpuInteger from "./EditFormSections/CpuInteger";
import FastReaction from "./EditFormSections/FastReaction";
import KeepRequest from "./EditFormSections/KeepRequest";
import LimitsStrategy, { LimitStrategyItems } from "./EditFormSections/LimitsStrategy";
import MaximumResourceBoundaries from "./EditFormSections/MaximumResourceBoundaries";
import MinimumResourceBoundaries from "./EditFormSections/MinimumResourceBoundaries";
import EnableHPA from "./EditFormSections/Replicas/EnableHPA";
import GeneralWorkloadOptimization from "./EditFormSections/Replicas/GeneralWorkloadOptimization";
import MinReplicasAllowed from "./EditFormSections/Replicas/MinReplicasAllowed";
//import OptimizationStrategy from "./EditFormSections/Replicas/OptimizationStrategy";
import PredictableWorkloadOptimization from "./EditFormSections/Replicas/PredictableWorkloadOptimization";
import RequiredWindowPercentage from "./EditFormSections/Replicas/RequiredWindowPercentage";
import SetLimits from "./EditFormSections/SetLimits";
import SkipRolloutUponAutomation from "./EditFormSections/SkipRolloutUponAutomation";
import { enableFilterByUrlParam, FilterByUrlParam } from "../../utils/queryParams";

export const TOP_CHIP_CLASS_NAME =
  "bg-mainMenuSelected rounded-full px-2 text-black flex items-center justify-center gap-1";

const INITIAL_MENU_ITEM = 0;

enum MenuItemType {
  REQUEST = "Request",
  LIMIT = "Limit",
  AUTOMATION = "Automation",
  SCHEDULING = "Scheduling",
  HPA = "HPA",
}

const menuItems: MenuItem[] = [
  {
    id: MenuItemType.REQUEST,
    title: MenuItemType.REQUEST,
    icon: <BasicSettingsIcon />,
  },
  {
    id: MenuItemType.LIMIT,
    title: MenuItemType.LIMIT,
    icon: <LimitIcon className="mt-[-3px]" />,
  },
  {
    id: MenuItemType.AUTOMATION,
    title: MenuItemType.AUTOMATION,
    icon: <AutomationIcon />,
  },
  {
    id: MenuItemType.SCHEDULING,
    title: MenuItemType.SCHEDULING,
    icon: <AdvancedSettingsIcon />,
  },
  {
    id: MenuItemType.HPA,
    title: MenuItemType.HPA,
    icon: <ReplicasIcon />,
  },
];

interface Props {
  rowPolicyData: components["schemas"]["V1alpha1Policy"] | undefined;
  defaultPolicy?: components["schemas"]["V1alpha1Policy"] | undefined;
  isEditPolicyDrawerOpen: boolean;
  setIsEditPolicyDrawerOpen: Dispatch<SetStateAction<boolean>>;
  isNewPolicy?: boolean;
  onSavingNewPolicy?: () => void;
}

const ENABLE_HPA_RECOMMENDATION = enableFilterByUrlParam(FilterByUrlParam.ENABLE_HPA_RECOMMENDATION);

const EditPolicyDrawer = ({
  rowPolicyData,
  defaultPolicy,
  isEditPolicyDrawerOpen,
  setIsEditPolicyDrawerOpen,
  isNewPolicy,
  onSavingNewPolicy,
}: Props) => {
  const isReadyOnlyFrontEnd = useIsReadyOnlyFrontEnd();
  const updatePolicy = useUpdatePolicy();
  const savePolicy = useSavePolicy();
  const hpaOptimizationEnabled = useHpaOptimizationEnabled();

  const [selectedMenuItem, setSelectedMenuItem] = useQueryParam(
    "selectedMenuItem",
    withDefault(StringParam, menuItems[INITIAL_MENU_ITEM].id)
  );

  const [yamlString, setYamlString] = useState<string>("");

  useEffect(() => {
    const rowPolicyDataYaml = rowPolicyData;
    if (rowPolicyDataYaml?.metadata?.managedFields) {
      delete rowPolicyDataYaml.metadata.managedFields;
    }
    const yamlString = yaml.dump(rowPolicyDataYaml);
    setYamlString(yamlString);
  }, [rowPolicyData]);

  const handleClose = () => {
    setIsEditPolicyDrawerOpen(false);
    setSelectedMenuItem(menuItems[INITIAL_MENU_ITEM].id);
  };

  const isCustomizedPolicy = !isBuiltInPolicy(rowPolicyData) && !isReadyOnlyFrontEnd;

  const hasManagedLimitsOption = useMemo(() => hasManagedLimit(rowPolicyData), [rowPolicyData]);

  return (
    <Drawer
      onClose={handleClose}
      open={!!rowPolicyData && isEditPolicyDrawerOpen}
      style={{ zIndex: 1300 }}
      className="overflow-y-auto flex flex-col top-0"
      anchor="right"
    >
      <DialogTitle className="bg-text-lightBlack text-white min-h-[56px] flex">
        <Typography variant="body2" className="flex items-center gap-1 grow">
          {!isCustomizedPolicy && <LockIcon />}
          <div className="flex items-center">{rowPolicyData?.metadata?.name}</div>
          {isBuiltInPolicy(rowPolicyData) && (
            <Typography variant="caption" className={TOP_CHIP_CLASS_NAME}>
              <CustomIcon width={14} height={14} />
              Built in policy
            </Typography>
          )}
        </Typography>
        <Tooltip
          title={
            <>
              <b>Click to export</b> policy YAML file.
            </>
          }
        >
          <Typography
            variant="caption"
            onClick={() =>
              exportStringAsFile({
                fileString: yamlString,
                fileName: rowPolicyData?.metadata?.name ?? "policy",
                extension: FileExtension.YAML,
              })
            }
            className="cursor-pointer hover:text-strongBorder flex justify-center items-center gap-1 borders border border-white hover:border-strongBorder  rounded-full py-1 px-2"
          >
            <p className="text-[10px]">Export YAML</p>
            <MainDownloadIcon width={12} height={12} />
          </Typography>
        </Tooltip>
      </DialogTitle>
      <DialogContent
        className="flex"
        sx={{
          padding: 0,
          overflowY: "hidden",
        }}
      >
        <SideMenu
          selectedMenuItem={selectedMenuItem}
          setSelectedMenuItem={setSelectedMenuItem}
          menuItems={menuItems.filter((item) =>
            ENABLE_HPA_RECOMMENDATION || hpaOptimizationEnabled ? true : item.id !== MenuItemType.HPA
          )}
          className="h-vh-100"
          noPaddingForFirstLevel={true}
        />
        <div className="h-full flex flex-col">
          <div className="flex-grow w-[700px]">
            <Formik
              initialValues={{
                minReplicas: policyUtils.policyRolloutMinReplicas(rowPolicyData),

                deploymentRecommendationType: policyUtils.policyUpdateDeploymentMode(rowPolicyData),
                deploymentConfigRecommendationType: policyUtils.policyUpdateDeploymentConfigMode(rowPolicyData),
                daemonSetRecommendationType: policyUtils.policyUpdateDaemonSetMode(rowPolicyData),
                jobRecommendationType: policyUtils.policyUpdateJobMode(rowPolicyData),
                statefulSetRecommendationType: policyUtils.policyUpdateStatefulSetMode(rowPolicyData),
                familyRecommendationType: policyUtils.policyUpdateFamilySetMode(rowPolicyData),

                keepMemoryRequest: policyUtils.getPolicyKeepMemoryRequest(rowPolicyData),
                keepCpuRequest: policyUtils.getPolicyKeepCpuRequest(rowPolicyData),
                cpuInteger: policyUtils.getPolicyCpuInteger(rowPolicyData),

                maxUnavailablePodsPercentage: policyUtils.policyMaxUnavailablePodsPercentage(rowPolicyData),
                changeThresholdScaleDown: policyUtils.policyMinChangeScaleDownCpu(rowPolicyData) * 100,
                changeThresholdScaleUp: policyUtils.policyMinChangeScaleUpCpu(rowPolicyData) * 100,
                windowCoverage: policyUtils.policyRequiredHistoryCoveragePercentage(rowPolicyData),
                allowRollingUpdate: policyUtils.policyAllowRollingUpdate(rowPolicyData),
                stabilizationScaleDownWindow:
                  Math.round((policyUtils.policyStabilizationScaleDownWindow(rowPolicyData) / 60 / 60) * 100) / 100,
                stabilizationScaleUpWindow:
                  Math.round((policyUtils.policyStabilizationScaleUpWindow(rowPolicyData) / 60 / 60) * 100) / 100,
                evictionScheduleScaleDown: policyUtils.policyEvictionScheduleScaleDown(rowPolicyData),
                evictionScheduleScaleUp: policyUtils.policyEvictionScheduleScaleUp(rowPolicyData),
                jvmEnvXmsMxs: policyUtils.policyJvmEnvXmsMxs(rowPolicyData),
                nodeCapping: policyUtils.policyNodeCapping(rowPolicyData),
                historyWindowCpu: policyUtils.policyHistoryWindow(rowPolicyData, "cpu"),
                historyWindowMemory: policyUtils.policyHistoryWindow(rowPolicyData, "memory"),
                limitStrategyCpu: policyUtils.policyCpuLimitStrategy(rowPolicyData),
                limitStrategyMemory: policyUtils.policyMemoryLimitStrategy(rowPolicyData),
                cpuHeadroom: policyUtils.policyCpuHeadroom(rowPolicyData),
                memoryHeadroom: policyUtils.policyMemoryHeadroom(rowPolicyData),
                cpuLimitsNoLimit: !policyUtils.policyCpuLimitsNoLimit(rowPolicyData),
                memoryLimitsNoLimit: !policyUtils.policyMemoryLimitsNoLimit(rowPolicyData),
                cpuLimitsHeadroom: numberFormatter(policyUtils.policyCpuLimitsHeadroom(rowPolicyData)),
                memoryLimitsHeadroom: numberFormatter(policyUtils.policyMemoryLimitsHeadroom(rowPolicyData)),
                histogramCPUPercentile: policyUtils.policyCPUPercentile(rowPolicyData),
                histogramCpuLimitsPercentile: policyUtils.policyCpuLimitsPercentile(rowPolicyData),
                histogramMemoryPercentile: policyUtils.policyMemoryPercentile(rowPolicyData),
                histogramMemoryLimitsPercentile: policyUtils.policyMemoryLimitsPercentile(rowPolicyData),
                cpuMinAllowed: numberFormatter(policyUtils.policyCpuMinAllowed(rowPolicyData) ?? ""),
                cpuMaxAllowed: numberFormatter(policyUtils.policyCpuMaxAllowed(rowPolicyData) ?? ""),
                cpuLimitsSetLimitValue: numberFormatter(policyUtils.policyCpuLimitsSetLimitsValue(rowPolicyData)),
                memoryLimitsSetLimitValue: numberFormatter(policyUtils.policyMemoryLimitsSetLimitsValue(rowPolicyData)),
                memoryMinAllowed: numberFormatter(policyUtils.policyMemoryMinAllowed(rowPolicyData) ?? ""),
                memoryMaxAllowed: numberFormatter(policyUtils.policyMemoryMaxAllowed(rowPolicyData) ?? ""),
                manageHPA: policyUtils.policyManageHPA(rowPolicyData),
                keepCpuLimit: policyUtils.policyCpuKeepLimit(rowPolicyData) ?? false,
                keepMemoryLimit: policyUtils.policyMemoryKeepLimit(rowPolicyData) ?? false,
                cpuLimitsEqualsToRequest: policyUtils.policyCpuLimitsEqualsToRequest(rowPolicyData) ?? false,
                memoryLimitsEqualsToRequest: policyUtils.policyMemoryLimitsEqualsToRequest(rowPolicyData) ?? false,
                cpuCostReductionOnly: policyUtils.policyCpuCostReductionOnly(rowPolicyData) ?? false,
                memoryCostReductionOnly: policyUtils.policyMemoryCostReductionOnly(rowPolicyData) ?? false,
                binPackUnEvictablePods: policyUtils.policyBinPackUnEvictablePods(rowPolicyData) ?? false,
                advancedBinPackCasLimitations: policyUtils.policyAdvancedBinPackCasLimitations(rowPolicyData) ?? false,
                hpaUtilTriggerRightSizeCap: policyUtils.policyHpaRightSizeCapping(rowPolicyData) ?? false,
                autoHealingSettingsCpuMinStep: policyUtils.policyAutoHealingSettingsCpuMinStep(rowPolicyData) ?? 0.15,
                autoHealingSettingsMemoryMinStep:
                  policyUtils.policyAutoHealingSettingsMemoryMinStep(rowPolicyData) ?? 0.15,
                autoHealingSettingsCpuMultiplier:
                  policyUtils.policyAutoHealingSettingsCpuMultiplier(rowPolicyData) ?? 1.2,
                autoHealingSettingsMemoryMultiplier:
                  policyUtils.policyAutoHealingSettingsMemoryMultiplier(rowPolicyData) ?? 1.2,
                autoHealingEnabled:
                  policyUtils.policyAutoHealingSettingsEnabled(rowPolicyData) ||
                  policyUtils.policyAutoHealingSettingsEnabled(rowPolicyData) === undefined,
                skipRolloutUponAutomation: policyUtils.getSkipRolloutUponAutomation(rowPolicyData) ?? false,
                fastReaction: policyUtils.getFastReaction(rowPolicyData) ?? false,
                hasHPAenabled: policyUtils.policyEnabled(rowPolicyData),
                workloadsToOptimize: policyUtils.policyOptimzedStrategy(rowPolicyData),
                percentilePercentage: policyUtils.percentilePercentage(rowPolicyData),
                replicasPercentilePercentage: policyUtils.replicasPercentilePercentage(rowPolicyData),
                hasPredictableEnabled: policyUtils.hasPredictableEnabled(rowPolicyData),
                replicasWindow: policyUtils.replicasWindow(rowPolicyData),
                predictAheadOfTimeDuration: policyUtils.predictAheadOfTimeDuration(rowPolicyData),
                requiredWindowCoverageDuration: policyUtils.requiredWindowCoverageDuration(rowPolicyData),
                hpaMinReplicas: policyUtils.hpaMinReplicas(rowPolicyData),
              }}
              validationSchema={Yup.object({
                hasHPAenabled: Yup.bool().required("Required"),
                workloadsToOptimize: Yup.string().required("Required"),
                percentilePercentage: nullablePositivePercentileValidation,
                predictAheadOfTimeDuration: Yup.string().required("Required"),
                requiredWindowCoverageDuration: Yup.string().required("Required"),
                hasPredictableEnabled: Yup.bool().required("Required"),
                replicasWindow: Yup.string().required("Required"),
                replicasPercentilePercentage: nullablePositivePercentileValidation,
                hpaMinReplicas: Yup.number()
                  .min(0, "Must be 0 or greater")
                  .integer("Must be an interger")
                  .required("Required"),
                minReplicas: Yup.number().min(0, "Must be 0 or greater").required("Required"),

                deploymentRecommendationType: Yup.string().required("Required"),
                deploymentConfigRecommendationType: Yup.string().required("Required"),
                daemonSetRecommendationType: Yup.string().required("Required"),
                jobRecommendationType: Yup.string().required("Required"),
                statefulSetRecommendationType: Yup.string().required("Required"),
                familyRecommendationType: Yup.string().required("Required"),

                keepMemoryRequest: Yup.bool().required("Required"),
                keepCpuRequest: Yup.bool().required("Required"),
                cpuInteger: Yup.bool().required("Required"),

                maxUnavailablePodsPercentage: nullablePositivePercentileValidation,
                changeThresholdScaleDown: nullablePositivePercentileValidation,
                changeThresholdScaleUp: nullablePositivePercentileValidation,
                windowCoverage: nullablePositivePercentileValidation,
                allowRollingUpdate: Yup.bool().required("Required"),
                stabilizationScaleDownWindow: nullablePositivePercentileValidation,
                evictionScheduleScaleDown: cronExpressionValidation,
                evictionScheduleScaleUp: cronExpressionValidation,
                jvmEnvXmsMxs: Yup.bool().required("Required"),
                nodeCapping: Yup.bool().required("Required"),
                historyWindowCpu: Yup.string().required("Required"),
                historyWindowMemory: Yup.string().required("Required"),
                limitStrategyCpu: Yup.string().required("Required"),
                limitStrategyMemory: Yup.string().required("Required"),
                cpuHeadroom: nullablePositiveLimitValidation,
                memoryHeadroom: nullablePositiveLimitValidation,
                cpuLimitsHeadroom: nullablePositiveLimitValidation,
                memoryLimitsHeadroom: nullablePositiveLimitValidation,
                histogramCPUPercentile: nullablePositivePercentileValidation,
                histogramCpuLimitsPercentile: nullablePositivePercentileValidation,
                histogramMemoryPercentile: nullablePositivePercentileValidation,
                histogramMemoryLimitsPercentile: nullablePositivePercentileValidation,
                cpuMinAllowed: nullablePositiveLimitValidation,
                cpuMaxAllowed: nullablePositiveLimitValidation,
                memoryMinAllowed: nullablePositiveLimitValidation,
                memoryMaxAllowed: nullablePositiveLimitValidation,
                manageHPA: Yup.bool().required("Required"),
                keepCpuLimit: Yup.bool().required("Required"),
                keepMemoryLimit: Yup.bool().required("Required"),
                cpuCostReductionOnly: Yup.bool().required("Required"),
                memoryCostReductionOnly: Yup.bool().required("Required"),
                binPackUnEvictablePods: Yup.bool().required("Required"),
                advancedBinPackCasLimitations: Yup.bool().required("Required"),
                hpaUtilTriggerRightSizeCap: Yup.bool().required("Required"),
                skipRolloutUponAutomation: Yup.bool().required("Required"),
                fastReaction: Yup.bool().required("Required"),
                autoHealingEnabled: Yup.bool().required("Required"),
              })}
              onSubmit={(values) => {
                const newPolicy = { ...rowPolicyData };
                policyUtils.policyUpdateEnabled(newPolicy, values.hasHPAenabled);
                policyUtils.policyUpdateOptimizedStrategy(newPolicy, values.workloadsToOptimize);
                policyUtils.policyUpdatePercentilePercentage(newPolicy, values.percentilePercentage);
                policyUtils.policyUpdateHpaMinReplicas(newPolicy, values.hpaMinReplicas);
                policyUtils.policyUpdatePredictAheadOfTimeDuration(newPolicy, values.predictAheadOfTimeDuration);
                policyUtils.policyUpdateHasPredictableEnabled(
                  newPolicy,
                  values.hasPredictableEnabled,
                  values.hasHPAenabled
                );
                policyUtils.policyUpdateReplicasWindow(newPolicy, values.replicasWindow);
                policyUtils.policyUpdateReplicasPercentilePercentage(newPolicy, values.replicasPercentilePercentage);
                policyUtils.policyUpdateRequiredWindowCoverageDuration(
                  newPolicy,
                  values.requiredWindowCoverageDuration
                );
                policyUtils.policyUpdateRolloutMinReplicas(newPolicy, values.minReplicas);

                policyUtils.policyUpdateUpdateDaemonSetMode(newPolicy, values.daemonSetRecommendationType);
                policyUtils.policyUpdateUpdateDeploymentMode(newPolicy, values.deploymentRecommendationType);
                policyUtils.policyUpdateUpdateDeploymentConfigMode(
                  newPolicy,
                  values.deploymentConfigRecommendationType
                );
                policyUtils.policyUpdateUpdateJobMode(newPolicy, values.jobRecommendationType);
                policyUtils.policyUpdateUpdateStatefulSetMode(newPolicy, values.statefulSetRecommendationType);
                policyUtils.policyUpdateUpdateFamilySetMode(newPolicy, values.familyRecommendationType);

                policyUtils.updatePolicyKeepMemoryRequest(newPolicy, values.keepMemoryRequest);
                policyUtils.updatePolicyKeepCpuRequest(newPolicy, values.keepCpuRequest);
                policyUtils.updatePolicyCpuInteger(newPolicy, values.cpuInteger);

                policyUtils.policyUpdateMaxUnavailablePodsPercentage(newPolicy, values.maxUnavailablePodsPercentage);
                policyUtils.policyUpdateMinChangeScaleUpCpu(newPolicy, Number(values.changeThresholdScaleUp) / 100);
                policyUtils.policyUpdateMinChangeScaleUpMemory(newPolicy, Number(values.changeThresholdScaleUp) / 100);
                policyUtils.policyUpdateMinChangeScaleDownCpu(newPolicy, Number(values.changeThresholdScaleDown) / 100);
                policyUtils.policyUpdateMinChangeScaleDownMemory(
                  newPolicy,
                  Number(values.changeThresholdScaleDown) / 100
                );
                policyUtils.policyUpdateRequiredHistoryCoveragePercentage(newPolicy, values.windowCoverage);
                policyUtils.policyUpdateAllowRollingUpdate(newPolicy, values.allowRollingUpdate);

                policyUtils.policyUpdateStabilizationWindowSecondsScaleDown(
                  newPolicy,
                  Number(values.stabilizationScaleDownWindow) * 60 * 60
                );

                policyUtils.policyUpdateStabilizationWindowSecondsScaleUp(
                  newPolicy,
                  Number(values.stabilizationScaleUpWindow) * 60 * 60
                );

                policyUtils.policyUpdateEvictionScheduleScaleDown(
                  newPolicy,
                  values.evictionScheduleScaleDown.toString()
                );
                policyUtils.policyUpdateEvictionScheduleScaleUp(newPolicy, values.evictionScheduleScaleUp.toString());

                policyUtils.policyUpdateJvmEnvXms(newPolicy, values.jvmEnvXmsMxs);

                policyUtils.policyUpdateNodeCapping(newPolicy, values.nodeCapping);

                policyUtils.policyUpdateHistoryWindow(newPolicy, values.historyWindowCpu, "cpu");
                policyUtils.policyUpdateHistoryWindow(newPolicy, values.historyWindowMemory, "memory");

                policyUtils.policyUpdateCpuHeadroom(newPolicy, values.cpuHeadroom);
                policyUtils.policyUpdateMemoryHeadroom(newPolicy, values.memoryHeadroom);

                policyUtils.policyUpdateCpuLimitStrategy(
                  newPolicy,
                  values.limitStrategyCpu,
                  Number(values.cpuLimitsSetLimitValue),
                  Number(values.histogramCpuLimitsPercentile),
                  Number(values.cpuLimitsHeadroom)
                );
                policyUtils.policyUpdateMemoryLimitStrategy(
                  newPolicy,
                  values.limitStrategyMemory,
                  Number(values.memoryLimitsSetLimitValue),
                  Number(values.histogramMemoryLimitsPercentile),
                  Number(values.memoryLimitsHeadroom)
                );

                policyUtils.policyUpdateCpuPercentile(newPolicy, values.histogramCPUPercentile);
                policyUtils.policyUpdateMemoryPercentile(newPolicy, values.histogramMemoryPercentile);

                policyUtils.policyUpdateCpuMinAllowed(newPolicy, Number(values.cpuMinAllowed));
                policyUtils.policyUpdateCpuMaxAllowed(newPolicy, Number(values.cpuMaxAllowed));
                policyUtils.policyUpdateMemoryMinAllowed(newPolicy, Number(values.memoryMinAllowed));
                policyUtils.policyUpdateMemoryMaxAllowed(newPolicy, Number(values.memoryMaxAllowed));

                policyUtils.policyUpdateManageHPA(newPolicy, values.manageHPA);

                policyUtils.policyUpdateCpuCostReductionOnly(newPolicy, !!values.cpuCostReductionOnly);
                policyUtils.policyUpdateMemoryCostReductionOnly(newPolicy, !!values.memoryCostReductionOnly);

                policyUtils.policyUpdateBinPackUnEvictablePods(newPolicy, !!values.binPackUnEvictablePods);
                policyUtils.policyUpdateAdvancedBinPackCasLimitations(
                  newPolicy,
                  !!values.advancedBinPackCasLimitations
                );

                policyUtils.policyUpdateHpaRightSizeCapping(newPolicy, !!values.hpaUtilTriggerRightSizeCap);
                // Old versionto auto healing old comp
                // policyUtils.policyUpdateAutohealingSettingsCpuMinStep(
                //   newPolicy,
                //   numberFormatter(values.autoHealingSettingsCpuMinStep)
                // );
                policyUtils.policyUpdateAutoHealingSettingsEnabled(newPolicy, values.autoHealingEnabled);
                policyUtils.policyUpdateAutohealingSettingsCpuMinStep(
                  newPolicy,
                  Number(values.autoHealingSettingsCpuMinStep)
                );
                policyUtils.policyUpdateAutoHealingSettingsMemoryMinStep(
                  newPolicy,
                  Number(values.autoHealingSettingsMemoryMinStep)
                );
                policyUtils.policyUpdateAutoHealingSettingsCpuMultiplier(
                  newPolicy,
                  Number(values.autoHealingSettingsCpuMultiplier)
                );
                policyUtils.policyUpdateAutoHealingSettingsMemoryMultiplier(
                  newPolicy,
                  Number(values.autoHealingSettingsMemoryMultiplier)
                );

                policyUtils.updateSkipRolloutUponAutomation(newPolicy, !!values.skipRolloutUponAutomation);

                policyUtils.updateFastReaction(newPolicy, !!values.fastReaction);

                if (rowPolicyData) {
                  if (isNewPolicy) {
                    onSavingNewPolicy && onSavingNewPolicy();
                    savePolicy.mutate({
                      policy: newPolicy,
                    });
                  } else {
                    updatePolicy.mutate({
                      policyName: rowPolicyData?.metadata?.name ?? "",
                      policy: newPolicy,
                    });
                  }
                }
                handleClose();
              }}
            >
              {(formik) => {
                const isKeepMemoryRequest = formik.values.keepMemoryRequest;
                const isKeepCpuRequest = formik.values.keepCpuRequest;

                return (
                  <Form className="h-full">
                    <div className="flex flex-col pt-[30px]">
                      <div className="px-[30px] flex-grow flex flex-col overflow-hidden h-[calc(100vh-160px)] overflow-y-auto scrollbar-thin scrollbar-thumb-background-chipActive scrollbar-track-guideline-lightGray scrollbar-thumb-rounded-md scrollbar-track-rounded-md">
                        {selectedMenuItem === MenuItemType.REQUEST && (
                          <>
                            <HistoryWindow
                              isCustomizedPolicy={isCustomizedPolicy}
                              defaultPolicy={defaultPolicy}
                              disableCPU={isKeepCpuRequest}
                              disableMemory={isKeepMemoryRequest}
                            />
                            <RequestsHeadroom
                              isCustomizedPolicy={isCustomizedPolicy}
                              defaultPolicy={defaultPolicy}
                              disableCPU={isKeepCpuRequest}
                              disableMemory={isKeepMemoryRequest}
                            />
                            <HistogramRequestPercentileConfiguration
                              isCustomizedPolicy={isCustomizedPolicy}
                              defaultPolicy={defaultPolicy}
                              disableCPU={isKeepCpuRequest}
                              disableMemory={isKeepMemoryRequest}
                            />
                            <MinimumResourceBoundaries isCustomizedPolicy={isCustomizedPolicy} />
                            <MaximumResourceBoundaries isCustomizedPolicy={isCustomizedPolicy} />
                            <KeepRequest isCustomizedPolicy={isCustomizedPolicy} />
                            <CpuInteger />
                          </>
                        )}
                        {selectedMenuItem === MenuItemType.LIMIT && (
                          <>
                            <div className="mt-10 pb-10 border-b border-strongBorder">
                              <LimitsStrategy
                                isCustomizedPolicy={isCustomizedPolicy}
                                defaultPolicy={defaultPolicy}
                                hasManagedLimitsOption={hasManagedLimitsOption}
                              />
                              {formik.values.limitStrategyCpu === LimitStrategyItems.SetLimit ||
                              formik.values.limitStrategyMemory === LimitStrategyItems.SetLimit ? (
                                <SetLimits
                                  disableCpuOption={formik.values.limitStrategyCpu !== LimitStrategyItems.SetLimit}
                                  disableMemoryOption={
                                    formik.values.limitStrategyMemory !== LimitStrategyItems.SetLimit
                                  }
                                  isCustomizedPolicy={isCustomizedPolicy}
                                />
                              ) : undefined}
                              {formik.values.limitStrategyCpu === LimitStrategyItems.ManagedLimit ||
                              formik.values.limitStrategyMemory === LimitStrategyItems.ManagedLimit ? (
                                <>
                                  <LimitsHeadroom
                                    disableCpuOption={
                                      formik.values.limitStrategyCpu !== LimitStrategyItems.ManagedLimit
                                    }
                                    disableMemoryOption={
                                      formik.values.limitStrategyMemory !== LimitStrategyItems.ManagedLimit
                                    }
                                    isCustomizedPolicy={isCustomizedPolicy}
                                  />
                                  <HistogramLimitPercentileConfiguration
                                    disableCpuOption={
                                      formik.values.limitStrategyCpu !== LimitStrategyItems.ManagedLimit
                                    }
                                    disableMemoryOption={
                                      formik.values.limitStrategyMemory !== LimitStrategyItems.ManagedLimit
                                    }
                                    isCustomizedPolicy={isCustomizedPolicy}
                                  />
                                </>
                              ) : undefined}
                            </div>
                            {/* <ResourceBoundaries isCustomizedPolicy={isCustomizedPolicy} /> */}
                            {/* <CostReductionOnly isCustomizedPolicy={isCustomizedPolicy} /> */}
                            {/* <HpaUtilTriggerRightsizeCapping isCustomizedPolicy={isCustomizedPolicy} /> */}
                          </>
                        )}
                        {selectedMenuItem === MenuItemType.AUTOMATION && (
                          <>
                            <ApplyingRecommendationSettings
                              isCustomizedPolicy={isCustomizedPolicy}
                              defaultPolicy={defaultPolicy}
                            />
                            <ZeroDowntimeRollout isCustomizedPolicy={isCustomizedPolicy} />
                            <FastReaction isCustomizedPolicy={isCustomizedPolicy} />
                            <AutoHealingSwitch isCustomizedPolicy={isCustomizedPolicy} />
                            <RolloutSettings isCustomizedPolicy={isCustomizedPolicy} />
                            <ManageHPA isCustomizedPolicy={isCustomizedPolicy} />
                            <ChangeThresholds isCustomizedPolicy={isCustomizedPolicy} defaultPolicy={defaultPolicy} />
                            <SkipRolloutUponAutomation isCustomizedPolicy={isCustomizedPolicy} />
                            <AllowRolloutPeriod isCustomizedPolicy={isCustomizedPolicy} />
                            <RequiredWindowCoverage isCustomizedPolicy={isCustomizedPolicy} />
                          </>
                        )}
                        {selectedMenuItem === MenuItemType.SCHEDULING && (
                          <>
                            <BinPackUnEvictable isCustomizedPolicy={isCustomizedPolicy} />
                            {/* <AutoHealingSettings isCustomizedPolicy={isCustomizedPolicy} /> */}
                            {/*<StabilizationScaleDownWindow*/}
                            {/*  isCustomizedPolicy={isCustomizedPolicy}*/}
                            {/*  defaultPolicy={defaultPolicy}*/}
                            {/*/>*/}
                            {/* <AdvancedBinPackingCasLimitations isCustomizedPolicy={isCustomizedPolicy} /> */}
                            {/* <JavaApplicationsEnvironmentOverrides isCustomizedPolicy={isCustomizedPolicy} /> */}
                          </>
                        )}
                        {selectedMenuItem === MenuItemType.HPA && (
                          <>
                            <EnableHPA isCustomizedPolicy={isCustomizedPolicy} />
                            {/* <OptimizationStrategy isCustomizedPolicy={isCustomizedPolicy} /> */}
                            <GeneralWorkloadOptimization
                              isCustomizedPolicy={isCustomizedPolicy}
                              defaultPolicy={defaultPolicy}
                            />
                            <PredictableWorkloadOptimization
                              isCustomizedPolicy={isCustomizedPolicy}
                              defaultPolicy={defaultPolicy}
                            />
                            <RequiredWindowPercentage
                              isCustomizedPolicy={isCustomizedPolicy}
                              defaultPolicy={defaultPolicy}
                            />
                            <MinReplicasAllowed isCustomizedPolicy={isCustomizedPolicy} />
                          </>
                        )}
                      </div>
                      <div className="flex justify-end gap-4 p-5 drop-shadow-[0_35px_35px_rgba(0,0,0,0.25)]">
                        <Button onClick={handleClose} label="Cancel" type="button" />
                        {isCustomizedPolicy && <Button type="submit" label="Save" disabled={!formik.isValid} />}
                        {!isCustomizedPolicy && (
                          <div className="flex items-center">
                            <Tooltip
                              title={
                                <>
                                  <b>Default policies can't be edited</b>.<br />
                                  You can only edit customized policies
                                </>
                              }
                            >
                              <LockIcon width={24} height={24} />
                            </Tooltip>
                          </div>
                        )}
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </DialogContent>
    </Drawer>
  );
};

export default EditPolicyDrawer;
