import { useFormikContext } from "formik";
import { components } from "../../../../api/schema";
import FormSingleSelect, { RenderValueType } from "../../../../components/formComponents/FormSingleSelect";
import FormSwitch from "../../../../components/formComponents/FormSwitch";
import FormTitleAndDescription from "../../../../components/formComponents/FormTitleAndDescription";
import * as policyUtils from "../../../../utils/policyUtils";
import { shouldDisableDefaultPolicyInputs } from "../../utils";
import { HPA_SECTION_CLASS_NAME } from "../utils";

const options: [string, string][] = [
  ["10m", "10m"],
  ["20m", "20m"],
  ["30m", "30m"],
  ["1h", "1h"],
];
const minRreplicaPrectailOptions: [string, number][] = [
  ["60%", 60],
  ["70%", 70],
  ["80%", 80],
  ["90%", 90],
  ["100%", 100],
];

interface Props {
  isCustomizedPolicy?: boolean;
  defaultPolicy?: components["schemas"]["V1alpha1Policy"] | undefined;
}

const PredictableWorkloadOptimization = ({ isCustomizedPolicy, defaultPolicy }: Props) => {
  const { values } = useFormikContext<{
    hasHPAenabled: boolean;
    workloadsToOptimize: string;
    hasPredictableEnabled: boolean;
  }>();

  const defaultHasPredictableEnabled = isCustomizedPolicy ? policyUtils.hasPredictableEnabled(defaultPolicy) : true;
  const defaultPercentilePercentage = isCustomizedPolicy ? policyUtils.percentilePercentage(defaultPolicy) : undefined;
  const defaultPredictAheadOfTimeDuration = isCustomizedPolicy
    ? policyUtils.predictAheadOfTimeDuration(defaultPolicy)
    : undefined;

  const isPredictableSwitchDisabled =
    (!isCustomizedPolicy && shouldDisableDefaultPolicyInputs) || !values.hasHPAenabled;

  const hasPredictableContentDisabled = isPredictableSwitchDisabled || !values.hasPredictableEnabled;
  return (
    <div className={HPA_SECTION_CLASS_NAME}>
      <div className="flex py-1">
        <FormTitleAndDescription
          title="Minimum replicas for predictable workloads"
          description="When enabled, ScaleOps identifies predictable workloads and recommend optimized minimum replicas based on HPA metrics history, and ensures timely scale-up by scaling ahead of predicted peak periods."
        />
        <FormSwitch
          name="hasPredictableEnabled"
          disabled={isPredictableSwitchDisabled}
          disableChange={!isCustomizedPolicy}
          defaultValue={defaultHasPredictableEnabled.toString()}
        />
      </div>
      <div className="flex flex-wrap gap-7">
        <FormSingleSelect
          label="Min replica percentile"
          name="percentilePercentage"
          options={minRreplicaPrectailOptions}
          areOptionsDisabled={!isCustomizedPolicy}
          defaultValue={defaultPercentilePercentage}
          renderValueType={RenderValueType.Percentage}
          disabled={hasPredictableContentDisabled}
        />
        <FormSingleSelect
          label="Time to scale up ahead of predicted peak"
          className="w-[100px]"
          name="predictAheadOfTimeDuration"
          options={options}
          defaultValue={defaultPredictAheadOfTimeDuration}
          areOptionsDisabled={!isCustomizedPolicy}
          disabled={hasPredictableContentDisabled}
        />
      </div>
    </div>
  );
};

export default PredictableWorkloadOptimization;
