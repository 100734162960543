import { useEffect, useState } from "react";
import clsx from "clsx";
import { SCALEOPS_COLORS } from "../../colors";
import { DateType } from "../../pages/Analytics/AnalyticsV2/utils";
import CustomLegend from "../CustomLegend";
import Tab, { TABS_CONTAINER_CLASS_NAME } from "../Tab";
import ResourceGraph from "./ResourceGraph";
import { GraphLine, LineStyle, ResourceType, graphLineNames } from "./graphUtils";

const INCLUDED_GRAPH_LINES = [GraphLine.Requests, GraphLine.RequestsOrigin, GraphLine.Allocatable];

export enum TabOptions {
  Day = "Day",
  Week = "Week",
  Month = "Month",
}

interface Props {
  setDate?: (date: DateType) => void;
  hasSavingsDiff: boolean;
  isMultiCluster?: boolean;
}

const ResourcesOverTimeChartsContainer = ({ setDate, hasSavingsDiff, isMultiCluster }: Props) => {
  const [selectedTab, setSelectedTab] = useState<TabOptions>(TabOptions.Week);
  const [window, setWindow] = useState<string | undefined>(undefined);
  const [selectedChartComponents, setSelectedChartComponents] = useState<GraphLine[]>([
    GraphLine.Requests,
    GraphLine.RequestsOrigin,
    GraphLine.Savings,
    // GraphLine.Allocatable,
  ]);

  useEffect(() => {
    let days;
    switch (selectedTab) {
      case TabOptions.Day:
        days = 1;
        break;
      case TabOptions.Week:
        days = 7;
        break;
      case TabOptions.Month:
        days = 30;
        break;
    }
    setWindow(`${days * 24}h`);
  }, [selectedTab]);

  const legendComponentStyle: { [key: string]: { color: string } } = {};

  Object.values(GraphLine).forEach((key) => {
    legendComponentStyle[key] = {
      color: LineStyle[key].stroke,
    };
  });

  return (
    <div className="relative">
      <div className={clsx(TABS_CONTAINER_CLASS_NAME, "top-[-1px] w-full")}>
        {Object.entries(TabOptions).map(([key, value]) => {
          return (
            <Tab
              key={key}
              isSelected={selectedTab === value}
              onClick={() => {
                setSelectedTab(value);
              }}
              name={String(value)}
              dataTestId={`node-cost-chart-${key}-tab`}
              hasDefaultGrayBorders
            />
          );
        })}
      </div>
      <div className="px-4 pb-4 bg-white w-[100%] border border-border rounded rounded-tl-none mt-[34px]">
        <div className="flex">
          <ResourceGraph
            selectedGraphLines={selectedChartComponents}
            resourceType={ResourceType.CPU}
            setDate={setDate}
            includedGraphLines={INCLUDED_GRAPH_LINES}
            hasSavingsDiff={hasSavingsDiff}
            isMultiCluster={isMultiCluster}
            windowParam={window}
          />
          <ResourceGraph
            selectedGraphLines={selectedChartComponents}
            resourceType={ResourceType.Memory}
            setDate={setDate}
            includedGraphLines={INCLUDED_GRAPH_LINES}
            hasSavingsDiff={hasSavingsDiff}
            isMultiCluster={isMultiCluster}
            windowParam={window}
          />
        </div>
        <div className="flex flex-wrap gap-4 justify-center w-full mt-[-14px]">
          <CustomLegend
            selectedChartComponents={selectedChartComponents}
            setSelectedChartComponents={setSelectedChartComponents}
            componentStyle={{
              [GraphLine.Requests]: {
                color: LineStyle[GraphLine.Requests].stroke,
              },
              [GraphLine.RequestsOrigin]: {
                color: LineStyle[GraphLine.RequestsOrigin].stroke,
              },
              [GraphLine.Savings]: {
                color: SCALEOPS_COLORS.main.green,
              },
              [GraphLine.Allocatable]: {
                color: LineStyle[GraphLine.Allocatable].stroke,
              },
            }}
            // waste capital insensitive
            isDashedFnc={(key: string) => key.includes("savings")}
            ChartComponents={{
              [GraphLine.Requests]: GraphLine.Requests,
              [GraphLine.RequestsOrigin]: GraphLine.RequestsOrigin,
              [GraphLine.Savings]: GraphLine.Savings,
              [GraphLine.Allocatable]: GraphLine.Allocatable,
            }}
            renderNameFunction={(key: string) => {
              return graphLineNames[key as keyof typeof graphLineNames];
            }}
            className="-mt-1"
          />
        </div>
      </div>
    </div>
  );
};

export default ResourcesOverTimeChartsContainer;
