import clsx from "clsx";
import { Typography } from "@mui/material";
import EyeIcon from "../Icons/EyeIcon";

interface Props {
  isFiltered?: boolean;
}

const CustomColumnsFilterButton = ({ isFiltered }: Props) => (
  <div
    className={clsx("border-black h-[32px] mt-[-24px] flex items-center justify-cente p-2 border rounded-lg", {
      "bg-text-lightBlack text-white": isFiltered,
    })}
  >
    <EyeIcon width={14} height={14} className="mr-1" />
    <Typography
      variant="body2"
      style={{
        fontSize: 11,
      }}
    >
      Columns
    </Typography>
  </div>
);

export default CustomColumnsFilterButton;
