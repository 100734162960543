import { ListItemText, MenuItem, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { components } from "../../../api/schema";
import CodeSnippet, { THEME as CodeSnippetTheme } from "../../../components/CodeSnippet";
import SingleSelect, { SingleSelectSize } from "../../../components/SingleSelect";
import { GetConfig } from "../../../utils/ConfigHelper";
import { capitalizeFirstLetter } from "../../../utils/formatterUtils";
import PermissionsDocsLink from "../Permission/PermissionsDocsLink";
import { getIntegrationCommand } from "./commandsUtils";
import { AuthMethod, SSOIntegration, SSOIntegrationDocLink } from "./utils";

export const HAS_ROLES = true;

interface Props {
  currentAuthMethod: AuthMethod;
}
const SSOWithoutAuthorization = ({ currentAuthMethod }: Props) => {
  const isCurrentAuthMethod = currentAuthMethod === AuthMethod.SSO_WITHOUT_AUTHORIZATION;
  const navigate = useNavigate();
  const conf = GetConfig();

  const [selectedIntegration, setSelectedIntegration] = useState<SSOIntegration>(SSOIntegration.Okta);
  const [ssoConf, setSsoConf] = useState<components["schemas"]["TypesAuthInfo"]>({ enabled: false, provider: "none" });

  useEffect(() => {
    if (JSON.stringify(ssoConf) !== JSON.stringify(conf.ssoConf))
      setSsoConf(
        conf.ssoConf || {
          enabled: false,
          provider: "none",
        }
      );
  }, [conf]);

  useEffect(() => {
    if (!HAS_ROLES) {
      navigate("/");
    }
  }, []);

  return (
    <div>
      <CodeSnippet
        description={
          <div className="flex flex-col gap-4">
            {isCurrentAuthMethod ? (
              <>
                <Typography variant="body2" className="text-text-lightBlack">
                  You currently have <b>SSO authentication</b> configured on your cluster.
                </Typography>
                {/* <Typography variant="body2" className="text-text-lightBlack">
                  In order to <b>change your SSO method</b>, select and run the appropriate command below.
                </Typography> */}
              </>
            ) : (
              <Typography variant="body2" className="text-text-lightBlack">
                Configure <b>SSO authentication</b> on your cluster.
              </Typography>
            )}
            <div className="flex gap-2 items-center">
              <Typography variant="caption" className="text-text-lightBlack">
                SSO integration:
              </Typography>
              <SingleSelect<SSOIntegration>
                selected={selectedIntegration}
                setSelected={(option) => {
                  setSelectedIntegration(option);
                }}
                renderOptionsFunc={() =>
                  Object.values(SSOIntegration).map((option) => {
                    return (
                      <MenuItem value={option} key={option}>
                        <ListItemText
                          primary={capitalizeFirstLetter(option)}
                          sx={{
                            fontSize: "12px",
                          }}
                        />
                      </MenuItem>
                    );
                  })
                }
                size={SingleSelectSize.Small}
              />
            </div>
          </div>
        }
        theme={CodeSnippetTheme.light}
        codeSnippet={getIntegrationCommand({
          currentIntegration: currentAuthMethod,
          selectedIntegration: AuthMethod.SSO_WITHOUT_AUTHORIZATION,
          selectedSsoIntegration: selectedIntegration,
          token: conf.token,
          hideToken: true,
        })}
        className="w-full"
      />
      <PermissionsDocsLink
        relativePath={SSOIntegrationDocLink[selectedIntegration]}
        linkTitle={`${capitalizeFirstLetter(selectedIntegration)} docs`}
      />
    </div>
  );
};

export default SSOWithoutAuthorization;
