import { IconProps } from "./utils/utils";

const WarningIcon = (props: IconProps) => {
  const { height = 20, width = 20, className, fill = "none" } = props;
  return (
    <svg height={height} width={width} className={className} fill="currentColor" viewBox="0 0 20 20" {...props}>
      <g clip-path="url(#clip0_44_12)">
        <path d="M9 2L10 1.5L11 2L13 5L19 15.5L19.5 17L18 18.5H3L1 18L0.5 16.5L1 15.5L8 3.5L9 2Z" fill={fill} />
        <path
          d="M19.6809 15.4445L12.0364 2.20378C11.6113 1.46753 10.85 1.02792 9.99975 1.02792C9.14955 1.02792 8.38815 1.46753 7.96307 2.20378L0.318593 15.4444C-0.106523 16.1807 -0.106523 17.0599 0.318593 17.7962C0.74371 18.5325 1.50504 18.972 2.35527 18.972H17.6442C18.4944 18.972 19.2558 18.5324 19.6809 17.7962C20.1061 17.0599 20.1061 16.1807 19.6809 15.4445ZM18.6662 17.2104C18.4529 17.5798 18.0708 17.8004 17.6442 17.8004H2.35527C1.92863 17.8004 1.5466 17.5798 1.33332 17.2104C1.12004 16.8409 1.12004 16.3998 1.33332 16.0304L8.97787 2.78968C9.19115 2.42023 9.57318 2.19968 9.99979 2.19968C10.4263 2.19968 10.8084 2.42023 11.0217 2.78968L18.6662 16.0304C18.8795 16.3998 18.8795 16.8409 18.6662 17.2104Z"
          fill="currentColor"
        />
        <path d="M10.5858 6.87537H9.41406V12.734H10.5858V6.87537Z" fill="currentColor" />
        <path
          d="M9.99998 13.9058C9.56924 13.9058 9.21881 14.2563 9.21881 14.687C9.21881 15.1177 9.56924 15.4682 9.99998 15.4682C10.4307 15.4682 10.7812 15.1177 10.7812 14.687C10.7812 14.2563 10.4307 13.9058 9.99998 13.9058Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_44_12">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default WarningIcon;
