import { useFormikContext } from "formik";
import { components } from "../../../api/schema";
import FormSingleSelect from "../../../components/formComponents/FormSingleSelect";
import FormTitleAndDescription from "../../../components/formComponents/FormTitleAndDescription";
import { DemoMenuHoursItem, GetMenuItems } from "../../../components/formComponents/utils";
import * as policyUtils from "../../../utils/policyUtils";
import { shouldDisableDefaultPolicyInputs } from "../utils";
import { getDisabledByKeepRequestTooltipContent } from "./utils";

const getOptions = (options: [string, string][], value: string | undefined) => {
  if (!value) return options;

  const isValueIncludedInOptions = options.map((entity) => String(entity && entity[1])).includes(value);

  if (!isValueIncludedInOptions) {
    const newOption = Object.entries(DemoMenuHoursItem).filter((entity) => String(entity && entity[1]) === value)[0];
    options.push(newOption);
  }

  options.sort((a, b) => {
    const aNumber = Number(a[1].replace(/\D/g, ""));
    const bNumber = Number(b[1].replace(/\D/g, ""));
    if (aNumber < bNumber) return -1;
    if (aNumber > bNumber) return 1;
    return 0;
  });

  options = options.filter((entity) => entity);
  return options;
};
interface Props {
  isCustomizedPolicy?: boolean;
  defaultPolicy?: components["schemas"]["V1alpha1Policy"] | undefined;
  disableCPU?: boolean;
  disableMemory?: boolean;
}

const HistoryWindow = ({ isCustomizedPolicy, defaultPolicy, disableCPU, disableMemory }: Props) => {
  const { values } = useFormikContext<{
    historyWindowCpu: string;
    historyWindowMemory: string;
  }>();

  const DEFAULT_OPTIONS: [string, string][] = Object.entries(GetMenuItems()).map((entity) => [
    entity[0],
    String(entity[1]),
  ]);

  const historyWindowCpuOptions = getOptions(DEFAULT_OPTIONS, values.historyWindowCpu);
  const historyWindowMemoryOptions = getOptions(DEFAULT_OPTIONS, values.historyWindowMemory);

  const defaultHistoryWindowCpu = isCustomizedPolicy
    ? policyUtils.policyHistoryWindow(defaultPolicy, "cpu")
    : undefined;

  const defaultHistoryWindowMemory = isCustomizedPolicy
    ? policyUtils.policyHistoryWindow(defaultPolicy, "memory")
    : undefined;

  return (
    <div className="flex flex-col gap-4 py-10 border-b border-strongBorder">
      <FormTitleAndDescription
        title="History window"
        description="Define the time window for the suggested optimization."
      />

      <div className="flex gap-6">
        <FormSingleSelect
          label="CPU"
          name="historyWindowCpu"
          options={historyWindowCpuOptions}
          defaultValue={defaultHistoryWindowCpu}
          areOptionsDisabled={!isCustomizedPolicy}
          disabled={(!isCustomizedPolicy && shouldDisableDefaultPolicyInputs) || disableCPU}
          tooltipContent={disableCPU ? getDisabledByKeepRequestTooltipContent("CPU") : undefined}
        />
        <FormSingleSelect
          label="Memory"
          name="historyWindowMemory"
          defaultValue={defaultHistoryWindowMemory}
          options={historyWindowMemoryOptions}
          areOptionsDisabled={!isCustomizedPolicy}
          disabled={(!isCustomizedPolicy && shouldDisableDefaultPolicyInputs) || disableMemory}
          tooltipContent={disableMemory ? getDisabledByKeepRequestTooltipContent("memory") : undefined}
        />
      </div>
    </div>
  );
};
export default HistoryWindow;
