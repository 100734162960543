import clsx from "clsx";
import PartialBorders from "../../../components/PartialBorders";
import AutomatedPieChartWrapper from "./AutomatedPieChartWrapper";
import SingleMetricBox, { Formats } from "./SingleMetricBox";
import { CORE_ELEMENT_CLASS_NAME, DateType } from "./utils";
import useHpaOptimizationEnabled from "../../../components/WorkloadStatusByNamespace/useHpaOptimizationEnabled";

interface Props {
  date: DateType;
  isMulticluster?: boolean;
}

const twoMetricsBoxClassName = "flex flex-col justify-end items-center gap-6";

const TopMetrics = ({ date, isMulticluster }: Props) => {
  const enableHpaOptimization = useHpaOptimizationEnabled();

  return (
    <div className={clsx(CORE_ELEMENT_CLASS_NAME, "h-[250px] flex items-center")}>
      <div className="w-[25%] h-full">
        <PartialBorders right>
          <div className="w-full relative">
            <SingleMetricBox
              title="Average monthly cost"
              queryParams={{
                from: date.range ? undefined : date.from,
                to: date.range ? undefined : date.to,
                range: date.range,
                type: "costOverTime",
              }}
              numberVariant="h2"
              format={Formats.Currency}
              hasGreenNegativeTheme
              labelWrapperClassName="minWidth1660:absolute minWidth1660:top-[34px] minWidth1660:right-[20px]"
              isMulticluster={isMulticluster}
            />
          </div>
        </PartialBorders>
      </div>
      <div className="w-[25%] h-full">
        <PartialBorders right>
          <div className={twoMetricsBoxClassName}>
            <SingleMetricBox
              title="Average active savings"
              queryParams={{
                from: date.range ? undefined : date.from,
                to: date.range ? undefined : date.to,
                range: date.range,
                type: enableHpaOptimization ? "activeSavingsOverTimeWithReplicas" : "activeSavingsOverTime",
              }}
              format={Formats.Currency}
              numberVariant="h5"
              positiveNumberClassName="text-main-green"
              defaultNumberClassName="text-text-lightBlack"
              negativeNumberClassName="text-main-red"
              isMulticluster={isMulticluster}
            />
            <div className="bg-strongBorder h-[1px] w-[80%]" />
            <SingleMetricBox
              title="Average nodes"
              queryParams={{
                from: date.range ? undefined : date.from,
                to: date.range ? undefined : date.to,
                range: date.range,
                type: "numberOfNodes",
              }}
              numberVariant="h5"
              format={Formats.Number}
              hasGreenNegativeTheme
              isMulticluster={isMulticluster}
            />
          </div>
        </PartialBorders>
      </div>
      <div className="w-[25%] h-full">
        <PartialBorders right>
          <div className={twoMetricsBoxClassName}>
            <SingleMetricBox
              title="Average allocatable CPU"
              queryParams={{
                from: date.range ? undefined : date.from,
                to: date.range ? undefined : date.to,
                range: date.range,
                type: "cpuAllocatable",
              }}
              numberVariant="h5"
              format={Formats.Number}
              hasGreenNegativeTheme
              isMulticluster={isMulticluster}
            />
            <hr className="w-[80%] border-strongBorder" />
            <SingleMetricBox
              title="Average allocatable memory"
              queryParams={{
                from: date.range ? undefined : date.from,
                to: date.range ? undefined : date.to,
                range: date.range,
                type: "memoryAllocatable",
              }}
              numberVariant="h5"
              format={Formats.Memory}
              hasGreenNegativeTheme
              isMulticluster={isMulticluster}
            />
          </div>
        </PartialBorders>
      </div>
      <div className="w-[25%] h-full flex justify-center items-center relative">
        <AutomatedPieChartWrapper
          date={{
            from: date.range ? undefined : date.from,
            to: date.range ? undefined : date.to,
            range: date.range,
          }}
          isMulticluster={isMulticluster}
        />
      </div>
    </div>
  );
};

export default TopMetrics;
