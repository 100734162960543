import clsx from "clsx";
import { useEffect, useState } from "react";
import { ObjectParam, StringParam, useQueryParam, withDefault } from "use-query-params";
import { GetPolicyTuningConfigParamsResponse } from "../../../api/fetcher";
import { components } from "../../../api/schema";
import Dialog from "../../../components/Dialog";
import Tab, { TABS_CONTAINER_CLASS_NAME } from "../../../components/Tab";
import WorkloadEventPopup from "../../../components/WorkloadStatusByNamespace/WorkloadEventsPopup";
import { getDisplayWorkloadName } from "../../../components/WorkloadStatusByNamespace/WorkloadStatusByNamespace";
import useHpaOptimizationEnabled from "../../../components/WorkloadStatusByNamespace/useHpaOptimizationEnabled";
import { SELECTED_CONTAINER_KEY } from "./ContainerFilter";
import { EventPoint } from "./Diagnostics/utils";
import PolicyTuningContainer from "./PolicyTuningContainer";
import PolicyTuningDialogueTitle from "./PolicyTuningDialogueTitle";
import PolicyTuningHpa from "./PolicyTuningHpa";
import { HpaChartComponent } from "./UsageChart/UsageHpaChart";
import WorkloadAnalytics from "./WorkloadAnalytics/WorkloadAnalytics";
import WorkloadNetwork from "./WorkloadNetwork/WorkloadNetwork";
import {
  ChartComponents,
  POLICY_TUNING_DATES_URL_PARAM,
  PolicyTuningTabs,
  useViewPeriodQueryParams,
  ViewPeriodOptions,
} from "./utils";
import { enableFilterByUrlParam, FilterByUrlParam } from "../../../utils/queryParams";

export const POLICY_TUNING_SELECTED_TAB_QUERY_KEY = "policyTuningSelectedTab";
const HAS_WORKLOAD_ANALYTICS_TAB = true;
const HAS_WORKLOAD_NETWORK_TAB = true;
const MIN_TAB_CONTENT_HEIGHT = 608;

interface Props {
  selectedWorkload: components["schemas"]["UtilsWorkload"];
  setSelectedWorkload: (workload: components["schemas"]["UtilsWorkload"] | undefined) => void;
  isOpen: boolean;
  onClose: () => void;
  fetchWorkloads: () => void;
  hideWorkloadSuffix?: boolean;
  argoRolloutName?: string;
}

export interface Policy {
  name: string;
  displayName: string;
}

const PolicyTuning = ({
  selectedWorkload,
  isOpen,
  onClose,
  fetchWorkloads,
  hideWorkloadSuffix,
  argoRolloutName,

  setSelectedWorkload,
}: Props) => {
  const hpaOptimizationEnabled = useHpaOptimizationEnabled();
  const ENABLE_HPA_RECOMMENDATION =
    enableFilterByUrlParam(FilterByUrlParam.ENABLE_HPA_RECOMMENDATION) && selectedWorkload.hasHpa;
  const HAS_HPA_TAB = ENABLE_HPA_RECOMMENDATION || hpaOptimizationEnabled;

  const [, setDates] = useQueryParam(POLICY_TUNING_DATES_URL_PARAM, ObjectParam);
  const [, setSelectedContainer] = useQueryParam(SELECTED_CONTAINER_KEY, StringParam);
  const [workloadName, setWorkloadName] = useState<string | undefined>(undefined);
  const [selectedTab, setSelectedTab] = useQueryParam(
    POLICY_TUNING_SELECTED_TAB_QUERY_KEY,
    withDefault(StringParam, ENABLE_HPA_RECOMMENDATION ? PolicyTuningTabs.Hpa : PolicyTuningTabs.Resources)
  );

  const [viewPeriod, setSelectedViewPeriod] = useViewPeriodQueryParams();
  const [tuningParams, setTuningParams] = useState<GetPolicyTuningConfigParamsResponse>({
    cpuPolicyTuningParams: undefined,
    memoryPolicyTuningParams: undefined,
  });

  const [selectedPolicy, setSelectedPolicy] = useState<Policy | undefined>({
    name: selectedWorkload.policyName,
    displayName: selectedWorkload.displayPolicyName,
  });

  const [selectedChartComponents, setSelectedChartComponents] = useState<ChartComponents[]>([
    ChartComponents.AverageUsage,
    ChartComponents.RecommendedRequest,
    ChartComponents.CurrentRequest,
  ]);

  const [selectedHpaChartComponents, setSelectedHpaChartComponents] = useState<HpaChartComponent[]>([]);

  const [cpuCappingConfig, setCpuCappingConfig] = useState<
    components["schemas"]["UtilsPolicyTuningCappingConfig"] | undefined
  >(undefined);

  const [memoryCappingConfig, setMemoryCappingConfig] = useState<
    components["schemas"]["UtilsPolicyTuningCappingConfig"] | undefined
  >(undefined);

  const [emptyEventArray, setEmptyEventArray] = useState<EventPoint[] | undefined>(undefined);

  useEffect(() => {
    if (!viewPeriod) {
      setSelectedViewPeriod(ViewPeriodOptions["1 day"]);
    }
  }, []);

  const handleClose = () => {
    setDates(undefined);
    setSelectedContainer(undefined);
    setSelectedTab(undefined);
    onClose();
  };

  useEffect(() => {
    if (selectedWorkload) {
      setWorkloadName(`${selectedWorkload.type}: ${selectedWorkload.namespace}/${selectedWorkload.workloadName}`);
    }
    if (selectedWorkload.isReadyRecommendation === false) {
      handleClose();
    }
  }, [selectedWorkload]);

  const recommendationName = selectedWorkload.type.toLowerCase() + "-" + selectedWorkload.workloadName;

  return (
    <Dialog
      isOpen={isOpen}
      onClose={handleClose}
      title={
        <PolicyTuningDialogueTitle
          workloadName={
            workloadName ? getDisplayWorkloadName(workloadName, hideWorkloadSuffix, argoRolloutName) : undefined
          }
          name={selectedWorkload.workloadName}
          namespace={selectedWorkload.namespace}
          kind={selectedWorkload.type}
        />
      }
      dialogContentStyle={{
        padding: "0px",
      }}
      minWidth="1200px"
    >
      <div className="flex flex-col gap-[10px] pt-[10px] bg-background-chipHover">
        <div className="mt-[6px] pt-[30px]">
          <div className="bg-white px-[20px] py-[20px] relative">
            <div className={clsx(TABS_CONTAINER_CLASS_NAME, "w-full top-[-36px] left-0")}>
              {Object.entries(PolicyTuningTabs).map(([key, value]) => {
                if (value === PolicyTuningTabs.Analytics && !HAS_WORKLOAD_ANALYTICS_TAB) return null;
                if (value === PolicyTuningTabs.Hpa && (!HAS_HPA_TAB || !selectedWorkload.hasHpa)) return null;
                if (value === PolicyTuningTabs.Network && !HAS_WORKLOAD_NETWORK_TAB) return null;

                return (
                  <Tab
                    key={key}
                    isSelected={selectedTab === value}
                    onClick={() => {
                      setSelectedTab(value);
                    }}
                    name={value}
                    dataTestId={`policy-tuning-${key}-tab`}
                    hasSelectedUnderline
                  />
                );
              })}
            </div>
            {selectedTab === PolicyTuningTabs.Resources && (
              <PolicyTuningContainer
                selectedWorkload={selectedWorkload}
                fetchWorkloads={fetchWorkloads}
                tuningParams={tuningParams}
                setTuningParams={setTuningParams}
                selectedPolicy={selectedPolicy}
                setSelectedPolicy={setSelectedPolicy}
                selectedChartComponents={selectedChartComponents}
                setSelectedChartComponents={setSelectedChartComponents}
                cpuCappingConfig={cpuCappingConfig}
                setCpuCappingConfig={setCpuCappingConfig}
                memoryCappingConfig={memoryCappingConfig}
                setMemoryCappingConfig={setMemoryCappingConfig}
                emptyEventArray={emptyEventArray}
                setEmptyEventArray={setEmptyEventArray}
              />
            )}
            {HAS_HPA_TAB && selectedTab === PolicyTuningTabs.Hpa && (
              <PolicyTuningHpa
                selectedWorkload={selectedWorkload}
                setWorkloadName={setWorkloadName}
                fetchWorkloads={fetchWorkloads}
                tuningParams={tuningParams}
                setTuningParams={setTuningParams}
                selectedPolicy={selectedPolicy}
                setSelectedPolicy={setSelectedPolicy}
                selectedChartComponents={selectedHpaChartComponents}
                setSelectedChartComponents={setSelectedHpaChartComponents}
              />
            )}
            {selectedTab === PolicyTuningTabs.Events && (
              <div style={{ minHeight: MIN_TAB_CONTENT_HEIGHT }}>
                <WorkloadEventPopup
                  namespace={selectedWorkload.namespace}
                  name={recommendationName}
                  className="mt-[0px] py-[20px]"
                />
              </div>
            )}
            {selectedTab === PolicyTuningTabs.Analytics && (
              <div style={{ minHeight: MIN_TAB_CONTENT_HEIGHT }}>
                <WorkloadAnalytics
                  selectedWorkload={selectedWorkload}
                  policyName={selectedPolicy?.name}
                  selectedChartComponents={selectedChartComponents}
                  setSelectedChartComponents={setSelectedChartComponents}
                  setCpuCappingConfig={setCpuCappingConfig}
                  setMemoryCappingConfig={setMemoryCappingConfig}
                  setEmptyEventArray={setEmptyEventArray}
                />
              </div>
            )}
            {selectedTab === PolicyTuningTabs.Network && (
              <WorkloadNetwork selectedWorkload={selectedWorkload} setSelectedWorkload={setSelectedWorkload} />
            )}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default PolicyTuning;
