import MultiSelectByQueryParams from "../../../../components/MultiSelectByQueryParams";
import useGetAnnotations from "../../../../components/WorkloadStatusByNamespace/OverviewHooks/useGetAnnotations";
import useGetLabels from "../../../../components/WorkloadStatusByNamespace/OverviewHooks/useGetLabels";
import useGetNamespaces from "../../../../components/WorkloadStatusByNamespace/OverviewHooks/useGetNamespaces";

const TopKFilters = () => {
  const namespaces = useGetNamespaces();
  const labels = useGetLabels();
  const getAnnotations = useGetAnnotations();

  return (
    <div className="flex gap-2 justify-start">
      <MultiSelectByQueryParams options={namespaces} queryKey="namespaces" hasVirtualizedList hasIsExclude={false} />
      <MultiSelectByQueryParams options={labels} queryKey="labels" hasVirtualizedList hasIsExclude={false} />
      <MultiSelectByQueryParams
        options={getAnnotations}
        queryKey="annotations"
        hasVirtualizedList
        hasIsExclude={false}
      />
    </div>
  );
};

export default TopKFilters;
