import { Typography } from "@mui/material";
import numeral from "numeral";
import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts";
import { SCALEOPS_COLORS } from "../../../colors";
import useWindowSize from "../../../components/useWindowSize";
import RunningNumberBox from "./RunningNumberBox";

interface interfaceMetricLabelProps {
  label: string;
  value: number;
  color: string;
}

const InterfaceMetricLabel = ({ label, value, color }: interfaceMetricLabelProps) => {
  return (
    <div className=" flex justify-start items-center gap-2">
      <div
        className="rounded-full h-2.5 w-5"
        style={{
          background: color,
        }}
      />
      <Typography variant="body2">
        {label} {numeral(value).format("0,0")}
      </Typography>
    </div>
  );
};

export const Title = {
  Automated: <span>Automated</span>,
  AverageAutomated: (
    <span className="flex flex-col">
      <span>Average</span>
      <span>automated</span>
    </span>
  ),
};

interface Props {
  totalAutomatedWorkloads?: number;
  totalUnAutomatedWorkloads?: number;
  disableAnimation?: boolean;
  showAutomatedAndUnAutomatedLabels?: boolean;
  subtitle?: string;
  title?: JSX.Element;
  unAutomatedLabel?: string;
  automatedLabel?: string;
}

const TotalAutomatedPieChart = ({
  totalAutomatedWorkloads = 0,
  totalUnAutomatedWorkloads = 0,
  disableAnimation,
  showAutomatedAndUnAutomatedLabels,
  subtitle = "workloads",
  title = Title.Automated,
  unAutomatedLabel = "un-automated",
  automatedLabel = "automated",
}: Props) => {
  const windowSize = useWindowSize();
  const isFullWidth = Number(windowSize.width) < 1700 || !showAutomatedAndUnAutomatedLabels;

  totalAutomatedWorkloads = totalAutomatedWorkloads || 0;
  totalUnAutomatedWorkloads = totalUnAutomatedWorkloads || 0;
  const totalNumberOfWorkloads = totalAutomatedWorkloads + totalUnAutomatedWorkloads || 0;

  const data = [
    { name: "Automated", value: totalAutomatedWorkloads, color: SCALEOPS_COLORS.main.green },
    { name: "Inefficient", value: totalUnAutomatedWorkloads, color: SCALEOPS_COLORS.main.info },
  ];

  let automatedPercentage = (totalAutomatedWorkloads / (totalAutomatedWorkloads + totalUnAutomatedWorkloads)) * 100;
  automatedPercentage = Number(automatedPercentage);

  switch (true) {
    case automatedPercentage > 100:
      automatedPercentage = 100;
      break;
    case automatedPercentage < 0:
      automatedPercentage = 0;
      break;
    case Number.isNaN(automatedPercentage):
      automatedPercentage = 0;
      break;
    default:
      break;
  }

  // NOTE: do not remove. this section prevent pie chart from breaking when there are no workloads at all @barel22
  const pieData = [
    { name: "Automated", value: totalAutomatedWorkloads, color: SCALEOPS_COLORS.main.green },
    {
      name: "Inefficient",
      value: totalAutomatedWorkloads === 0 && totalUnAutomatedWorkloads === 0 ? 1 : totalUnAutomatedWorkloads,
      color: SCALEOPS_COLORS.main.info,
    },
  ];

  return (
    <div className="flex items-center justify-center relative h-full w-full">
      <div
        className="flex items-center justify-center relative"
        style={{
          width: isFullWidth ? "100%" : "50%",
          height: 200,
        }}
      >
        <div
          className="w-full h-full"
          style={{
            transform: "scaleX(-1)",
          }}
        >
          <ResponsiveContainer>
            <PieChart>
              <Pie
                dataKey="value"
                data={pieData}
                innerRadius={60}
                animationBegin={0}
                animationDuration={1000}
                animationEasing="ease-in-out"
                paddingAngle={0}
                isAnimationActive={!disableAnimation}
                startAngle={-270}
              >
                {data.map((cell, index) => {
                  return <Cell key={`cell-${index}`} fill={cell.color} stroke="transparent" />;
                })}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </div>
        <div className="absolute pt-2">
          <RunningNumberBox
            value={Number.isNaN(automatedPercentage) ? 0 : automatedPercentage}
            title={title}
            suffix="%"
            valueDataTestId="top-overview-automated-percentage-value"
            disableAnimation={disableAnimation}
          />
        </div>
      </div>
      <div
        className="bg-pin-500 flex flex-col gap-2 items-start justify-center w-[50%]"
        style={{
          display: isFullWidth ? "none" : "flex",
        }}
      >
        <div>
          <Typography variant="h6" fontWeight={400}>
            {numeral(totalNumberOfWorkloads).format("0,0")}
          </Typography>
          <Typography variant="body2">{subtitle}</Typography>
        </div>
        <div className="flex flex-col items-start justify-center gap-2">
          <InterfaceMetricLabel
            label={automatedLabel}
            value={totalAutomatedWorkloads}
            color={SCALEOPS_COLORS.main.green}
          />
          <InterfaceMetricLabel
            label={unAutomatedLabel}
            value={totalUnAutomatedWorkloads}
            color={SCALEOPS_COLORS.main.info}
          />
        </div>
      </div>
    </div>
  );
};

export default TotalAutomatedPieChart;
