import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import {
  GetDashboardByNamespaceResponse,
  PostAutoCluster,
  PostAutoClusterParams,
  getDashboardByNamespace,
} from "../../api/fetcher";
import { useWorkloadsContext } from "../../WorkloadsContext";

const { queryFn } = PostAutoCluster();
const dashboardByNamespace = getDashboardByNamespace();

const usePostAutoCluster = () => {
  const queryClient = useQueryClient();

  const { updateOverriddenWorkloads, deleteOverriddenWorkloadsProperties } = useWorkloadsContext();

  const { data } = useQuery<GetDashboardByNamespaceResponse, Error>({
    queryKey: [dashboardByNamespace.queryKey, "get-all-workloads-for-bulk-automation"],
    queryFn: () => dashboardByNamespace.queryFn({}),
  });

  const allRowsExceptExcludedFromAutomationIds = (
    data?.workloads?.filter((workload) => !workload.isAutomationExcluded) || []
  ).map((workload) => workload.id);

  return useMutation((params: PostAutoClusterParams) => queryFn(params), {
    onMutate: (values) => {
      updateOverriddenWorkloads({
        ids: allRowsExceptExcludedFromAutomationIds,
        props: {
          auto: values.state,
        },
      });
    },
    onError: (error: Error, values) => {
      deleteOverriddenWorkloadsProperties({
        ids: allRowsExceptExcludedFromAutomationIds,
        propertyNames: ["auto"],
      });
      console.log(`Error: ${error.message}`);
      toast.error(`Failed to ${values?.state ? "automate" : "un-automate"} cluster`, {
        position: "top-center",
      });
    },
    onSuccess: (_, values) => {
      queryClient.invalidateQueries([dashboardByNamespace.queryKey]).catch((error) => console.log(error));
      toast.success(`Cluster ${values?.state ? "automated" : "un-automated"} successfully`, {
        position: "top-center",
        style: { width: 350 },
      });
    },
  });
};

export default usePostAutoCluster;
