import { useEffect, useState } from "react";
import { components } from "../../../../api/schema";
import WorkloadTag from "./WorkloadTag";
import { TagArray, tagArray } from "./utils";
import { Typography } from "@mui/material";

interface Props {
  workload: components["schemas"]["UtilsWorkload"];
}

const WorkloadTagsContainer = ({ workload }: Props) => {
  const [hasTags, setHasTags] = useState<boolean>(false);

  useEffect(() => {
    setHasTags(false);
  }, [workload]);

  const hasMoreThanOneTag =
    tagArray.filter(({ workloadHasTagFnc }: TagArray) => workloadHasTagFnc(workload)).length > 1;

  if (tagArray.length === 0) return null;

  return (
    <div className="w-full flex gap-2 items-center">
      {hasTags && (
        <Typography variant="caption" className="text-text-lightBlack" fontWeight={600}>
          Auto-detected workload:
        </Typography>
      )}
      {tagArray.map(({ title, background, color, workloadHasTagFnc }: TagArray, index) => {
        if (workloadHasTagFnc(workload)) {
          if (!hasTags) setHasTags(true);
          return (
            <>
              {index !== 0 && hasMoreThanOneTag && <div className="h-[12px] w-[1px] bg-border" />}
              <WorkloadTag key={title} title={title} background={background} color={color} />
            </>
          );
        }
      })}
    </div>
  );
};

export default WorkloadTagsContainer;
