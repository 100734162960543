import { Typography } from "@mui/material";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";

interface Props {
  title: string;
  value: React.ReactNode | undefined;
}

const DialogTitle = ({ title, value }: Props) => {
  return (
    <div className="flex items-center">
      <Typography fontWeight={500}>{title}</Typography>{" "}
      {value && (
        <>
          <ArrowRightAltIcon /> {value}
        </>
      )}
    </div>
  );
};

export default DialogTitle;
