export const OPTIMIZED_REQUEST = "Optimized request";
export const OPTIMIZED_LIMIT = "Optimized limit";

export const INCLUDE_SIGN = <span>:</span>;
export const EXCLUDE_SIGN = <b>!:</b>;

export const workloadTypes = {
  deployment: "Deployment",
  daemonset: "DaemonSet",
  statefulset: "StatefulSet",
  job: "Job",
  cronjob: "CronJob",
};

export const getWorkloadType = (type: string) => workloadTypes?.[type as keyof typeof workloadTypes] ?? type;
