import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import LinearProgress, { linearProgressClasses } from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === "light" ? 300 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#52D39D" : "#52D39D",
  },
}));

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 400,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));

const formatXDigits = (num: number, digits = 1) => {
  return (Math.round(num * 100) / 100).toFixed(digits);
};
export default function ResourceBar(props: { request: number; allocatable: number; units: string; loading: boolean }) {
  if (props.loading) {
    return (
      <Box sx={{ width: "100%" }}>
        <LinearProgress />
      </Box>
    );
  }
  const [request, allocatable, units] = [props.request, props.allocatable, props.units];
  let progressBarValue = 50;
  const tooltip = (
    <p>
      Requested: {formatXDigits(request) + units}
      <br />
      Allocatable: {formatXDigits(allocatable) + units}
      <br />
    </p>
  );
  if (allocatable > 0) {
    progressBarValue = (100 * request) / allocatable;
  }
  return (
    <HtmlTooltip title={tooltip} followCursor={true}>
      <Box flexGrow={1} alignItems={"center"}>
        <p style={{ textAlignLast: "center" }}>{formatXDigits(progressBarValue, 0)}%</p>
        <BorderLinearProgress variant="determinate" value={progressBarValue} />
      </Box>
    </HtmlTooltip>
  );
}
