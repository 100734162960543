import { IconProps } from "./utils/utils";

const BatchIcon = (props: IconProps) => {
  const { height = 20, width = 20, className } = props;
  return (
    <svg height={height} width={width} className={className} fill="currentColor" viewBox="0 0 20 20" {...props}>
      <g clip-path="url(#clip0_978_33)">
        <path d="M19.9274 15.4292C19.9825 15.3541 19.9985 15.2572 19.9705 15.1684L19.6229 14.065C19.8322 14.083 20.0429 13.9145 19.9705 13.6846L19.6229 12.5813C19.8321 12.5992 20.0428 12.4309 19.9705 12.201L16.1974 0.204752C16.1591 0.0828734 16.0461 -3.8147e-06 15.9184 -3.8147e-06H4.08111C3.95338 -3.8147e-06 3.8404 0.0828734 3.8021 0.204752L3.33253 1.69771C3.2227 2.04689 3.77577 2.23834 3.89059 1.87325L4.29574 0.585012H15.7038L19.2928 11.9962H0.706666L3.42317 3.35931C3.53303 3.01017 2.97992 2.81868 2.8651 3.18381L0.0289841 12.201C-0.0417638 12.4258 0.167555 12.5985 0.376639 12.5812L0.0290231 13.6846C-0.0418418 13.9095 0.167516 14.0823 0.376639 14.0649L0.0290231 15.1683C-0.0418418 15.3932 0.167516 15.566 0.376639 15.5487L0.0290231 16.6521C-0.0418418 16.877 0.167516 17.0498 0.376639 17.0324L0.0290231 18.1358C-0.0418418 18.3607 0.167516 18.5335 0.376639 18.5162L0.0290231 19.6195C-0.0294395 19.8051 0.113421 19.9999 0.307997 19.9999H19.6915C19.8861 19.9999 20.029 19.8051 19.9705 19.6195L19.6229 18.5162C19.8322 18.5341 20.0429 18.3657 19.9705 18.1358L19.6229 17.0324C19.8322 17.0504 20.0429 16.882 19.9705 16.6521L19.6229 15.5487C19.7363 15.5611 19.8572 15.5249 19.9274 15.4292ZM19.0095 12.5813L19.2926 13.48H0.706861L0.989969 12.5813H19.0095ZM19.0095 14.065L19.2926 14.9637C19.2926 14.9637 19.2226 14.9637 19.2214 14.9637H0.706861L0.989969 14.065H19.0095ZM19.2926 19.415H0.706861L0.989969 18.5162H19.0095L19.2926 19.415ZM19.2926 16.4475C18.631 16.4475 17.9695 16.4475 17.3078 16.4475C16.9418 16.4475 16.9251 17.0325 17.3078 17.0325H19.0095L19.2926 17.9312H0.706861L0.989969 17.0325H15.7956C16.1616 17.0325 16.1783 16.4475 15.7956 16.4475H0.706861L0.989969 15.5487H19.0095L19.2926 16.4475Z" />
        <path d="M13.8455 2.86135C14.2328 2.84793 14.2325 2.28959 13.8455 2.27633H6.15402C5.7667 2.28975 5.76701 2.84809 6.15402 2.86135H13.8455Z" />
        <path d="M5.4715 4.41909C5.4715 4.58063 5.60242 4.71159 5.764 4.71159H14.2355C14.6229 4.69818 14.6225 4.13984 14.2355 4.12658H5.764C5.60242 4.12658 5.4715 4.25754 5.4715 4.41909Z" />
        <path d="M5.00348 6.26934C5.00348 6.43088 5.13441 6.56184 5.29599 6.56184H14.7035C15.0909 6.54843 15.0905 5.99009 14.7035 5.97683H5.29599C5.13441 5.97683 5.00348 6.10776 5.00348 6.26934Z" />
        <path d="M4.49646 8.11955C4.49646 8.28109 4.62739 8.41205 4.78897 8.41205H15.2106C15.5979 8.39864 15.5976 7.8403 15.2106 7.82704H4.78897C4.62739 7.82704 4.49646 7.958 4.49646 8.11955Z" />
        <path d="M3.91144 10.0124C3.91144 10.174 4.04236 10.3049 4.20395 10.3049H15.7956C16.1829 10.2915 16.1826 9.73317 15.7956 9.71991H4.20395C4.04236 9.71991 3.91144 9.85088 3.91144 10.0124Z" />
      </g>
      <defs>
        <clipPath id="clip0_978_33">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BatchIcon;
