import { Typography } from "@mui/material";
import UpAndDownCircleIcon from "../../Icons/UpAndDownCircleIcon";
import { components } from "../../api/schema";
import Tooltip from "../Tooltip";
import { WORKLOAD_OVERVIEW_TOOLTIP_CLASS_NAME } from "./overviewUtils";

interface Props {
  workload: components["schemas"]["UtilsWorkload"];
  children?: React.ReactNode;
  className?: string;
  iconSize?: number;
}

const HAPWorkload = ({ workload, children, className, iconSize = 14 }: Props) => {
  if (!workload.hasHpa) return <div>{children}</div>;

  return (
    <div className={className}>
      <Tooltip
        title={
          <Typography variant="caption">
            <p>
              ScaleOps detected <b>HPA for this workload</b>
            </p>
            <ul className="list-disc pl-4">
              <li>Current Replicas: {workload.runningReplicas || 0}</li>
              <li>Desired Replicas: {workload.desiredReplicas || 0}</li>
              <li>Min Replicas: {workload.minReplicas || 0}</li>
              <li>Max Replicas: {workload.maxReplicas || 0}</li>
            </ul>
          </Typography>
        }
        maxWidth={400}
        className={WORKLOAD_OVERVIEW_TOOLTIP_CLASS_NAME}
      >
        {children}
        <UpAndDownCircleIcon width={iconSize} height={iconSize} />
      </Tooltip>
    </div>
  );
};

export default HAPWorkload;
