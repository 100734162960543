import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { components } from "../../../api/schema";
import {
  ASC,
  getClusterNameRow,
  getCostRow,
  getMemoryRow,
  getNumberRow,
  getPercentageRow,
} from "../../../components/WorkloadStatusByNamespace/utils";

export enum TableType {
  Workloads = "Workloads",
  Aggregation = "Aggregation",
}

export enum Field {
  id = "id",
  workloadName = "workloadName",
  clusterName = "clusterName",
  totalCost = "totalCost",
  savingsAvailable = "savingsAvailable",
  spot = "spot",
  onDemand = "onDemand",
  cpu = "ownerCpuRequest",
  memory = "ownerMemoryRequest",
  replicas = "replicas",
  amountOfWorkloads = "amountOfWorkloads",
  crossAZCost = "crossAZCost",
  intraAZCost = "intraAZCost",
  totalDataTransfer = "totalDataTransfer",
  intraAZDataTransfer = "intraAZDataTransfer",
  crossAZDataTransfer = "crossAZDataTransfer",
}

export enum ColumnNames {
  id = "Name",
  workloadName = "Workload",
  clusterName = "Cluster",
  totalCost = "Total Cost",
  savingsAvailable = "Savings Available",
  spot = "Spot %",
  onDemand = "On-demand %",
  ownerCpuRequest = "CPU Request",
  ownerMemoryRequest = "Memory Request",
  replicas = "Replicas",
  amountOfWorkloads = "Amount of Workloads",
  crossAZCost = "Cross-AZ Cost",
  intraAZCost = "Intra-AZ Cost",
  totalDataTransfer = "Total Data Transfer",
  intraAZDataTransfer = "Intra-AZ Data Transfer",
  crossAZDataTransfer = "Cross-AZ Data Transfer",
}

type SortData = {
  id: string;
  clusterName: string;
  namespace: string;
  onDemand: number;
  ownerCpuRequest: number;
  ownerMemoryRequest: number;
  replicas: number;
  savingsAvailable: number;
  spot: number;
  totalCost: number;
  workloadName: string;
  workloadType: string;
  amountOfWorkloads: number;
};
interface getSortedRowsParas<T> {
  sortField: string | null | undefined;
  sortDirection: string | null | undefined;
  sortedData: T[];
}

export const getSortedColumn = <T extends SortData>({
  sortField,
  sortDirection,
  sortedData,
}: getSortedRowsParas<T>) => {
  // sort by clusterName
  if (sortField === Field.clusterName) {
    sortedData = sortedData.sort((a, b) => {
      if (sortDirection === ASC) {
        return a.clusterName.localeCompare(b.clusterName);
      } else {
        return b.clusterName.localeCompare(a.clusterName);
      }
    });
  }
  // sort by totalCost
  else if (sortField === Field.totalCost) {
    sortedData = sortedData.sort((a, b) => {
      if (sortDirection === ASC) {
        return a.totalCost - b.totalCost;
      } else {
        return b.totalCost - a.totalCost;
      }
    });
  }
  // sort by savingsAvailable
  else if (sortField === Field.savingsAvailable) {
    sortedData = sortedData.sort((a, b) => {
      if (sortDirection === ASC) {
        return a.savingsAvailable - b.savingsAvailable;
      } else {
        return b.savingsAvailable - a.savingsAvailable;
      }
    });
  }
  // sort by spot
  else if (sortField === Field.spot) {
    sortedData = sortedData.sort((a, b) => {
      if (sortDirection === ASC) {
        return a.spot - b.spot;
      } else {
        return b.spot - a.spot;
      }
    });
  }

  // sort by onDemand
  else if (sortField === Field.onDemand) {
    sortedData = sortedData.sort((a, b) => {
      if (sortDirection === ASC) {
        return a.onDemand - b.onDemand;
      } else {
        return b.onDemand - a.onDemand;
      }
    });
  }

  // sort by cpu
  else if (sortField === Field.cpu) {
    sortedData = sortedData.sort((a, b) => {
      if (sortDirection === ASC) {
        return a.ownerCpuRequest - b.ownerCpuRequest;
      } else {
        return b.ownerCpuRequest - a.ownerCpuRequest;
      }
    });
  }
  // sort by memory
  else if (sortField === Field.memory) {
    sortedData = sortedData.sort((a, b) => {
      if (sortDirection === ASC) {
        return a.ownerMemoryRequest - b.ownerMemoryRequest;
      } else {
        return b.ownerMemoryRequest - a.ownerMemoryRequest;
      }
    });
  }

  // replicas
  else if (sortField === Field.replicas) {
    sortedData = sortedData.sort((a, b) => {
      if (sortDirection === ASC) {
        return a.replicas - b.replicas;
      } else {
        return b.replicas - a.replicas;
      }
    });
  }

  // amountOfWorkloads
  else if (sortField === Field.amountOfWorkloads) {
    sortedData = sortedData.sort((a, b) => {
      if (sortDirection === ASC) {
        return a.amountOfWorkloads - b.amountOfWorkloads;
      } else {
        return b.amountOfWorkloads - a.amountOfWorkloads;
      }
    });
  }

  return sortedData;
};

export const getSharedColumns = (
  selectedColumns: (string | undefined)[],
  replicasHeaderName = ColumnNames[Field.replicas] as string
): GridColDef[] => [
  {
    field: Field.clusterName,
    headerName: ColumnNames[Field.clusterName],
    hide: !selectedColumns.includes(Field.clusterName),
    flex: 1,
    minWidth: 200,
    type: "string",
    align: "left",
    headerAlign: "center",
    disableColumnMenu: true,
    sortable: true,
    renderCell: (
      params: GridRenderCellParams<
        components["schemas"]["UtilsWorkloadCostReport"],
        components["schemas"]["UtilsWorkloadCostReport"]
      >
    ) =>
      getClusterNameRow({
        clusterName: params.row.clusterName,
      }),
  },
  {
    field: Field.totalCost,
    headerName: ColumnNames[Field.totalCost],
    hide: !selectedColumns.includes(Field.totalCost),
    flex: 1,
    minWidth: 120,
    type: "number",
    align: "center",
    headerAlign: "center",
    disableColumnMenu: true,
    sortable: true,
    renderCell: (
      params: GridRenderCellParams<
        components["schemas"]["UtilsWorkloadCostReport"],
        components["schemas"]["UtilsWorkloadCostReport"]
      >
    ) =>
      getCostRow({
        cost: params.row.totalCost,
      }),
  },
  {
    field: Field.savingsAvailable,
    headerName: ColumnNames[Field.savingsAvailable],
    hide: !selectedColumns.includes(Field.savingsAvailable),
    flex: 1,
    minWidth: 171,
    type: "number",
    align: "center",
    headerAlign: "center",
    disableColumnMenu: true,
    sortable: true,
    renderCell: (
      params: GridRenderCellParams<
        components["schemas"]["UtilsWorkloadCostReport"],
        components["schemas"]["UtilsWorkloadCostReport"]
      >
    ) =>
      getCostRow({
        cost: params.row.savingsAvailable,
        colorBySavings: true,
      }),
  },
  {
    field: Field.spot,
    headerName: ColumnNames[Field.spot],
    hide: !selectedColumns.includes(Field.spot),
    flex: 1,
    minWidth: 120,
    type: "number",
    align: "center",
    headerAlign: "center",
    disableColumnMenu: true,
    sortable: true,
    renderCell: (
      params: GridRenderCellParams<
        components["schemas"]["UtilsWorkloadCostReport"],
        components["schemas"]["UtilsWorkloadCostReport"]
      >
    ) => {
      const total = params.row.spot + params.row.onDemand;
      let percentage = Math.round((params.row.spot / total) * 100);

      if (isNaN(percentage) || percentage < 0 || percentage > 100) percentage = 0;

      return getPercentageRow({ percentage });
    },
  },
  {
    field: Field.onDemand,
    headerName: ColumnNames[Field.onDemand],
    hide: !selectedColumns.includes(Field.onDemand),
    flex: 1,
    minWidth: 150,
    type: "number",
    align: "center",
    headerAlign: "center",
    disableColumnMenu: true,
    sortable: true,
    renderCell: (
      params: GridRenderCellParams<
        components["schemas"]["UtilsWorkloadCostReport"],
        components["schemas"]["UtilsWorkloadCostReport"]
      >
    ) => {
      const total = params.row.spot + params.row.onDemand;
      let percentage = Math.round((params.row.onDemand / total) * 100);

      if (isNaN(percentage) || percentage < 0 || percentage > 100) percentage = 0;

      return getPercentageRow({ percentage });
    },
  },
  {
    field: Field.cpu,
    headerName: ColumnNames[Field.cpu],
    hide: !selectedColumns.includes(Field.cpu),
    flex: 1,
    minWidth: 150,
    type: "number",
    align: "center",
    headerAlign: "center",
    disableColumnMenu: true,
    sortable: true,
    renderCell: (
      params: GridRenderCellParams<
        components["schemas"]["UtilsWorkloadCostReport"],
        components["schemas"]["UtilsWorkloadCostReport"]
      >
    ) => getNumberRow({ value: params.row.ownerCpuRequest }),
  },
  {
    field: Field.memory,
    headerName: ColumnNames[Field.memory],
    hide: !selectedColumns.includes(Field.memory),
    flex: 1,
    minWidth: 162,
    type: "number",
    align: "center",
    headerAlign: "center",
    disableColumnMenu: true,
    sortable: true,
    renderCell: (
      params: GridRenderCellParams<
        components["schemas"]["UtilsWorkloadCostReport"],
        components["schemas"]["UtilsWorkloadCostReport"]
      >
    ) => getMemoryRow({ memory: params.row.ownerMemoryRequest }),
  },
  {
    field: Field.replicas,
    headerName: replicasHeaderName,
    hide: !selectedColumns.includes(Field.replicas),
    flex: 1,
    minWidth: 150,
    type: "number",
    align: "center",
    headerAlign: "center",
    disableColumnMenu: true,
    sortable: true,
    renderCell: (
      params: GridRenderCellParams<
        components["schemas"]["UtilsWorkloadCostReport"],
        components["schemas"]["UtilsWorkloadCostReport"]
      >
    ) => Math.round(params.row?.replicas ?? 0),
  },
  {
    field: Field.amountOfWorkloads,
    headerName: ColumnNames[Field.amountOfWorkloads],
    hide: !selectedColumns.includes(Field.amountOfWorkloads),
    flex: 1,
    minWidth: 150,
    type: "number",
    align: "center",
    headerAlign: "center",
    disableColumnMenu: true,
    sortable: true,
    renderCell: (
      params: GridRenderCellParams<
        components["schemas"]["UtilsWorkloadCostReport"],
        components["schemas"]["UtilsWorkloadCostReport"]
      >
    ) => Math.round(params.row?.amountOfWorkloads ?? 0),
  },
];
