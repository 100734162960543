import { ApiError, Fetcher, Middleware } from "openapi-typescript-fetch";
import { components, paths } from "./schema";
import { ApiResponse, CreateFetch, Fetch, FetchConfig, OpenapiPaths } from "openapi-typescript-fetch/dist/cjs/types";
import { GetConfig } from "../utils/ConfigHelper";

interface FetcherApi<Paths extends OpenapiPaths<Paths>> {
  configure: (config: FetchConfig) => void;
  use: (mw: Middleware) => number;
  path: <P extends keyof Paths>(
    path: P
  ) => {
    method: <M extends keyof Paths[P]>(
      method: M
    ) => {
      create: CreateFetch<M, Paths[P][M]>;
    };
  };
  withTimeout(number: number | undefined): FetcherApi<Paths>;
}
interface ScaleopsClustersFetcher<Paths extends OpenapiPaths<Paths>> extends FetcherApi<Paths> {
  cluster(name?: string): FetcherApi<Paths>;
}

export interface ScaleOpsClient extends FetcherApi<paths> {
  getFetcher(withNoCluster?: boolean): FetcherApi<paths>;
  logIn(token: string): Promise<boolean>;
  loggedIn(): boolean;
  logOut(): Promise<void>;
  withTimeout(number: number | undefined): FetcherApi<paths>;
}

class scaleOpsClient implements ScaleopsClustersFetcher<paths> {
  private current: FetcherApi<paths>;
  private readonly url: string;
  private clustersFetcher: Map<string, ScaleOpsClient>;
  constructor(url?: string, clusterName?: string) {
    if (url) {
      this.url = url;
    } else {
      this.url = process.env.REACT_APP_API_HOST || sessionStorage.getItem("baseName") || "";
    }
    // remove the trailing "/" from url
    this.url = this.url.replace(/\/$/, "");
    const f = Fetcher.for<paths>();
    this.current = {
      configure: f.configure,
      use: f.use,
      path: f.path,
      withTimeout: (timeout: number | undefined): FetcherApi<paths> => {
        if (timeout === undefined || timeout <= 0) {
          return this;
        }
        this.use((url, init, next) => {
          if (timeout !== undefined) {
            init.headers.append("X-ScaleOps-Multi-Cluster-Timeout", timeout.toFixed(3));
          }
          timeout = undefined;
          return next(url, init);
        });
        return this;
      },
    };
    this.clustersFetcher = new Map<string, ScaleOpsClient>();
    this.configure({
      baseUrl: window.location.protocol + "//" + window.location.host + this.url,
      use: [this.logger, this.auth, this.loginMiddleware, clusterIdMiddleware()],
    });
    if (clusterName !== undefined) {
      this.use(this.clusterMiddleware(clusterName));
    }
  }
  configure(config: FetchConfig): void {
    this.current.configure(config);
  }

  path<P extends keyof paths>(
    path: P
  ): { method: <M extends keyof paths[P]>(method: M) => { create: CreateFetch<M, paths[P][M]> } } {
    return this.current.path(path);
  }

  use(mw: Middleware): number {
    return this.current.use(mw);
  }
  withTimeout(timeout: number | undefined): FetcherApi<paths> {
    return this.current.withTimeout(timeout);
  }

  cluster(name?: string): ScaleOpsClient {
    if (name == undefined) {
      return this;
    }
    let cluster = this.clustersFetcher.get(name);
    if (cluster == undefined) {
      cluster = new scaleOpsClient();
      cluster.configure({
        baseUrl: this.url,
        use: [this.logger, this.auth, this.loginMiddleware, this.clusterMiddleware(name), clusterIdMiddleware()],
      });
      this.clustersFetcher.set(name, cluster);
    }
    return cluster;
  }

  public getFetcher(): ScaleOpsClient {
    return this.cluster();
  }

  public loggedIn(): boolean {
    const token: string | null = localStorage.getItem("token");
    return token != null && token != "";
  }

  public logIn(token: string): Promise<boolean> {
    // If successful we write a cookie from the server and return the cookie value as part of the response
    // In the React app we check if we already have this cookie, if so do nothing else write the cookie in the React app
    // We also update the fetcher config with the additional cookie
    localStorage.setItem("token", token);
    return this.getFetcher()
      .path("/auth/token")
      .method("post")
      .create()({
        token: token,
      })
      .then((result: ApiResponse<components["schemas"]["AuthTokenResponse"]>) => {
        if (result.ok && result.data.token != undefined) {
          localStorage.setItem("token", result.data.token);
          return true;
        } else {
          return false;
        }
      })
      .catch((reason) => {
        console.error("api.ts: logIn: failed to authenticate token", reason);
        return false;
      })
      .then((success) => {
        if (!success) {
          localStorage.removeItem("token");
        }
        return success;
      });
  }

  public logOut(): Promise<void> {
    localStorage.removeItem("token");
    return Promise.resolve();
  }

  // Middlewares

  private logger: Middleware = (url, init, next) => {
    console.debug(`ScaleOps: fetching ${url}`);
    return next(url, init)
      .then((resp) => {
        console.debug(`ScaleOps: fetched ${url} successfully`);
        return resp;
      })
      .catch((reason) => {
        console.error("ScaleOps: failed to do request, reason", reason);
        return Promise.reject(reason);
      });
  };

  private loginMiddleware: Middleware = (url, init, next: Fetch) => {
    const paramToken = new URLSearchParams(window.location.search).get("token");
    const storedToken = paramToken || localStorage.getItem("__scaleops_token") || undefined;
    if (storedToken !== undefined) {
      localStorage.setItem("__scaleops_token", storedToken);
      if (paramToken !== null) {
        const params = new URLSearchParams(window.location.search);
        params.delete("token");
        window.location.search = params.toString();
      }
      init.headers.append("Authorization", "Bearer " + storedToken);
    }
    return next(url, init)
      .catch((err: ApiError) => {
        if (!doRedirectIfNeeded(err)) {
          // we did not get a redirect but the request is still broken
          console.error("Failed to fetch", err);
          if (err.status == 401) {
            localStorage.removeItem("__scaleops_token");
          }
        }

        return Promise.reject(err);
      })
      .then((resp) => {
        // checking if server returned a new token, google refresh token flow for example
        const tokenHeader = resp.headers.get("X-ScaleOps-Token");
        // if tokenHeader is not null and has a length > 1, we store the token in local storage
        if (tokenHeader && tokenHeader.length > 1) {
          localStorage.setItem("__scaleops_token", tokenHeader);
        }
        // return the original response and continue the chain as normal
        return resp;
      });
  };

  private auth: Middleware = (url, init, next: Fetch) => {
    try {
      const config: components["schemas"]["ConfGetConfResponse"] | undefined = GetConfig();
      const storedToken = localStorage.getItem("token");
      const headerName: string = config?.authHeader ? config?.authHeader : "X-ScaleOps-Authorization";

      if (storedToken != null) {
        let token = storedToken;
        if (headerName == "Authorization") {
          token = "Bearer " + storedToken;
        }
        init.headers.append(headerName, token);
      }
    } catch (e) {
      console.log(e);
    }

    return next(url, init).catch((reason: ApiError) => {
      if (reason.status == 401) {
        localStorage.removeItem("token");
      }
      return Promise.reject(reason);
    });
  };

  private clusterMiddleware: (clusterName: string) => Middleware =
    (clusterName: string): Middleware =>
    (url, init, next) => {
      init.headers.append("X-Scaleops-Cluster", clusterName);
      return next(url, init);
    };
}
const clusterIdMiddleware: () => Middleware = (): Middleware => (url, init, next) => {
  const clusterId = sessionStorage.getItem("clusterId") || undefined;
  if (clusterId !== undefined) {
    init.headers.append("X-Scaleops-Cluster-Id", clusterId);
  }
  return next(url, init);
};

export const ScaleOps = (): ScaleOpsClient => {
  const localClient = new scaleOpsClient();

  return {
    getFetcher: (withNoCluster): FetcherApi<paths> => {
      let currentCluster;
      if (!withNoCluster) {
        currentCluster = sessionStorage.getItem("currentCluster") || undefined;
      }
      return localClient.cluster(currentCluster);
    },
    logIn: (token: string) => {
      return localClient.logIn(token);
    },
    loggedIn: () => {
      return localClient.loggedIn();
    },
    logOut: () => {
      return localClient.logOut();
    },
    configure: (config: FetchConfig) => {
      localClient.configure(config);
    },
    use: (mw: Middleware) => {
      return localClient.use(mw);
    },
    path: (path) => {
      return localClient.path(path);
    },
    withTimeout(number: number | undefined): FetcherApi<paths> {
      return localClient.withTimeout(number);
    },
  };
};

const doRedirectIfNeeded = (reason?: { data?: { redirectURL?: string } }) => {
  if (reason !== undefined && reason.data != undefined && reason.data.redirectURL != undefined) {
    window.location.assign(reason.data.redirectURL);
    return true;
  }
  return false;
};
