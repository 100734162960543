import { Checkbox, ListItemText, MenuItem } from "@mui/material";
import { StringParam, useQueryParam } from "use-query-params";
import { SCALEOPS_COLORS } from "../../../../colors";
import FilterChip, { FilterType } from "../../../../components/FilterChip";
import Input from "../../../../components/Input";
import MultiSelectByQueryParams from "../../../../components/MultiSelectByQueryParams";
import useGetAnnotations from "../../../../components/WorkloadStatusByNamespace/OverviewHooks/useGetAnnotations";
import useGetLabels from "../../../../components/WorkloadStatusByNamespace/OverviewHooks/useGetLabels";
import useGetNamespaces from "../../../../components/WorkloadStatusByNamespace/OverviewHooks/useGetNamespaces";
import useGetClusters from "../../../../MainMenu/useGetClusters";
import ColumnsFilter from "../ColumnsFilter";
import ClearWorkloadsFilters from "./ClearWorkloadsFilters";

const workloadTypes = {
  Deployment: "deployment",
  DaemonSet: "daemonset",
  StatefulSet: "statefulset",
  Job: "job",
  CronJob: "cronjob",
};

interface Props {
  selectedColumns: (string | undefined)[];
  setSelectedColumns: (selectedColumns: (string | undefined)[]) => void;
  columnOptions: string[];
}

const WorkloadsFilters = ({ selectedColumns, setSelectedColumns, columnOptions }: Props) => {
  const [searchTerm, setSearchTerm] = useQueryParam("searchTerms", StringParam);

  const clusters = useGetClusters();
  const namespaces = useGetNamespaces();
  const labels = useGetLabels();
  const annotations = useGetAnnotations();

  return (
    <div className="w-full flex flex-col gap-2">
      <div className="flex gap-2 items-center">
        <Input
          className="-mt-1 min-w-[150px]"
          borderColor={SCALEOPS_COLORS.black}
          placeholder="search..."
          onChange={(e) => {
            setSearchTerm(e.target.value);
          }}
          value={searchTerm ?? ""}
        />
        <MultiSelectByQueryParams
          options={clusters?.map((cluster) => cluster.name)?.filter((cluster) => cluster) as string[]}
          queryKey="clusterFilters"
          name="clusters"
          hasIsExclude
          excludeQueryKey="isClusterExclude"
          hasVirtualizedList
          enableAddCustomValue
        />
        <MultiSelectByQueryParams
          options={namespaces}
          queryKey="namespaces"
          excludeQueryKey="isNamespaceExclude"
          hasIsExclude
          hasVirtualizedList
          enableAddCustomValue
        />
        <MultiSelectByQueryParams
          options={labels}
          queryKey="labels"
          excludeQueryKey="isLabelExclude"
          hasIsExclude
          hasVirtualizedList
          enableAddCustomValue
          hasLogicalAndOR
          logicalOperatorQueryKey="logicalLabel"
        />
        <MultiSelectByQueryParams
          options={annotations}
          queryKey="annotations"
          hasIsExclude
          excludeQueryKey="isAnnotationExclude"
          hasVirtualizedList
          enableAddCustomValue
          hasLogicalAndOR
          logicalOperatorQueryKey="logicalAnnotation"
        />
        <MultiSelectByQueryParams
          options={Object.values(workloadTypes)}
          optionRenderFunction={(option, index, selected, dataTestId) => {
            let displayName = Object.keys(workloadTypes).find(
              (key) => workloadTypes[key as keyof typeof workloadTypes] === option
            );

            if (!displayName) displayName = option;

            return (
              <MenuItem
                value={option}
                key={`${index}-multi-select-option`}
                data-testid={dataTestId ? `${dataTestId}-option-${index}` : undefined}
                className="min-w-fit"
              >
                <div className="min-w-[235px] flex items-center">
                  <Checkbox checked={selected.indexOf(option) > -1} />
                  <ListItemText primary={displayName} />
                </div>
              </MenuItem>
            );
          }}
          queryKey="workloadTypes"
          name="types"
          hasIsExclude
          excludeQueryKey="isWorkloadTypeExclude"
          hasVirtualizedList
          enableAddCustomValue
        />
        <ColumnsFilter
          selectedColumns={selectedColumns}
          setSelectedColumns={setSelectedColumns}
          options={columnOptions}
        />
      </div>
      <div className="flex gap-2 flex-wrap">
        <FilterChip
          label="clusters"
          filterType={FilterType.ARRAY}
          queryParam={"clusterFilters"}
          excludeQueryParam="isClusterExclude"
        />
        <FilterChip
          label="namespaces"
          filterType={FilterType.ARRAY}
          queryParam={"namespaces"}
          excludeQueryParam="isNamespaceExclude"
        />
        <FilterChip
          label="labels"
          filterType={FilterType.ARRAY}
          queryParam={"labels"}
          excludeQueryParam="isLabelExclude"
        />
        <FilterChip
          label="annotations"
          filterType={FilterType.ARRAY}
          queryParam={"annotations"}
          excludeQueryParam="isAnnotationExclude"
        />
        <FilterChip
          label="types"
          filterType={FilterType.ARRAY}
          queryParam={"workloadTypes"}
          excludeQueryParam="isWorkloadTypeExclude"
        />
      </div>
      <ClearWorkloadsFilters />
    </div>
  );
};

export default WorkloadsFilters;
